import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Label } from "reactstrap";
import logo1 from "../../assets/images/logo-1.png";
import { hospital_by_id } from "../../redux/actions/hospitalAction";
import HospitalPropertyCardView from "../../components/hospitalPropertyComponents/HospitalPropertyCardView";
import HospitalPropertyGridView from "../../components/hospitalPropertyComponents/HospitalPropertyGridView";
import { deleteHospitalProperty, hospital_property_by_hospital } from "../../redux/actions/hospitalPropertyAction";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import moment from "moment";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { hospital_property_access } from "../../helpers/access_policies";
import NoAccess from "../../components/NoAccess";
import hospital1 from "../../assets/images/nodata-found/hospital.png";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import CreateHospital from "./CreateHospital";
import CreateHospitalProperty from "./CreateHospitalProperty";
import SingleHospitalProperty from "./SingleHospitalProperty";
import { formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import DeleteConfirmationModal from "../../components/DeletePopupComponent/DeletePopup";
import HospitalHeader from "../../components/Header/HospitalHeader";
import HospitalSubHeader from "../../components/Header/HospitalSubHeader";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";

// Define the type inline
interface HospitalProperty {
  name: string;
  created_on: string;
}

const SingleHospital: React.FC<any> = ({ showSingleHospitalPropertyModal, setSingleShowHospitalPropertyModal, setShowHospitalPropertyModal, showHospitalPropertyModal, hospital_id, toggle, isHospitalEditMode, setIsHospitalEditMode, countries, handleDeleteHospital }) => {
  const navigate = useNavigate();
  const { user_info } = useSelector((state: any) => state.user);

  const [subTab, setsubTab] = useState("1");
  const [hospital, sethospital]: any = useState(null);
  const [hospital_properties, sethospital_properties] = useState<HospitalProperty[]>([]);
  const [backup_hosp_propertues, setbackup_hosp_propertues] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);

  const [hospital_property_id, set_hospital_property_id] = useState("");
  const [hospitalProperty, setHospitalProperty] = useState({});
  const [hospitalPropertyMode, setHospitalPropertyMode] = useState("Create");

  const [filters, setFilters] = useState({ start_date: "", end_date: "", sort_order: "" });

  const subTabtoggle = (tab: string) => subTab !== tab && setsubTab(tab);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hospitalPropertyToDelete, setHospitalPropertyToDelete] = useState<any>(null);

  const toggleDeleteModal = (hospitalProperty: any) => {
    setHospitalPropertyToDelete(hospitalProperty);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteConfirmation = async () => {
    const request = await deleteHospitalProperty(hospitalPropertyToDelete?.id);
    if (request?.statusCode === 200) {
      setSingleShowHospitalPropertyModal(false);
      const getProperties = await hospital_property_by_hospital(hospital_id);
      if (getProperties?.statusCode === 200) {
        sethospital_properties(getProperties?.body?.data);
        setbackup_hosp_propertues(getProperties?.body?.data);
      } else {
        sethospital_properties([]);
        setbackup_hosp_propertues([]);
      }
      SuccessToast(request?.message);
    } else {
      if (request?.errors?.length > 0) {
        ErrorToast(request?.errors[0]?.msg);
      }
    }
    setShowDeleteModal(false);
  };

  const handleDeleteHospitalProperty = async (e: MouseEvent<HTMLElement>, hospitalProperty: any) => {
    e.stopPropagation();
    toggleDeleteModal(hospitalProperty);
  };

  const search_hospital_property = (search_text: any) => {
    if (search_text === "") {
      sethospital_properties(backup_hosp_propertues);
    } else {
      let filter_hospital_props = backup_hosp_propertues?.filter(
        (hospital_prop: any) => {
          return hospital_prop?.landmark
            ?.toLowerCase()
            .includes(search_text?.toLowerCase());
        }
      );
      sethospital_properties(filter_hospital_props);
    }
  };

  const fetch_data = useCallback(async () => {
    if (
      hospital_id !== undefined &&
      hospital_id !== "2" &&
      hospital_id !== "1"
    ) {
      const [hospitalResponse, hospitalPropertyResponse] = await Promise.all([
        hospital_by_id(hospital_id),
        hospital_property_by_hospital(hospital_id),
      ]);

      if (hospitalResponse?.statusCode === 200)
        sethospital(hospitalResponse?.body?.data);
      else sethospital(null);

      if (hospitalPropertyResponse?.statusCode === 200) {
        sethospital_properties(hospitalPropertyResponse?.body?.data);
        setbackup_hosp_propertues(hospitalPropertyResponse?.body?.data);
      } else {
        sethospital_properties([]);
        setbackup_hosp_propertues([]);
      }
    }

  }, [hospital_id]);

  useEffect(() => {
    fetch_data();
  }, [fetch_data, isHospitalEditMode]);

  const handleFilterApply = () => {
    const { start_date, end_date, sort_order } = filters;

    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      return;
    }

    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        return;
      }

      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        return;
      }
    }

    let filteredData: HospitalProperty[] = [...backup_hosp_propertues];
    if (start_date && end_date) {
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      startDateObj.setHours(0, 0, 0, 0);
      endDateObj.setHours(23, 59, 59, 999);

      filteredData = filteredData.filter((hospital: HospitalProperty) => {
        const createdDate = new Date(hospital.created_on);
        return createdDate >= startDateObj && createdDate <= endDateObj;
      });
    }
    if (sort_order === 'asc') {
      filteredData = filteredData.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort_order === 'desc') {
      filteredData = filteredData.sort((a, b) => b.name.localeCompare(a.name));
    }

    if (filteredData.length === 0) {
      console.error("No hospital properties matched the filter criteria.");
    }

    sethospital_properties(filteredData);
  };

  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };

  const handleFilterReset = async () => {
    setFilters({ start_date: "", end_date: "", sort_order: "ASC" });
    fetch_data();
    setShowFilterBox(false);
  };

  const handleEditHospitalProperty = (e: MouseEvent<HTMLElement>, hospitalProperty: any) => {
    e?.stopPropagation();
    setHospitalProperty(hospitalProperty);
    setHospitalPropertyMode("Update");
    setShowHospitalPropertyModal(true);
  };

  // const handleDeleteHospitalProperty = async (e: MouseEvent<HTMLElement>, hospitalProperty: any) => {
  //   // e?.stopPropagation();
  //   // const request = await deleteHospitalProperty(hospitalProperty?.id);
  //   // if (request?.statusCode === 200) {
  //   //   setSingleShowHospitalPropertyModal(false);

  //   //   const getProperties = await hospital_property_by_hospital(hospital_id);
  //   //   if (getProperties?.statusCode === 200) {
  //   //     sethospital_properties(getProperties?.body?.data);
  //   //     setbackup_hosp_propertues(getProperties?.body?.data);
  //   //   } else {
  //   //     sethospital_properties([]);
  //   //     setbackup_hosp_propertues([]);
  //   //   }
  //   //   SuccessToast(request?.message);
  //   // } else {
  //   //   if (request?.errors?.length > 0) {
  //   //     ErrorToast(request?.errors[0]?.msg);
  //   //   }
  //   // }
  // };

  useEffect(() => {
    const getProperties = async () => {
      if (hospital_id) {
        const request = await hospital_property_by_hospital(hospital_id);
        if (request?.statusCode === 200) {
          sethospital_properties(request?.body?.data);
          setbackup_hosp_propertues(request?.body?.data);
        } else {
          sethospital_properties([]);
          setbackup_hosp_propertues([]);
        }
      }
    };

    getProperties();
  }, [showHospitalPropertyModal, hospital_id]);

  const handleHospitalPropertyCard = (hospital_property: any) => {
    set_hospital_property_id(hospital_property?.id);
    setSingleShowHospitalPropertyModal(true);
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        pageName={hospitalPropertyToDelete?.landmark}
        onConfirm={handleDeleteConfirmation}
      />
      <div className="row">
        {showHospitalPropertyModal === false && showSingleHospitalPropertyModal === false && isHospitalEditMode === false && (
          <><div className="col-12 col">
            <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between">
              <HospitalSubHeader />
              <HospitalHeader />

              {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-sm-0 font-size-14">
                  <li className="breadcrumb-item">
                    <div onClick={() => toggle("1")}>Hospitals</div>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Hospital Detail
                  </li>
                </ol>
              </nav> */}
              <div className="page-title-right  d-flex">
                <a className="btn back-btn" style={{ cursor: "pointer" }} onClick={() => toggle("1")}>
                  <i className=" mdi mdi-keyboard-backspace"></i> Back
                </a>
              </div>
            </div>
          </div>
            {/* <div className="col-12 col">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <HospitalSubHeader />
              </div>
            </div> */}
          </>
        )}


        {isHospitalEditMode ?
          (<div className="col-12">

            <CreateHospital
              toggle={toggle}
              countries={countries}
              hospital={hospital}
              isHospitalEditMode={isHospitalEditMode}
              setIsHospitalEditMode={setIsHospitalEditMode}
            />
          </div>)
          :
          showSingleHospitalPropertyModal === true && showHospitalPropertyModal === false ?
            (
              <SingleHospitalProperty
                hospital_property_id={hospital_property_id}
                handleEditHospitalProperty={handleEditHospitalProperty}
                handleDeleteHospitalProperty={handleDeleteHospitalProperty}
                setSingleShowHospitalPropertyModal={setSingleShowHospitalPropertyModal}
              />
            )
            : showHospitalPropertyModal === true ?
              (
                <CreateHospitalProperty
                  showHospitalPropertyModal={showHospitalPropertyModal}
                  setShowHospitalPropertyModal={setShowHospitalPropertyModal}
                  hospitalPropertyMode={hospitalPropertyMode}
                  setHospitalPropertyMode={setHospitalPropertyMode}
                  hospitalProperty={hospitalProperty}
                  hospital={hospital}
                />
              )
              : (
                <div className="col-12 common-top-con hospital-detail-top-con">
                  <Card className="overflow-hidden">
                    <CardBody className="hospital-detail">
                      <div className="row">
                        <div className="col-lg-12 hospital-card-box col-xl-4 left-side">
                          <div className="bg-primary bg-soft hospital-icon-box left-inner p-3">
                            <div className="brand-logo patient-title-box">
                              <div className="icon-box">
                                <SvgFilesIcon iconname={"hospital"} />
                              </div>
                            </div>
                            <div className="brand-detail">
                              <div className="title">
                                <div className="d-flex align-items-center  justify-content-between mb-2">
                                  <h4>{hospital?.name}</h4>
                                  <div className="user-setting-box">
                                    <div className="edit-box" onClick={() => setIsHospitalEditMode(true)} style={{ cursor: 'pointer', marginRight: 10 }}>
                                      <SvgFilesIcon iconname={"edit"} />
                                    </div>
                                    <div className="delete-box" onClick={(e) => handleDeleteHospital(e, hospital)}>
                                      <SvgFilesIcon iconname={"delete"} />
                                    </div>
                                  </div>
                                  {/* <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-success" onClick={() => setIsHospitalEditMode(true)} style={{ cursor: 'pointer', marginRight: 10 }}>
                                      <i className="mdi mdi-pencil font-size-18" />
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteHospital(e, hospital)}>
                                      <i className="mdi mdi-delete-outline text-danger font-size-16"></i>
                                    </div>
                                  </div> */}
                                </div>
                                <p className="mb-0">
                                  {hospital?.address} - {hospital?.city?.city}{" "}
                                  {hospital?.state?.state} {hospital?.zipcode}
                                </p>
                              </div>
                            </div>
                            <div className="address-line">
                              <div className="d1">
                                <label className="title">Reg No. :</label>
                                <p className="deatil mb-0">
                                  {hospital?.registeration_no}
                                </p>
                              </div>
                              <div className="d1">
                                <label className="title"> Lic No. :</label>
                                <p className="deatil mb-0">{hospital?.license_no}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-8">
                          <div className="right-side">
                            <div className="detail-line small-line">
                              <label className="title">Key Person : </label>
                              <p className="deatil mb-0">{hospital?.contact_person}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title"> Mobile :</label>
                              <p className="deatil mb-0">{hospital?.mobile}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title">Landline :</label>
                              <p className="deatil">{hospital?.landline}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title">Toll Free :</label>
                              <p className="deatil">45789</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title">Emergency :</label>
                              <p className="deatil">{hospital?.emergency_contact}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title">Ambulance :</label>
                              <p className="deatil">{hospital?.ambulance_contact}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title"> Created By :</label>
                              <p className="deatil mb-0">{hospital?.created_by?.name}</p>
                            </div>
                            <div className="detail-line small-line">
                              <label className="title"> Created On :</label>
                              <p className="deatil mb-0">
                                {formatDateToLocaleDateString(hospital?.created_on)}
                              </p>
                            </div>

                            <div className="detail-line full-line">
                              <label className="title">Website :</label>
                              <p className="deatil">
                                <a href={hospital?.website} target="_blank">
                                  {hospital?.website}
                                </a>
                              </p>
                            </div>
                            {/* <div className="detail-line full">
                    <label className="title">Banner URL</label>
                    <p className="deatil"><a href="https://www.freepik.com/free-photos-vectors/hospital-banner">https://www.freepik.com/free-photos-vectors/hospital-banner</a> </p>
                  </div> */}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="col-12 col">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0 font-size-18">Properties</h4>
                      <div className="page-title-right d-flex">
                        <div className="">
                          <button type="button" className="btn btn-info medium-btn" onClick={() => { setShowHospitalPropertyModal(true); setHospitalPropertyMode("Create") }}>
                            Create
                          </button>
                        </div>
                        <div className="mr-2 patient-search-box">
                          <div className="search-box-wrapper">
                            <SvgFilesIcon iconname={"search"} />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Hospital Properties"
                              onChange={(e: any) => search_hospital_property(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mr-2">
                          <button
                            type="button"
                            onClick={() =>
                              setShowFilterBox((showFilterBox) => !showFilterBox)
                            }
                            className="btn btn-outline-light"
                          >
                            <i className="mdi mdi-filter-outline"></i> Filter
                            <i className="mdi mdi-chevron-down" />
                          </button>
                          {showFilterBox && (
                            <div className="filter-box">
                              <button
                                className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                                onClick={() => setShowFilterBox(false)}
                              ><i className="mdi mdi-close" />
                              </button>
                              <div className="row">
                                <div className="col-6 mb-3">
                                  <Label className="form-label">From Date</Label>
                                  <CustomMUIDatePicker
                                    name="start_date"
                                    type="formik"
                                    value={filters?.start_date}
                                    setState={handleFilterChange}
                                  />
                                </div>
                                <div className="col-6 mb-3">
                                  <Label className="form-label">To Date</Label>
                                  <CustomMUIDatePicker
                                    name="end_date"
                                    type="formik"
                                    value={filters?.end_date}
                                    setState={handleFilterChange}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <Label className="form-label">Filter By Order</Label>
                                  <select
                                    onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                                    className="form-control"
                                    name="sort_order"
                                    value={filters.sort_order}
                                  >
                                    <option disabled selected>
                                      Select Order
                                    </option>
                                    <option style={{ color: "black" }} value="ASC">
                                      Ascending
                                    </option>
                                    <option style={{ color: "black" }} value="DESC">
                                      Decending
                                    </option>
                                  </select>
                                </div>
                                <div className="col-12 text-center">
                                  <button
                                    type="button"
                                    onClick={() => handleFilterApply()}
                                    className="btn btn-info medium-btn mr-2"
                                  >
                                    Apply
                                  </button>
                                  <button
                                    onClick={() => handleFilterReset()}
                                    type="button"
                                    className="btn btn-secondary medium-btn"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                          <NavItem>
                            <NavLink
                              className={subTab === "1" ? "active" : ""}
                              onClick={() => {
                                subTabtoggle("1");
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="mdi mdi-grid"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={subTab === "2" ? "active" : ""}
                              onClick={() => {
                                subTabtoggle("2");
                              }}
                            >
                              <span className="d-none d-sm-block">
                                {" "}
                                <i className="mdi mdi-format-list-bulleted"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {user_info?.user_access?.includes(hospital_property_access.view_all_hospital_properties)
                      ? (
                        <TabContent activeTab={subTab} className="text-muted hospital-card-scroll">
                          <TabPane tabId="1">
                            <div className="scroll-bar-hospital  patient-card-box hospital-card-box">
                              {hospital_properties?.length > 0 ? (
                                hospital_properties.map(
                                  (hospital_property: any, index: number) => {
                                    return (
                                      <div
                                        className="patient-box"
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleHospitalPropertyCard(hospital_property)}
                                      >
                                        <HospitalPropertyCardView
                                          handleDeleteHospitalProperty={handleDeleteHospitalProperty}
                                          handleEditHospitalProperty={handleEditHospitalProperty}
                                          hospital_property={hospital_property}
                                        />
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    marginTop: 100,
                                  }}
                                >
                                  <img className="nodata-img" src={hospital1} width={80}></img><br></br>
                                  <h4 className="mt-2">No Hospital Properties Found</h4>
                                </div>
                              )}
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="row patient-list-view-section hospital-card-box">
                              <div className="col-md-12">
                                <Card>
                                  <CardBody>
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-nowrap mb-0  table-list">
                                        <thead className="table-light">
                                          <tr>
                                            <th>Hospital Property Name & Address</th>
                                            <th>Contact Person</th>
                                            <th>Contact Number</th>
                                            <th>Subscription Type</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {hospital_properties?.length > 0 ? (
                                            hospital_properties.map(
                                              (hospital_property: any, index: number) => {
                                                return (
                                                  <tr
                                                    onClick={() => handleHospitalPropertyCard(hospital_property)}
                                                    style={{ cursor: "pointer" }}
                                                    key={index}
                                                  >
                                                    <HospitalPropertyGridView
                                                      handleDeleteHospitalProperty={handleDeleteHospitalProperty}
                                                      handleEditHospitalProperty={handleEditHospitalProperty}
                                                      hospital_property={hospital_property}
                                                    />
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td>
                                                <span>No Hospitals Propeties Found</span>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      ) : (
                        <NoAccess text="Hospital Properties Access Restricted" />
                      )}
                  </div>
                </div>
              )
        }
      </div >
    </>
  );
};

export default SingleHospital;