import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../utils/toastNotifications";
import {
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
  putPatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";
import { v4 as uuidv4 } from 'uuid';

const PatientAdmissionVitals: React.FC<any> = ({
  selected_admission,
  selected_date,

}) => {
  const initialRows = useMemo(
    () =>
      Array.from({ length: 24 }).map((_, i) => {
        const startHour = (i % 24).toString().padStart(2, "0");
        const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
        const selected_time = `${startHour}:00-${endHour}:00`;

        return {
          id: uuidv4().toString(),
          datetime: new Date(),
          selected_time,
          bp: "",
          pulse: "",
          vital_respiratory_system: "",
          spo2: "",
          temperature: "",
        };
      }),
    []
  );

  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const { user_info } = useSelector((state: any) => state?.user);
  const [rowId, setrowId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);

  const admission_hourly_report = useCallback(async () => {
    if (selected_admission !== null) {
      const date = selected_date
        ? new Date(selected_date?.substring(0, 10))
        : new Date();
      const tomorrow = new Date(date);
      tomorrow.setDate(date?.getDate() + 1);
      const yesterday = new Date(date);
      yesterday.setDate(date?.getDate() - 1);
      yesterday.setHours(23, 59, 59, 999);
      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "vitals",
        new Date(yesterday).toISOString(),
        new Date(tomorrow).toISOString()
      );
      if (request?.statusCode === 200) {
        const requestedData = request?.body?.data[0];
        setrowId(requestedData?.id);
        if (requestedData?.reportValues?.length > 0) {
          const reorderedArray = requestedData?.reportValues;
          setRows(reorderedArray);
        } else {
          setRows(initialRows);
        }
      } else {
        setrowId('');
        setRows(initialRows);
      }
    }
  }, [selected_date, selected_admission, initialRows]);

  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);

  const handleDeleteHourlyReport = useCallback(
    async (id: string) => {
      try {
        const isRowSaved = rows.some(row => row.id === id && rowId);
        if (!isRowSaved) {
          return ErrorToast("Record is not saved yet!");
        }
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              bp: "",
              pulse: "",
              vital_respiratory_system: "",
              spo2: "",
              temperature: "",
            };
          }
          return row;
        });
        const response = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
        if (response?.statusCode === 200) {
          SuccessToast("Row Deleted Successfully");
          admission_hourly_report();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [rows, rowId, admission_hourly_report]
  );

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteHourlyReport(currentDeleteId);
    }
    closeDeleteModal();
  };

  const handleAdmissionHourlyReport = useCallback(async () => {
    let isValid = true;
    let validatedRows = rows.map(row => {
      if (row.bp) {
        let bpParts = row.bp.split('/');
        
        if (bpParts.length !== 2 || !bpParts[0] || !bpParts[1]) {
          isValid = false; // Ensure both systolic & diastolic values exist
        }
  
        let systolic = bpParts[0].replace(/[^0-9]/g, '');
        let diastolic = bpParts[1]?.replace(/[^0-9]/g, '') || '';
  
        if (systolic && diastolic) {
          row.bp = `${systolic}/${diastolic}`;
        } else {
          isValid = false;
        }
      }
      return row;
    });
  
    if (!isValid) {
      ErrorToast("Invalid BP format! Please enter in 'Systolic/Diastolic' format.");
      return;
    }
  
    if (!rowId) {
      let jsonDaa = {
        reportValues: validatedRows,
        category: "vitals",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonDaa);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: validatedRows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message);
        admission_hourly_report();
      } else {
        ErrorToast(request?.message);
      }
    }
  }, [rows, rowId, admission_hourly_report]);

  const shouldShowActions = () => {
    return (
      (user_info?.is_super_admin ||
        new Date(selected_date).toDateString() === new Date().toDateString()) &&
      selected_admission?.admitted &&
      !selected_admission?.discharged
    );
  };

  const onChangeTextField = useCallback(
    (event: any, index: number) => {
      const { name, value } = event.target;
      let formattedValue = '';
      if (name === 'bp') {
        let hasSlash = value.includes('/');
        // Split the value into parts before and after the slash
        const [beforeSlash, afterSlash] = value.split('/');
        // Format the part before the slash
        const formattedBeforeSlash = (beforeSlash || '')
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
        const formattedAfterSlash = (afterSlash || '')
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
        if (hasSlash) {
          formattedValue = `${formattedBeforeSlash}/${formattedAfterSlash}`;
        } else {
          formattedValue = formattedBeforeSlash;
        }
        // Ensure only one slash is allowed
        const parts = formattedValue.split('/');
        if (parts.length > 2) {
          // If there are more than 2 parts, we only keep the first two
          formattedValue = parts.slice(0, 2).join('/');
        }
      } else {
        // Allow only numbers and dots for other fields, with up to two decimal places
        formattedValue = value
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
      }
      const updatedRows = rows.map((row, i) =>
        i === index ? { ...row, [name]: formattedValue } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission vitals"
      />
      {shouldShowActions() && (
        <div className="report-save-all-btn">
          <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport}>
            Save All
          </button>
        </div>
      )}
      <div className="hourly-table-scroll">
        <table className="table table-hover fixed-table-top">
          <thead className="table-light">
            <tr>
              <th className="time-col">Time</th>
              <th>Pulse (per min)</th>
              <th>BP (MMHG)</th>
              <th>Respiratory Rate & Pattern (PER MIN)</th>
              <th>SPO2 (%)</th>
              <th>Temperature (F)</th>
              {shouldShowActions() && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, index: number) => {
              const isRowEmpty = !row.pulse && !row.bp && !row.vital_respiratory_system && !row.spo2 && !row.temperature;
              return (
                <tr key={index}>
                  <td className="time-col">{row?.selected_time}</td>
                  <td>
                    <input
                      type="text"
                      name="pulse"
                      value={row?.pulse}
                      onChange={(e) => onChangeTextField(e, index)}
                      disabled={selected_admission?.discharged}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="bp"
                      value={row?.bp}
                      onChange={(e) => onChangeTextField(e, index)}
                      disabled={selected_admission?.discharged}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="vital_respiratory_system"
                      value={row?.vital_respiratory_system}
                      onChange={(e) => onChangeTextField(e, index)}
                      disabled={selected_admission?.discharged}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="spo2"
                      value={row?.spo2}
                      onChange={(e) => onChangeTextField(e, index)}
                      disabled={selected_admission?.discharged}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="temperature"
                      value={row?.temperature}
                      onChange={(e) => onChangeTextField(e, index)}
                      disabled={selected_admission?.discharged}
                    />
                  </td>
                  {shouldShowActions() && (
                    <td className="hospital-card-box">
                      <div className="user-setting-box">
                        <div
                          className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                          title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                          onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                          style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                        >
                          <i className="fas fa-redo"></i>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PatientAdmissionVitals;
