import { hospital_routes, SERVER_URL } from "../api_path";
import axiosApi from "../../api/apiHelper";
 
export const all_hospitals_without_pagination = async (start_date = '', end_date = '', sort_order = '') => {
  try {
    let url = `${SERVER_URL}${hospital_routes.all_hospitals}`;
 
    const startDateTrimmed = start_date?.trim();
    const endDateTrimmed = end_date?.trim();
 
    if (startDateTrimmed && endDateTrimmed) url += `?start_date=${startDateTrimmed}&end_date=${endDateTrimmed}`;
 
    if (sort_order) url += startDateTrimmed && endDateTrimmed ? `&sort_order=${sort_order}` : `?sort_order=${sort_order}`;
 
    const { data } = await axiosApi.get(url);
    return data
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
 
export const create_hospital = async (fields: any) => {
  try {
    let { data } = await axiosApi.post(`${SERVER_URL}${hospital_routes.create_hospital}`,fields);
 
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const hospital_by_id = async (id: string) => {
  try {
    let { data } = await axiosApi.get(`${SERVER_URL}${hospital_routes.hospital_by_id}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const updateHospital = async (values: any) => {
  try {
    const { data } = await axiosApi.put(hospital_routes.update_hospital, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const deleteHospital = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(`${hospital_routes?.delete_hospital}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data?.data;
  }
};
 