import React, { FC, useCallback, useEffect, useState, } from "react";
import * as Yup from "yup";
import { Label, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardTitle, } from "reactstrap";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IDoctorList, IPostInstructionByPatientId, ISingleInstruction, } from "../../../../redux/types/patientAdmissionInstructionsTypes";
import {
  deletePatientAdmissionInstructions, getPatientAdmissionInstructionsByPatientId, postPatientAdmissionInstructions, putPatientAdmissionInstructions,
} from "../../../../redux/actions/patientAdmissionInstructions";
import { ERROR_MESSAGES } from "../../../../helpers/constants";
import { getDoctorListByRole, getDoctorTypesList } from "../../../../redux/actions/doctorAction";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";
import { formatDateToLocaleDateString } from "../../../../helpers/helperFunctions";
import CustomDropdownBox from "../../../../components/InstuctionDateSort/CustomDropdownBox"
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import { postFireBaseNotification } from "../../../../redux/actions/firebaseNotification";
import { useFirebaseMessagingContext } from "../../../../components/Notification/firebasemessageshow";
 
const Instructions: FC<any> = ({ patient, selected_admission }) => {
  const today = new Date().toISOString().split('T')[0];
  const initialFilters = {
    patient_id: patient?.id,
    start_date: today,
    end_date: today,
    hospital_property_id: patient?.hospital_property?.id,
    sort_order: "",
  };
  const [completedCollapsedItems, setCompletedCollapsedItems] = useState<boolean[]>([]);
  const [incompletedCollapsedItems, setIncompletedCollapsedItems] = useState<boolean[]>([]);
  const [filters, setFilters] = useState(initialFilters);
  const [completedInstructions, setCompletedInstructions] = useState<ISingleInstruction[]>([]);
  const [incompletedInstructions, setIncompletedInstructions] = useState<ISingleInstruction[]>([]);
  const [notCompletedInstructionsLength, setNotCompletedInstructionsLength] = useState(0);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [isInstructionOpen, setIsInstructionOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ISingleInstruction | null>();
  const [doctorTypes, setDoctorTypes] = useState<IDoctorList[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  const [isAllCompleted, setIsAllCompleted] = useState(true);
  const [completedInstructionsAll, setCompletedInstructionsAll] = useState<ISingleInstruction[]>([]);
  const [inCompletedInstructionsAll, setinCompletedInstructionsAll] = useState<ISingleInstruction[]>([]);
 
  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  const { user_info } = useSelector((state: any) => state.user);
 
  useEffect(() => {
    setCompletedCollapsedItems(
      new Array(completedInstructions?.length).fill(true)
    );
  }, [completedInstructions]);

  useFirebaseMessagingContext();
 
  const sendNotification = async (instruction_id: string) => {
    const notificationPayload = {
      hospital_property: user_info?.hospital_property?.id,
      type: "instruction",
      patient_admission_id: selected_admission?.id,
      instruction_id:instruction_id,
      notification_text: `Instruction for patient ${patient?.first_name} ${patient?.last_name}`,
      patient_id: patient?.id,
      created_by: user_info?.id,
    };
 
    try {
      await postFireBaseNotification(notificationPayload);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };
 
 
  useEffect(() => {
    setIncompletedCollapsedItems(
      new Array(incompletedInstructions?.length).fill(true)
    );
  }, [incompletedInstructions]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { body } = await getDoctorListByRole({ role: "doctor", hospital_id: user_info?.hospital_property?.hospital?.id });
        if (body) setDoctorTypes(body?.data);
        else setDoctorTypes([]);
      } catch (error) {
        console.error("Error fetching doctor types:", error);
      }
    };
    fetchData();
  }, []);
 
  const validationSchema = Yup.object({
    instruction_by_doctor: Yup.string().required("Instruction By Doctor is required"),
    instruction_description: Yup.string().required("Instruction Description is required"),
  });
 
  const initialValues = {
    instruction_by_doctor: editData?.doctor?.id || "",
    instruction_description: editData?.instruction_description || "",
    instruction_status: editData?.instruction_status || "",
    notification_date: editData?.notification_date || "",
    show_Notification_Date: editData?.show_Notification_Date || false,
  };
 
  const getCompletedInstructionsByPatientId = useCallback(async () => {
    if (patient !== null) {
      const { body } = await getPatientAdmissionInstructionsByPatientId(patient?.id, true);
      if (body?.data) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0];
        const todayInstructions = body.data.filter((instruction: { created_on: string; }) => {
          const instructionDate = instruction.created_on.split('T')[0];
          return instructionDate === todayString;
        });
        setCompletedInstructions(todayInstructions);
        setCompletedInstructionsAll(body.data);
      } else {
        setCompletedInstructions([]);
      }
    }
  }, [patient]);
 
  const handleTabClick = (tab: string) => {
    if (tab === "1") {
      setIsAllCompleted(true);
    } else if (tab === "2") {
      setIsAllCompleted(false);
    }
  };
 
  const getIncompletedInstructionsByPatientId = useCallback(async () => {
    if (patient !== null) {
      const { body } = await getPatientAdmissionInstructionsByPatientId(patient?.id, false);
      if (body?.data) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0];
        const todayInstructions = body.data.filter((instruction: { created_on: string; }) => {
          const instructionDate = instruction.created_on.split('T')[0];
          return instructionDate === todayString;
        });
        setIncompletedInstructions(todayInstructions);
        setinCompletedInstructionsAll(body.data);
      } else {
        setIncompletedInstructions([]);
        setNotCompletedInstructionsLength(0);
      }
    }
  }, [patient]);
 
  const searchInstructions = (searchType: "completed" | "incompleted") => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
      return;
    }
    const formattedStart = formatDateToLocaleDateString(startDate);
    const formattedEnd = formatDateToLocaleDateString(endDate);
    let filteredInstructions = searchType === "completed"
      ? completedInstructionsAll.filter((instruction) => {
        const instructionDate = new Date(instruction.created_on);
        const formattedInstructionDate = formatDateToLocaleDateString(instructionDate.toISOString().split('T')[0]);
        return instructionDate >= start && instructionDate <= end;
      })
      : inCompletedInstructionsAll.filter((instruction) => {
        const instructionDate = new Date(instruction.created_on);
        const formattedInstructionDate = formatDateToLocaleDateString(instructionDate.toISOString().split('T')[0]);
        return instructionDate >= start && instructionDate <= end;
      });
    if (filters.sort_order === "ASC") {
      filteredInstructions = filteredInstructions.sort((a, b) => {
        const dateA = new Date(a.created_on).getTime();
        const dateB = new Date(b.created_on).getTime();
        return dateA - dateB;
      });
    } else if (filters.sort_order === "DESC") {
      filteredInstructions = filteredInstructions.sort((a, b) => {
        const dateA = new Date(a.created_on).getTime();
        const dateB = new Date(b.created_on).getTime();
        return dateB - dateA;
      });
    }
    searchType === "completed"
      ? setCompletedInstructions(filteredInstructions)
      : setIncompletedInstructions(filteredInstructions);
  };
  const handleSearch = () => {
    searchInstructions("completed");
  };
 
  const handleIncompleteSearch = () => {
    searchInstructions("incompleted");
  };
  const resetInstructions = (resetType: "completed" | "incompleted") => {
    const today = new Date();
    const todayISO = today.toISOString().split('T')[0];
    handleFilterChange('start_date', todayISO);
    handleFilterChange('end_date', todayISO);
    const formattedToday = formatDateToLocaleDateString(todayISO);
    let filteredInstructions = resetType === "completed"
      ? completedInstructionsAll.filter((instruction) => {
        const instructionDate = new Date(instruction.created_on);
        const formattedInstructionDate = formatDateToLocaleDateString(instructionDate.toISOString().split('T')[0]);
        return formattedInstructionDate === formattedToday;
      })
      : inCompletedInstructionsAll.filter((instruction) => {
        const instructionDate = new Date(instruction.created_on);
        const formattedInstructionDate = formatDateToLocaleDateString(instructionDate.toISOString().split('T')[0]);
        return formattedInstructionDate === formattedToday;
      });
    resetType === "completed"
      ? setCompletedInstructions(filteredInstructions)
      : setIncompletedInstructions(filteredInstructions);
  };
  const handleReset = () => {
    resetInstructions("incompleted");
  };
 
  const handleResetCompleted = () => {
    resetInstructions("completed");
  };
  useEffect(() => {
    if (patient !== null) {
      getCompletedInstructionsByPatientId();
      getIncompletedInstructionsByPatientId();
    }
  }, [getCompletedInstructionsByPatientId, getIncompletedInstructionsByPatientId]);
 
  const toggleCompletedCollapse = (index: number) => {
    setCompletedCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };
 
  const toggleIncompleteCollapse = (index: number) => {
    setIncompletedCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };
 
  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
    if (name === "start_date") {
      setStartDate(value || "");
    } else if (name === "end_date") {
      setEndDate(value || "");
    }
  };
 
  const handleDeleteIncompletedInstruction = useCallback(
    async (id: string) => {
      try {
        if (id?.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionInstructions(id);
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getIncompletedInstructionsByPatientId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getIncompletedInstructionsByPatientId]
  );
 
  const handleUpdateCompleteInstruction = async (id: string) => {
    try {
      const response = await putPatientAdmissionInstructions({
        id: id,
        is_completed: true,
      });
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        getCompletedInstructionsByPatientId();
        getIncompletedInstructionsByPatientId();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast("Unknown Error Occured!");
    }
  };
 
  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };
 
  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };
 
  const confirmDelete = () => {
    if (currentDeleteId) {
      if (currentActiveTab === "1") handleDeleteIncompletedInstruction(currentDeleteId);
      else handleDeleteCompletedInstruction(currentDeleteId)
      closeDeleteModal();
    }
  };
 
  const handleDeleteCompletedInstruction = useCallback(
    async (id: string) => {
      try {
        if (id?.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionInstructions(id);
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getCompletedInstructionsByPatientId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [getCompletedInstructionsByPatientId]
  );
 
  const submitForm = async (
    values: IPostInstructionByPatientId,
    resetForm: () => void
  ) => {
    try {
const notificationDateValue = typeof values?.notification_date === 'string' && values?.notification_date ? new Date(values?.notification_date) : new Date();
      let response;
      if (!editData) {
        response = await postPatientAdmissionInstructions({
          ...values,
          patient_id: patient?.id,
          patient_admission_id: selected_admission?.id,
          hospital_property_id: selected_admission?.hospital_property?.id,
          notification_date: notificationDateValue,
          show_Notification_Date: values.show_Notification_Date,
        });
      } else {
        response = await putPatientAdmissionInstructions({
          ...values,
          id: editData?.id,
          patient_id: patient?.id,
          patient_admission_id: selected_admission?.id,
          hospital_property_id: selected_admission?.hospital_property?.id,
          notification_date: notificationDateValue,
        });
      }
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        sendNotification(response.body?.id);
        getCompletedInstructionsByPatientId();
        getIncompletedInstructionsByPatientId();
        setIsInstructionOpen(false);
        resetForm();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast("Unknown Error Occured!");
    }
  };
 
  const handleAdd = () => {
    setEditData(null);
    setIsInstructionOpen(true);
  };
 
  useEffect(() => {
    if (patient) {
      setFilters({
        ...filters,
        hospital_property_id: patient?.hospital_property?.id,
        patient_id: patient?.id,
      })
    }
  }, [patient])
 
 
 
  return (
    <>
      {isInstructionOpen && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            submitForm(values, resetForm);
          }}
        >
          {({
            isValid,
            isSubmitting,
            resetForm,
            handleSubmit,
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors) {
                    const firstError = Object.values(errors)[0];
 
                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <div className="right-panel">
                  <h4>Instruction</h4>
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => setIsInstructionOpen(false)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">By Doctor Name</Label>
                        <Field
                          name="instruction_by_doctor"
                          as="select"
                          className={`form-control ${errors.instruction_by_doctor && touched.instruction_by_doctor ? 'is-invalid' : ''}`}
                          value={values.instruction_by_doctor} // Set the value attribute to the selected value
                          onChange={(e: any) => {
                            const selectedDoctorId = e.target.value;
                            const selectedDoctorObject = doctorTypes.find(doctor => doctor?.id === selectedDoctorId);
                            if (selectedDoctorObject) {
                              setFieldValue('speciality', selectedDoctorObject?.speciality);
                            } else {
                              setFieldValue('speciality', '');
                            }
                            handleChange(e);
                          }}
                        >
                          <option value="">Select a Doctor</option>
                          {doctorTypes && doctorTypes.length > 0 && doctorTypes.map(doctor => (
                            <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="instruction_by_doctor"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Description
                        </Label>
                        <ReactQuill
                          className="form-control"
                          id="instruction_description"
                          value={values?.instruction_description || ""}
                          placeholder="Enter Instruction Status"
                          // onBlur={handleBlur}
                          onChange={(content, delta, source, editor) => setFieldValue('instruction_description', content)}
                        />
                        <ErrorMessage
                          name="instruction_description"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label>Show Notification Date?</label>
                        <div>
                          <label>
                            <Field
                              type="radio"
                              name="show_Notification_Date"
                              value={true}
                              onChange={() => setFieldValue('show_Notification_Date', true)} // Update Formik state
                            />
                            Yes
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="show_Notification_Date"
                              value={false}
                              onChange={() => setFieldValue('show_Notification_Date', false)} // Update Formik state
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {values?.show_Notification_Date && (
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="notification_date">Notification Date</label>
                          <CustomMUIDatePicker
                            useDateTimePicker={true}
                            name="notification_date"
                            type="formik"
                            setState={setFieldValue}
                            value={typeof values?.notification_date === 'string' ? values?.notification_date : values?.notification_date?.toISOString()}
                          // disabled={(editData !== null && editData?.doctor?.id !== user_info?.id)}
                          />
                          <ErrorMessage name="notification_date" component="div" className="text-danger" />
                        </div>
                      </div>
                    )}
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary mr-2 medium-btn"
                        type="submit"
                      >
                        {editData ? "Update" : "Add"}
                      </button>
                      <button
                        className="btn btn-secondary medium-btn"
                        onClick={() => resetForm()}
                      >
                        Reset{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
      <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary medium-btn mb-2"
              onClick={() => handleAdd()}
            >
              Add
            </button>
          )}
      </div>
 
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="page-title-right d-flex ">
              <CustomDropdownBox
                filters={filters}
                handleFilterChange={handleFilterChange}
                isAllCompleted={isAllCompleted}
                handleSearch={handleSearch}
                handleIncompleteSearch={handleIncompleteSearch}
                handleReset={handleReset}
                handleResetCompleted={handleResetCompleted}
              />
            </div>
            <div className="page-title-right d-flex ">
              <Nav tabs className="nav-tabs-custom list-view-tab">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={currentActiveTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                      handleTabClick("1");
                    }}
                  >
                    Current{" "}
                    <span className="badge bg-success rounded-pill">
                      {notCompletedInstructionsLength > 0
                        ? notCompletedInstructionsLength
                        : ""}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={currentActiveTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                      handleTabClick("2");
                    }}
                  >
                    All Completed
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
        <TabContent activeTab={currentActiveTab} className="p-3 text-muted instruction-panel-accordion ">
          <TabPane tabId="1">
            <div className="col-12 col scroll-box">
              <Card>
                <CardBody>
                  <CardTitle></CardTitle>
                  <div className="row">
                    <div className="col-12">
                      <div className="accordion" id="accordion">
                        {incompletedInstructions &&
                          incompletedInstructions.length > 0 ? (
                          incompletedInstructions.map(
                            (
                              instruction: ISingleInstruction,
                              index: number
                            ) => (
                              <div className="accordion-item" key={index}>
                                <h2
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className={`accordion-button fw-medium ${incompletedCollapsedItems[index]
                                      ? "collapsed"
                                      : ""
                                      }`}
                                    type="button"
                                    onClick={() =>
                                      toggleIncompleteCollapse(index)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    By Doctor - {instruction?.doctor?.name} ,
                                    Status - {!instruction?.is_completed ? 'Active' : "Complete"}
                                    {(true || user_info?.hospital_property?.id ===
                                      selected_admission?.hospital_property?.id) &&
                                      selected_admission?.admitted &&
                                      !selected_admission?.discharged && (
                                        <span className="right-date">
                                          <div className="gap-3">
                                            <a
                                              className="text-success mr-2"
                                            >
                                              <i
                                                className="mdi mdi-check"
                                                style={{ fontSize: "20px" }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleUpdateCompleteInstruction(
                                                    instruction?.id
                                                  );
                                                }}
                                              ></i>
                                            </a>
                                            <a className="text-success mr-2" >
                                              <i
                                                className="mdi mdi-pencil font-size-16"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  setEditData(instruction);
                                                  setIsInstructionOpen(true);
                                                }}
                                              ></i>
                                            </a>
                                            <a
                                              className="text-danger"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                openDeleteModal(instruction?.id);
                                              }}
                                            >
                                              <i className="mdi mdi-delete font-size-16"></i>
                                            </a>
                                          </div>
                                        </span>
                                      )}
                                  </button>
                                </h2>
                                <Collapse
                                  isOpen={!incompletedCollapsedItems[index]}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <div className="table-box">
                                      <table className="table">
                                        <thead className="table-light">
                                          <tr>
                                            <th>Instruction Description :</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr><td>
                                            <p className="deatil richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instruction?.instruction_description || "-") }}>
                                            </p>
                                          </td></tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            )
                          )
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            No Rows Available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="col-12 col scroll-box">
              <Card>
                <CardBody>
                  <CardTitle></CardTitle>
                  <div className="row">
                    <div className="col-12">
                      <div className="accordion" id="accordion">
                        {completedInstructions &&
                          completedInstructions.length > 0 ? (
                          completedInstructions.map(
                            (
                              instruction: ISingleInstruction,
                              index: number
                            ) => (
                              <div className="accordion-item" key={index}>
                                <h2
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <button
                                    className={`accordion-button fw-medium ${completedCollapsedItems[index]
                                      ? "collapsed"
                                      : ""
                                      }`}
                                    type="button"
                                    onClick={() =>
                                      toggleCompletedCollapse(index)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    By Doctor - {instruction?.doctor?.name} ,
                                    Status - {!instruction?.is_completed ? 'Active' : "Complete"}
                                    <span className="right-date">
                                      {(true || user_info?.hospital_property?.id ===
                                        selected_admission?.hospital_property?.id) &&
                                        selected_admission?.admitted &&
                                        !selected_admission?.discharged && (
                                          <div className="gap-3">
                                            <a
                                              className="text-success mr-2"
                                              href="javascript:void(0);"
                                            >
                                              <i
                                                className="mdi mdi-pencil font-size-16"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  setEditData(instruction);
                                                  setIsInstructionOpen(true);
                                                }}
                                              ></i>
                                            </a>
                                            <a
                                              className="text-danger"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                openDeleteModal(instruction?.id);
                                              }}
                                            >
                                              <i className="mdi mdi-delete font-size-16"></i>
                                            </a>
                                          </div>
                                        )}
                                    </span>
                                  </button>
                                </h2>
                                <Collapse
                                  isOpen={!completedCollapsedItems[index]}
                                  className="accordion-collapse"
                                >
                                  <div className="accordion-body">
                                    <div className="table-box">
                                      <table className="table">
                                        <thead className="table-light">
                                          <tr>
                                            <th>Instruction Description :</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr><td>
                                            <p className="deatil richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instruction?.instruction_description || "-") }}></p>
                                          </td></tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            )
                          )
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            No Rows Available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="instructions"
      />
    </>
  );
};
 
export default Instructions;
 
 