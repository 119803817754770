import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NotificationProps } from'../Header/SideHeader';
import { ErrorToast } from '../../utils/toastNotifications';
import { getMarkReadNotificationBasedonHospitalId, getNotificationBasedonHospitalId } from '../../redux/hospitalWiseNotification';
 
interface NotificationsContextType {
  notifications: NotificationProps[];
  totalCount: number;
  unReadCount:number;
  fetchNotifications: (page: number, perPage: number) => Promise<void>;
  markAllAsRead:()=>Promise<void>;
}
 
const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);
 
export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};
 
export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user_info } = useSelector((state: any) => state.user);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [unReadCount,setUnReadCount]=useState(0);
 
  const fetchNotifications = useCallback(async (page: number, perPage: number) => {
    if (!user_info?.hospital_property?.id) return;
    try {
      const allNotifications = await getNotificationBasedonHospitalId(user_info?.hospital_property?.id, page, perPage);
      if (allNotifications?.statusCode === 200) {
        setNotifications(allNotifications?.body?.data);
        setTotalCount(allNotifications?.body?.page_count);
        setUnReadCount(allNotifications?.body?.is_read_count);
      } else {
        setNotifications([]);
        setTotalCount(0);
        setUnReadCount(0);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);  
    }
  }, [user_info?.hospital_property?.id]);
 
  const markAllAsRead =useCallback(async () => {
    try {
        await getMarkReadNotificationBasedonHospitalId(user_info?.hospital_property?.id);
        await fetchNotifications(page_number, per_page);
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  },[user_info?.hospital_property?.id, page_number,per_page]);
 
  useEffect(() => {
    fetchNotifications(page_number, per_page);
    const interval = setInterval(() => {fetchNotifications(page_number, per_page);}, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchNotifications]);
 
  return (
    <NotificationsContext.Provider value={{ notifications,totalCount,fetchNotifications,markAllAsRead,unReadCount}}>
      {children}
    </NotificationsContext.Provider>
  );
};
 
 