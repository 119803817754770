import { useState, useCallback, useEffect, FC } from "react";
import { Card, CardBody, CardTitle, Input, Label, Form } from "reactstrap";
import * as Yup from "yup";
import { Formik, FormikValues } from "formik";
import { getStatesByCountry } from "../../redux/actions/stateActions";
import { getCityByStateList } from "../../redux/actions/cityAction";
import { create_hospital, updateHospital } from "../../redux/actions/hospitalAction";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/hospitalTabsTypes";
import { getCountryList } from "../../redux/actions/countryAction";
import HospitalHeader from "../../components/Header/HospitalHeader";
import HospitalSubHeader from "../../components/Header/HospitalSubHeader";
import Header from "../../components/Header/Header";

const CreateHospitalSchema = Yup.object().shape({
  name: Yup.string().matches(/^[A-Za-z ]+$/, 'Please enter valid Name').required("Name is required"),
  registeration_no: Yup.string().required("Registeration Number is required"),
  license_no: Yup.string().required("License Number is required"),
  website: Yup.string()
    .matches(/^(http(s)?:\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?].*)?$/, 'Please enter a valid website URL')
    .nullable()
    .optional(),
  address: Yup.string().required("Address is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  zipcode: Yup.string()
    .matches(/^\d{6}$/, "Please enter valid Pincode (6 digits)").required("Zipcode is required"),
  contact_person: Yup.string().matches(/^[A-Za-z .]+$/, 'Please enter valid Emergency contact name').required("Contact Person Name is required"),
  mobile: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid Mobile Number(10 digits)').required("Mobile number is required"),
  landline: Yup.string()
    .matches(/^\+?[0-9\s-]+$/, 'Please enter a valid landline number')
    .optional(),
  emergency_contact: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid M (10 digits)').optional(),
  ambulance_contact: Yup.string()
    .matches(/^\+?[0-9\s-]+$/, 'Please enter a ambulance number').nullable().optional(),
  email: Yup.string()
    .email('Invalid email')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter valid email')
    .required("Email is required"),
});

interface CreateHospitalProps {
  countries: { id: string; country: string; }[];
  toggle: any;
  isHospitalEditMode?: boolean;
  hospital?: any;
  setIsHospitalEditMode?: (isHospitalEditMode: boolean) => void;
}

const CreateHospital: FC<CreateHospitalProps> = ({ toggle, hospital, isHospitalEditMode, setIsHospitalEditMode }) => {
  const dispatch = useDispatch();
  const { currentActiveTab } = useSelector((state: any) => state.hospitalTab);
  const [docName, setDocName] = useState("");
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [initial_values, setinitial_values] = useState({
    name: "",
    registeration_no: "",
    license_no: "",
    website: "",
    address: "",
    city_id: "",
    state_id: "",
    country_id: "",
    zipcode: "",
    contact_person: "",
    mobile: "",
    landline: "",
    emergency_contact: "",
    ambulance_contact: "",
    email: "",
    logo_url: "",
    banner_url: "",
  });

  const fetch_states = useCallback(
    async (country_id: string, setFieldValue?: any) => {
      if (country_id !== undefined) {
        const request = await getStatesByCountry({
          country_id,
        });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("state_id", "");
            setFieldValue("city_id", "");
            setFieldValue("zipcode", "");
          }
          setstates(
            request?.body?.data?.map((state: any) => {
              return {
                id: state?.id,
                state: state?.state,
              };
            })
          );
        } else {
          setstates([]);
          setFieldValue("state_id", "");
          setFieldValue("city_id", "");
          setFieldValue("zipcode", "");
        }
      }
    },
    []
  );

  const fetch_city = useCallback(
    async (state_id: string, setFieldValue?: any) => {
      if (state_id !== undefined) {
        const request = await getCityByStateList({ state_id });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("city_id", "");
            setFieldValue("zipcode", "");
          }
          setcities(
            request?.body?.data?.map((city: any) => {
              return {
                id: city?.id,
                city: city?.city,
                zipcode: city?.zipcode
              };
            })
          );
        } else {
          setFieldValue("city_id", "");
          setFieldValue("zipcode", "");
          setcities([]);
        }
      }
    },
    []
  );
  useEffect(() => {
    async function fetchCountryData() {
      const { body } = await getCountryList({});
      const updatedCountryList: [] = body?.data?.map((e: any) => ({
        country: e?.country,
        id: e?.id,
      }));
      setCountryOptions(updatedCountryList);
    }
    fetchCountryData();

  }, []);
  const create_hospital_form = async (values: FormikValues, resetForm: any) => {
    if (isHospitalEditMode === true) {
      const request = await updateHospital({ id: hospital?.id, ...values });
      const tab_info = { id: hospital?.id, name: values?.name };
      if (request?.statusCode === 200) {
        resetForm();
        dispatch({ type: ActionTypes.ADD_HOSPITAL_TAB, payload: tab_info });
        SuccessToast(request?.message);
        if (setIsHospitalEditMode) setIsHospitalEditMode(false);
      } else {
        if (request?.errors?.length > 0) {
          ErrorToast(request?.errors[0]?.msg);
        }
      }
    } else {
      const request = await create_hospital(values);

      if (request?.statusCode === 200) {
        resetForm();
        SuccessToast(request?.message);
        toggle("1");
      } else {
        if (request?.errors?.length > 0) {
          ErrorToast(request?.errors[0]?.msg);
        }
      }
    }
  };

  useEffect(() => {
    if (hospital && isHospitalEditMode === true) {
      setinitial_values({
        name: hospital?.name ? hospital?.name : "",
        registeration_no: hospital?.registeration_no ? hospital?.registeration_no : "",
        license_no: hospital?.license_no ? hospital?.license_no : "",
        website: hospital?.website ? hospital?.website : "",
        address: hospital?.address ? hospital?.address : "",
        city_id: hospital?.city?.id ? hospital?.city?.id : "",
        state_id: hospital?.state?.id ? hospital?.state?.id : "",
        country_id: hospital?.country?.id ? hospital?.country?.id : "",
        zipcode: hospital?.zipcode ? hospital?.zipcode : "",
        contact_person: hospital?.contact_person ? hospital?.contact_person : "",
        mobile: hospital?.mobile ? hospital?.mobile : "",
        landline: hospital?.landline ? hospital?.landline : "",
        emergency_contact: hospital?.emergency_contact ? hospital?.emergency_contact : "",
        ambulance_contact: hospital?.ambulance_contact ? hospital?.ambulance_contact : "",
        email: hospital?.email ? hospital?.email : "",
        logo_url: hospital?.logo_url ? hospital?.logo_url : "",
        banner_url: hospital?.banner_url ? hospital?.banner_url : "",
      })
      fetch_states(hospital?.country?.id);
      fetch_city(hospital?.state?.id);
    }
  }, [fetch_city, fetch_states, hospital, isHospitalEditMode])

  /* CLOSE THE POPUP WHEN TABS CHANGE FROM HEADER */
  useEffect(() => {
    if (currentActiveTab !== hospital?.id && setIsHospitalEditMode) {
      setIsHospitalEditMode(false);
    }
  }, [currentActiveTab]);

  const handleChangeCity = (e: any, setFieldValue: any) => {
    setFieldValue("city_id", e.target.value);
    const Zipcode = cities.find((city: any) => city?.id === e.target.value)?.zipcode || "";
    setFieldValue("zipcode", Zipcode);
  }

  return (
    <div
      style={{
        //height: "calc(100vh - 40px)",
        // overflow: "unset",
      }}
    >
      <Header HeaderName={isHospitalEditMode === true ? "Update Hospital" : "Create Hospital"}/>
      <div className="col-12 col">
        <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between only-create-title">
          <HospitalSubHeader />
          {/* <HospitalHeader /> */}
          <div className="page-title-right  d-flex">
                <a className="btn back-btn" style={{ cursor: "pointer" }} onClick={() => toggle("1")}>
                  <i className=" mdi mdi-keyboard-backspace"></i> Back
                </a>
              </div>
        </div>
      </div>
      <div className="row common-top-con">
        {/* <div className="col-12 col">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">{isHospitalEditMode === true ? "Update" : "Create"} Hospital</h4>
          </div>
        </div> */}
        <div className="col-12 hospital-detail-con">
          <div className="col-md-12">
            <Formik
              initialValues={initial_values}
              enableReinitialize
              validationSchema={CreateHospitalSchema}
              onSubmit={(values, { resetForm }) => {
                create_hospital_form(values, resetForm)
              }}
            >
              {(formik) => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  resetForm,
                } = formik;
                return (
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    if (errors) {
                      const firstError = Object.values(errors)[0];

                      if (Array.isArray(firstError)) {
                        const errorMessage = firstError.join(", ");
                        ErrorToast(errorMessage);
                      } else ErrorToast(firstError);
                    }
                    handleSubmit();
                  }}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Basic Info</CardTitle>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                className={`"form-control" ${(errors.name && touched.name) && "error"}`}
                                type="text"
                                placeholder="Enter Name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.name && touched.name && (
                                <span className="error">{errors.name}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Registration No.
                              </Label>
                              <Input
                                name="registeration_no"
                                className={`"form-control" ${(errors.registeration_no && touched.registeration_no) && "error"}`}
                                type="text"
                                placeholder="Enter Registration No"
                                value={values.registeration_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.registeration_no &&
                                touched.registeration_no && (
                                  <span className="error">
                                    {errors.registeration_no}
                                  </span>
                                )} */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <Label className="form-label">License No.</Label>
                              <Input
                                name="license_no"
                                className={`"form-control" ${(errors.license_no && touched.license_no) && "error"}`}
                                type="text"
                                placeholder="Enter License No"
                                value={values.license_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.license_no && touched.license_no && (
                                <span className="error">
                                  {errors.license_no}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label className="form-label">Website</Label>
                              <Input
                                name="website"
                                className={`"form-control" ${(errors.website && touched.website) && "error"}`}
                                type="text"
                                placeholder="Enter Website"
                                value={values.website}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.website && touched.website && (
                                <span className="error">
                                  {errors.website}
                                </span>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Location Info</CardTitle>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                className={`"form-control" ${(errors.address && touched.address) && "error"}`}
                                type="text"
                                placeholder="Enter Address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.address && touched.address && (
                                <span className="error">{errors.address}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">Country</Label>
                              <select
                               className={`form-control ${errors.country_id && touched.country_id ? "error dropdown-error" : ""}`}
                                name="country_id"
                                value={values.country_id}
                                onChange={async (e: any) => {
                                  handleChange(e);
                                  if (e.target.value !== undefined) {
                                    await fetch_states(
                                      e?.target?.value,
                                      setFieldValue
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                              >
                                <option value="">
                                  Select Country
                                </option>
                                {countryOptions?.map((country: any, index: any) => {
                                  return (
                                    <option value={country.id} key={index}>
                                      {country?.country}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* {errors.country_id && touched.country_id && (
                                <span className="error">
                                  {errors.country_id}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">State</Label>
                              <select
                                className={`form-control ${errors.state_id && touched.state_id ? "error dropdown-error" : ""}`}
                                name="state_id"
                                value={values.state_id}
                                onChange={async (e: any) => {
                                  if (states.length === 0) {
                                    handleChange(undefined);
                                  }
                                  handleChange(e);
                                  if (e.target.value !== undefined) {
                                    await fetch_city(
                                      e?.target?.value,
                                      setFieldValue
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                              >
                                <option value="">Select State</option>
                                {states?.map((state: any, index: any) => {
                                  return (
                                    <option value={state.id} key={index}>
                                      {state?.state}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* {errors.state_id && touched.state_id && (
                                <span className="error">{errors.state_id}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">City</Label>
                              <select
                                className={`form-control ${errors.city_id && touched.city_id ? "error dropdown-error" : ""}`}
                                name="city_id"
                                value={values.city_id}
                                onChange={(e) => handleChangeCity(e, setFieldValue)}
                                onBlur={handleBlur}
                              >
                                <option value="">Select City</option>
                                {cities?.map((city: any, index: number) => {
                                  return (
                                    <option value={city.id} key={index}>
                                      {city?.city}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* {errors.city_id && touched.city_id && (
                                <span className="error">{errors.city_id}</span>
                              )} */}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">Zipcode</Label>
                              <Input
                                name="zipcode"
                                className={`"form-control" ${(errors.zipcode && touched.zipcode) && "error"}`}
                                type="text"
                                placeholder="Enter Zipcode"
                                value={values.zipcode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.zipcode && touched.zipcode && (
                                <span className="error">{errors.zipcode}</span>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Contact Info</CardTitle>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Key Contact Person
                              </Label>
                              <Input
                                name="contact_person"
                                className={`"form-control" ${(errors.contact_person && touched.contact_person) && "error"}`}
                                type="text"
                                value={values.contact_person}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Person Name"
                              />
                              {/* {errors.contact_person &&
                                touched.contact_person && (
                                  <span className="error">
                                    {errors.contact_person}
                                  </span>
                                )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Mobile Number
                              </Label>
                              <Input
                                name="mobile"
                                className={`"form-control" ${(errors.mobile && touched.mobile) && "error"}`}
                                type="text"
                                placeholder="Enter Mobile Number (10 digit)"
                                value={values.mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.mobile && touched.mobile && (
                                <span className="error">{errors.mobile}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Landline Number
                              </Label>
                              <Input
                                name="landline"
                                className={`"form-control" ${(errors.landline && touched.landline) && "error"}`}
                                type="text"
                                placeholder="Enter Landline"
                                value={values.landline}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.landline && touched.landline && (
                                <span className="error">{errors.landline}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Emergency Contact
                              </Label>
                              <Input
                                name="emergency_contact"
                                className={`"form-control" ${(errors.emergency_contact && touched.emergency_contact) && "error"}`}
                                type="text"
                                placeholder="Enter Emergency"
                                value={values.emergency_contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.emergency_contact && touched.emergency_contact && (
                                <span className="error">{errors.emergency_contact}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Ambulance Contact
                              </Label>
                              <Input
                                name="ambulance_contact"
                                className={`"form-control" ${(errors.ambulance_contact && touched.ambulance_contact) && "error"}`}
                                type="text"
                                placeholder="Enter Ambulance Contact"
                                value={values.ambulance_contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.ambulance_contact && touched.ambulance_contact && (
                                <span className="error">{errors.ambulance_contact}</span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className={`"form-control" ${(errors.email && touched.email) && "error"}`}
                                type="text"
                                placeholder="Enter Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.email && touched.email && (
                                <span className="error">{errors.email}</span>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle>Branding</CardTitle>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label htmlFor="formFile" className="form-label">
                                Upload Logo
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="formFile"
                                name="logo_url"
                                // value={values.logo_url}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <Label htmlFor="formFile" className="form-label">
                                Upload Banner
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="formFile"
                                name="banner_url"
                                // value={values.banner_url}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle>Upload Document</CardTitle>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label htmlFor="formFile" className="form-label">
                                Document Name
                              </Label>
                              <Input
                                className="form-control"
                                placeholder="Enter Document Name"
                                type="text"
                                id="formFile"
                                onChange={(e) => setDocName(e.target.value)}
                                value={docName}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <Label htmlFor="formFile" className="form-label">
                                Upload Document
                              </Label>
                              <Input
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 button-box">
                            <button
                              className="btn btn-primary mr-2 medium-btn"
                              type="button"
                              onClick={() => setDocName("")}
                            >
                              Add
                            </button>
                            <button
                              className="btn btn-secondary medium-btn"
                              type="button"
                              onClick={() => setDocName("")}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className={`row ${isHospitalEditMode === true ? "mb-5" : ""}`}>
                      <div className="col-12 text-center margin-top-20 mb-5">
                        <button type="submit" className="btn btn-primary mr-2 medium-btn">
                          Save
                        </button>
                        <button className="btn btn-secondary medium-btn ms-2" type="button"
                          onClick={() => {
                            if (isHospitalEditMode === true && setIsHospitalEditMode) {
                              setIsHospitalEditMode(false)
                            } else {
                              resetForm();
                              setDocName("");
                            }
                          }}>
                          {isHospitalEditMode === true ? "Cancel" : "Reset"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateHospital;
