import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { LinearProgress, Select, MenuItem } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { grid_styles } from "../grid_styles";
import { IDiseaseOptions } from "../../CreatePatient";
import { getAdmissionById } from "../../../../redux/actions/patientAction";
import { getSystemicExaminationTypesList } from "../../../../redux/actions/systemicExaminationTypeAction";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPatientAdmissionSystemicExaminationTypes } from "../../../../redux/types/patientAdmissionSystemicExaminationTypes";
import { deletePatientAdmissionSystemicExamination, getPatientAdmissionSystemicExaminationByPatientAdmissionId, postPatientAdmissionSystemicExamination, postPatientAdmissionSystemicExaminationBatch, putPatientAdmissionSystemicExamination, putPatientAdmissionSystemicExaminationBatch } from "../../../../redux/actions/patientAdmissionSystemicExamination";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";

const SystemicExamination: React.FC<any> = ({
  selected_admission,
  setselected_admission,
}) => {
  const initialRows = useMemo(
    () => [
      {
        id: "1",
        examination_type: { id: "", name: " " },
        note: "",
      },
    ],
    []
  );

  type Row = (typeof initialRows)[number];
  const { user_info } = useSelector((state: any) => state?.user);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const systemic_examination_types = useRef<any[]>([])
  const isCellEditable = true;
  const handleRowUpdateError = (error: any) => { };
  const processRowUpdate = (newRow: GridRowModel) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row))
    );
    return newRow;
  };
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);

  const getSystemicExaminationByPatientAdmissionId = async (systemicExaminationTypes: any) => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionSystemicExaminationByPatientAdmissionId(selected_admission?.id);
      if (request?.statusCode === 200) {
        const rowsconvertedForIntial: any = systemicExaminationTypes?.length > 0
          ? systemicExaminationTypes.map((item: any, index: number) => {
            const find_Examination = request?.body?.data.find((resposeData: any) => (resposeData?.examination_type?.id === item?.id));
            return {
              id: find_Examination ? find_Examination?.id : (index + 1).toString(),
              examination_type: { id: item?.id, name: item?.name },
              note: find_Examination ? find_Examination?.note : "",
            };
          })
          : [];
        setRows(rowsconvertedForIntial)
      } else {
        const rowsconvertedForIntial: any = systemicExaminationTypes?.length > 0
          ? systemicExaminationTypes.map((item: any, index: number) => {
            return {
              id: (index + 1).toString(),
              examination_type: { id: item?.id, name: item?.name },
              note: "",
            };
          })
          : [];
        setRows(rowsconvertedForIntial)
      }
    };
  };

  useEffect(() => {
    getSystemicExaminationByPatientAdmissionId(systemic_examination_types.current);
  }, [selected_admission]);

  const refresh_selected_admission = useCallback(async () => {
    const request = await getAdmissionById(selected_admission?.id);
    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(selected_admission);
    }
  }, [selected_admission, setselected_admission]);

  const getSystemicExaminationsTypes = async () => {
    const { body } = await getSystemicExaminationTypesList({});
    if (body) {
      systemic_examination_types.current = body?.data;
      getSystemicExaminationByPatientAdmissionId(body?.data);
    }
    else {
      systemic_examination_types.current = [];
    }
  }

  useEffect(() => {
    getSystemicExaminationsTypes();
  }, []);

  const handleUpdateSystemicExamination = useCallback(async ({ id, examination_type_id, note }: IPatientAdmissionSystemicExaminationTypes) => {
    try {
      if (!selected_admission?.id?.trim()) throw new Error("Please select a hospital first!");
      if (!examination_type_id?.trim()) throw new Error("Examination type is a required field!");
      if (!note?.trim()) throw new Error("Details is a required field!");
      const isInitialRowId = Number(id) < systemic_examination_types.current?.length ? "1" : null;

      const values = {
        patient_admission_id: selected_admission?.id,
        examination_type_id,
        note,
        id: isInitialRowId ? undefined : id,
      };

      const response = isInitialRowId
        ? await postPatientAdmissionSystemicExamination(values)
        : await putPatientAdmissionSystemicExamination(values);

      if (response?.statusCode === 200) {
        await refresh_selected_admission();
        SuccessToast(`Patient Admission Systemic Examination ${isInitialRowId ? "Added" : "Updated"} Successfully!`);
        setrowId(null);
      } else if (response?.statusCode === 400) {
        ErrorToast(response?.errors[0]?.msg);
      } else {
        ErrorToast("Unknown Error Occurred!");
      }
    } catch (error: any) {
      ErrorToast(error?.message);
    }
  },
    [initialRows, selected_admission?.id, refresh_selected_admission]
  );

  const handleDeleteSystemicExamination = useCallback(async (id: string) => {
    try {
      const response = await deletePatientAdmissionSystemicExamination(id);
      if (response?.statusCode === 200) {
        await refresh_selected_admission();
        SuccessToast("Patient Admission Systemic Examination Deleted Successfully!");
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error Occurred!");
    } catch (error: any) {
      ErrorToast(error?.message);
    }
  },
    [refresh_selected_admission]
  );
  const saveOnclick = async () => {
    const createBatchArray = rows.filter((item) => Number(item?.id) && item?.note);
    const updateBatchArray = rows.filter((item) => !Number(item?.id));
    if (updateBatchArray?.length > 0) {
      const updatesRows = await putPatientAdmissionSystemicExaminationBatch(updateBatchArray);
      if (updatesRows?.statusCode === 200)
        (createBatchArray?.length === 0 || (createBatchArray?.length > 0 && updateBatchArray?.length > 0)) && SuccessToast(updatesRows?.message)
    }
    if (createBatchArray?.length > 0) {
      const updatedUpdates = await createBatchArray.map(update => ({
        ...update,
        patient_admission_id: selected_admission?.id,
      }));
      const response = await postPatientAdmissionSystemicExaminationBatch(updatedUpdates);
      if (response?.statusCode === 200)
        updateBatchArray?.length === 0 && SuccessToast(response?.message)
    }
    await getSystemicExaminationByPatientAdmissionId(systemic_examination_types.current);
  };

  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCurrentDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteSystemicExamination(currentDeleteId);
      closeDeleteModal();
    }
  };

  const columns: any = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "examination_type",
        headerName: "Examination Type",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedExaminationTypeId: string) => {
            setrowId(params.id);
            const selectedExaminationType = systemic_examination_types.current?.find(({ id }) => id === selectedExaminationTypeId);
            if (!selectedExaminationType) return;
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;
            const { id, name } = selectedExaminationType;
            const isParamsIdOne = params.id === 1;
            const row = params.row || {};
            const updatedRow = {
              ...rows[rowIndex],
              examination_type: { id, name },
              note: rows[rowIndex]?.note?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.note : row?.note,
            };
            const updatedRows = [...rows.slice(0, rowIndex), updatedRow, ...rows.slice(rowIndex + 1)];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                readOnly
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() => params?.value?.name?.trim() === "" ? "Select Examination Type" : params?.value?.name}
                value={params?.value?.id || params?.row?.examination_type?.id}
                onChange={(event) => handleChange(event.target.value)}
              >
                <MenuItem value=" " disabled>
                  <em>Select Systemic Examination Type</em>
                </MenuItem>
                {systemic_examination_types.current &&
                  systemic_examination_types.current?.length > 0 &&
                  systemic_examination_types.current?.map(
                    ({ name, id }: IDiseaseOptions) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  )}
              </Select>
            </div>
          );
        }
      },
      {
        field: "note",
        type: "TextField",
        headerName: "Details",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions:
          user_info?.hospital_property?.id ===
            selected_admission?.hospital_property?.id &&
            selected_admission?.admitted &&
            !selected_admission?.discharged
            ? (params: any) => [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                color={"primary"}
                disabled={params?.id !== rowId}
                onClick={() =>
                  handleUpdateSystemicExamination({
                    id: params.row.id.toString(),
                    note: params.row.note,
                    // value: params.row.value,
                    // document_name: params.row.document_name,
                    // document_type: params.row.document_type,
                    examination_type_id: params.row.examination_type?.id,
                  })
                }
              />,

              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                disabled={Number(params?.id) <= systemic_examination_types.current?.length}
                onClick={() =>
                  openDeleteModal(
                    params?.row?.id?.toString(),
                  )
                }
              />,
            ]
            : (params: any) => [],
      },
    ],
    [openDeleteModal, handleUpdateSystemicExamination, initialRows, isCellEditable, rowId, rows, selected_admission?.admitted, selected_admission?.discharged, selected_admission?.hospital_property?.id, systemic_examination_types.current, user_info?.hospital_property?.id]
  );

  return (
    <>
      {/* <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id ) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary mb-3"
              type="button"
              onClick={() => addRow()}
            >
              Add Row
            </button>
          )}
      </div> */}
      {!selected_admission?.discharged && (
        <div className="btn-end-coman-save">
          <button className="btn btn-primary mr-2 medium-btn" onClick={saveOnclick}>Save All</button>
        </div>
      )}
      <div className="row">
        <div className="col-12 structure-box">
          <div className="inner-con-box" style={{ height: 250, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="cell"
              rowHeight={35}
              columnHeaderHeight={35}
              disableRowSelectionOnClick={true}
              pagination={true}
              processRowUpdate={processRowUpdate}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 50, page: 0 },
                },
              }}
              autoHeight={true}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              paginationMode="client"
              sx={grid_styles}
              onCellEditStart={onCellEditCommit}
              onProcessRowUpdateError={handleRowUpdateError}
            />
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="systemic examination"
      />
    </>
  );
};

export default SystemicExamination;