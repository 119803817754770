import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/images/login.png";
import {
  forgot_password_code_check,
  forgot_password_email,
} from "../../redux/actions/userAction";
import { formatDateToCustomDateString, formatDateToLocaleDateString } from "../../helpers/helperFunctions";
 
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});
 
const ForgotPassword = () => {
  const [Otp, setOtp] = useState("");
  const [otp_sent, setotp_sent] = useState(false);
  const [otp_error, setotp_error] = useState("");
  const [email_otp, setEmail_otp] = useState("");
  const [timer, setTimer] = useState(60); // Set timer to 60 seconds
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading indication
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Ref for timer with correct type
  const navigate = useNavigate();
 
  useEffect(() => {
    // Function to decrement timer every second
    const countdown = () => {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    };
 
    // Start countdown timer if OTP has been sent
    if (otp_sent && timer > 0) {
      countdown();
    }
 
    // Clear timer when component unmounts
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [otp_sent]);
 
  useEffect(() => {
    if (timer === 0) {
      setotp_sent(false);
      setOtp("");
      setTimer(30);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [timer]);
 
  const send_code = async (values: any) => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    let code_sending = await forgot_password_email(values.email);
    setEmail_otp(code_sending?.body?.otp);
 
    if (code_sending?.statusCode === 200) {
      setotp_sent(true);
      SuccessToast("Otp sent successfully");
    } else {
      ErrorToast(code_sending?.errors[0]?.msg);
    }
    setIsButtonDisabled(false);
    setIsLoading(false);
  };
 
  const check_code = async (values: any) => {
    // Ensure email_otp is defined and check its length
    if (!email_otp || email_otp.length < 6) {
      setotp_error("Otp is not valid");
      setTimeout(() => {
        setotp_error("");
      }, 2000);
      return;
    }
 
    // Check if the OTP entered by the user matches the OTP sent to the email
    if (email_otp !== Otp) {
      ErrorToast("Entered OTP does not match. Please try again.");
      setotp_error("Entered OTP does not match. Please try again.");
      setTimeout(() => {
        setotp_error("");
      }, 3000);
      return;
    }
 
    try {
      // Call the function to check the OTP, using email_otp
      let check_code_sending = await forgot_password_code_check(values.email, email_otp);
 
      if (check_code_sending?.statusCode === 200) {
        SuccessToast("Otp verified successfully");
        navigate("/reset-password", {
          state: { email: values?.email, otp: email_otp }, // Pass email_otp here
        });
      } else {
        // Display error message if OTP verification fails
        ErrorToast(check_code_sending?.errors[0]?.msg || "OTP verification failed");
      }
    } catch (error) {
      // Handle unexpected errors
      ErrorToast("An error occurred. Please try again later.");
      console.error("Error during OTP verification:", error);
    }
  };
 
 
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Link to="/" className="text-center login-img">
                      <img src={profile} alt="" className="img-fluid" />
                    </Link>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={ForgotPasswordSchema}
                      onSubmit={otp_sent ? check_code : send_code}
                    >
                      {(formik) => {
                        const {
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          handleBlur,
                          isValid,
                          dirty,
                        } = formik;
                        return (
                          <Form
                            className="form-horizontal"
                            onSubmit={handleSubmit}
                          >
                            <h4 className="card-title mb-3 mt-2">
                              Forgot Password
                            </h4>
                            {!otp_sent && (
                              <div className="mb-3 email-box">
                                <Label className="form-label">
                                  Email Address
                                </Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email Address"
                                  type="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.email && touched.email && (
                                  <span className="error">{errors.email}</span>
                                )}
                              </div>
                            )}
                            {otp_sent && (
                              <div className="mb-3">
                                <Label className="form-label">OTP</Label>
                                <OtpInput
                                  value={Otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  renderSeparator={<span style={{ margin: 5 }} className="otp-separator"> </span>}
                                  renderInput={(props) => <input {...props} className="otp-input" />}
                                />
                                {otp_error && <span className="error-label">{otp_error}</span>}
                              </div>
                            )}
                            <div className="mt-3 d-grid">
                              <button
                                type="submit"
                                className={
                                  dirty && isValid
                                    ? "btn btn-primary btn-block"
                                    : "btn btn-primary btn-block disabled-btn"
                                }
                                disabled={!(dirty && isValid) || isButtonDisabled}
                              >
                                {isLoading ? "Sending OTP..." : (otp_sent ? "Verify Code" : "Send Otp")}
                              </button>
                            </div>
                            {otp_sent && timer > 0 && (
                              <div className="mt-4 text-center text-muted">
                                {`Time remaining: ${timer}s`}
                              </div>
                            )}
                            {!otp_sent && (
                              <div className="mt-4 text-center text-muted">
                                Didn't receive a code?
                                <Link
                                  to="/forgot-password"
                                  className="text-secondary"
                                >
                                  &nbsp; Resend
                                </Link>
                              </div>
                            )}
                            {!otp_sent && (
                              <div className="mt-4 text-center text-muted">
                                Remember Password?
                                <Link to="/" className="text-secondary">
                                  &nbsp; Login
                                </Link>
                              </div>
                            )}
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="text-muted">
                  © {new Date().getFullYear()} Entel. Crafted With{" "}
                  <i className="mdi mdi-heart text-danger" /> By{" "}
                  <a
                    className="text-muted"
                    href="https://www.atozinfoway.com/"
                    title="AtoZ Infoway"
                    target="blank"
                  >
                    {" "}
                    AtoZ Infoway.
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <p className="account-pages-footer">
          <Link className="text-muted" to="/">
            Privacy Policy{" "}
          </Link>{" "}
          &nbsp; &nbsp; | &nbsp; &nbsp;
          <Link className="text-muted" to="/">
            Terms & Conditions
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};
 
export default ForgotPassword;
 