import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../utils/toastNotifications";
import {
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
  putPatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";
import { v4 as uuidv4 } from 'uuid';
import SvgFilesIcon from "../../../../../components/SvgFiles/SvgFiles";

const PatientAdmissionHROutput: React.FC<any> = ({
  selected_admission,
  selected_date,
}) => {
  const initialRows = useMemo(() =>
    Array.from({ length: 24 }).map((_, i) => {
      const startHour = (i % 24).toString().padStart(2, "0");
      const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
      const selected_time = `${startHour}:00-${endHour}:00`;
      return {
        id: uuidv4().toString(),
        datetime: new Date(),
        selected_time,
        aspiration: "",
        blood_loose: "",
        drain_output: "",
        urine: "",
        diarrhea: "",
        hourly_total: "0",
        day_total: "0",
      };
    }),
    []);
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const [rowId, setrowId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const { user_info } = useSelector((state: any) => state?.user);

  const admission_hourly_report = useCallback(async () => {
    if (selected_admission !== null) {
      const date = selected_date
        ? new Date(selected_date?.substring(0, 10))
        : new Date();
      const tommorow = new Date(date);
      tommorow.setDate(date?.getDate() + 1);
      const yestrday = new Date(date);
      yestrday.setDate(date?.getDate() - 1);
      yestrday.setHours(23, 59, 59, 999);

      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "output",
        new Date(yestrday).toISOString(),
        new Date(tommorow).toISOString()
      );

      if (request?.statusCode === 200) {
        const requestedData = request?.body?.data[0];
        setrowId(requestedData?.id);

        if (requestedData?.reportValues?.length > 0) {
          const reorderedArray = requestedData?.reportValues;
          recalculateTotals(reorderedArray); // Recalculate totals after fetching data
        } else {
          setRows(initialRows); // Initialize with default values if no data
          recalculateTotals(initialRows); // Recalculate totals for default values
        }
      } else {
        setrowId('');
        setRows(initialRows); // Initialize with default values on error
        recalculateTotals(initialRows); // Recalculate totals for default values
      }
    }
  }, [selected_date, selected_admission, initialRows]);


  useEffect(() => {
    recalculateTotals(rows);
  }, [rows]);

  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);

  const handleAdmissionHourlyReport = async () => {
    if (!rowId) {
      let jsonDaa = {
        reportValues: rows,
        category: "output",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonDaa);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message)
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: rows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message)
        admission_hourly_report();
      } else {
        ErrorToast(request?.message)
      }
    }
  }
  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };

  const confirmDelete = async () => {
    if (currentDeleteId) {
      const updatedRows = rows.map((row) =>
        row.id === currentDeleteId
          ? {
            ...row,
            aspiration: "",
            blood_loose: "",
            drain_output: "",
            urine: "",
            diarrhea: "",
            hourly_total: "",
            day_total: "",
          }
          : row
      );
      recalculateTotals(updatedRows);

      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
      if (request?.statusCode === 200) {
        SuccessToast("Row Deleted Successfully");
        admission_hourly_report();
      } else {
        ErrorToast(request?.message);
      }
    }
    closeDeleteModal();
  };


  const recalculateTotals = (updatedRows: Row[]) => {
    let cumulativeDayTotal = 0; // Initialize cumulative day total

    updatedRows.forEach((row) => {
      // Calculate hourly total for the current row
      const hourlyTotal = (
        Number(row.aspiration) +
        Number(row.blood_loose) +
        Number(row.drain_output) +
        Number(row.urine) +
        Number(row.diarrhea)
      ).toString();

      // Set the hourly total for the row
      row.hourly_total = hourlyTotal;

      // Calculate and set the cumulative day total
      cumulativeDayTotal += parseFloat(hourlyTotal) || 0;
      row.day_total = cumulativeDayTotal.toString();
    });
    setRows(updatedRows); // Update state with recalculated rows
  };

  const onchageTexfields = useCallback((event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) => i === index ? {
      ...row,
      [name]: value.replace(/[^0-9.]/g, '') // Allow numbers and dots
        .replace(/(\..*)\./g, '$1') // Allow only one dot
        .replace(/(\.\d{2})\d+/g, '$1') // Allow up to two decimal places
    } : row)
    recalculateTotals(updatedRows);
  }, [rows]);

  useEffect(() => {
    admission_hourly_report();
  }, [admission_hourly_report]);

  const shouldShowActions = () => {
    return (
      (user_info?.is_super_admin ||
        new Date(selected_date).toDateString() === new Date().toDateString()) &&
      selected_admission?.admitted &&
      !selected_admission?.discharged
    );
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission HR Output"
      />
      {shouldShowActions() && (
        <div className="report-save-all-btn">
          <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport}>
            Save All
          </button>
        </div>
      )}
      <div className="hourly-table-scroll">
        <table className="table table-hover fixed-table-top">
          <thead className="table-light">
            <tr>
              <th className="time-col">Time</th>
              <th>Urine (ML)</th>
              <th>Aspiration (ML)</th>
              <th>Blood Loss (ML)</th>
              <th>Drain Output (ML)</th>
              <th>Diarrhea (ML)</th>
              <th>Hourly Total (ML)</th>
              <th>Day Total (ML)</th>
              {shouldShowActions() && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, index: number) => {
              const isRowEmpty =
                !row.urine && !row.aspiration && !row.blood_loose && !row.drain_output && !row.diarrhea;
              return (
                <tr key={index}>
                  <td className="time-col">{row?.selected_time}</td>
                  <td><input type="text" name="urine" value={row?.urine} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="aspiration" value={row?.aspiration} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="blood_loose" value={row?.blood_loose} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="drain_output" value={row?.drain_output} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="diarrhea" value={row?.diarrhea} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td>{row?.hourly_total}</td>
                  <td>{row?.day_total}</td>
                  {shouldShowActions() && (<td className="hospital-card-box">
                    <div className="user-setting-box">
                      <div
                        className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                        title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                        onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                        style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                      >
                        <i className="fas fa-redo"></i>
                      </div>
                    </div>
                  </td>)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PatientAdmissionHROutput;

