import { useCallback, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PatientsList from "./PatientList";
import CreatePatient from "./CreatePatient";
import { useDispatch, useSelector } from "react-redux";
import SinglePatient from "./SinglePatient";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/scss/teleicu/patients.scss";
import PatientAdmission from "./PatientAdmission";
import { patient_access } from "../../helpers/access_policies";
import NoAccess from "../../components/NoAccess";
import Header from "../../components/Header/Header";
import PatientHeader from "../../components/Header/PatientHeader";

const Patients = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { patientTabs, patientTab, currentPatientActiveTab, patientMainTab } = useSelector((state: any) => state.patientTab);
  const [basicInfoTab, setBasicInfoTab] = useState<string | null>(null);
  const [isBasicInfoEditable, setIsBasicInfoEditable] = useState<boolean>(false);
  const { user_info } = useSelector((state: any) => state.user);

  const [tab, settab] = useState<any>(undefined);

  useEffect(() => {
    if (user_info?.is_super_admin) {
      if (patientTab && patientTab?.id === currentPatientActiveTab) {
        return;
      }
      dispatch({
        type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
        payload: "allpatients",
      });
      settab("allpatients");
    } else if (user_info?.is_admin) {
      if (patientTab && patientTab?.id === currentPatientActiveTab) {
        return;
      }
      dispatch({
        type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
        payload: "admitted",
      });
      settab("admitted");
    } else {
      if (patientTab && patientTab?.id === currentPatientActiveTab) {
        return;
      }
      dispatch({
        type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
        payload: "admitted",
      });
      settab("admitted");
    }
  }, [user_info, dispatch])

  const toggle = useCallback(
    (innertab: string) => {
      if (currentPatientActiveTab !== innertab) {
        if (location?.pathname !== "/patients") {
          navigate("/patients");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
          payload: { id: innertab },
        });
        settab(innertab);
      }
    },
    [currentPatientActiveTab, dispatch, location?.pathname, navigate]
  );


  const add_patient_tab = useCallback((tab: { id: string, name: string }) => {
    dispatch({ type: ActionTypes.ADD_Patient_TAB, payload: tab });
  }, [dispatch]);

  const handleBasicInfoEdit = (patient: any) => {
    setBasicInfoTab("3");
    setIsBasicInfoEditable(true);
    const tab_info = {
      id: patient?.id,
      name: patient?.first_name + " " + patient?.middle_name + " " + patient?.last_name
    };

    if (patientTabs?.some((tab: any) => tab?.id === patient?.id)) {
      toggle(patient?.id);
      dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
    } else {
      add_patient_tab(tab_info);
      toggle(patient?.id);
      dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
    }
  };

  const handleBasicPatientView = (patient: any) => {
    setBasicInfoTab("1");
    setIsBasicInfoEditable(true);
    const tab_info = {
      id: patient?.id,
      name: patient?.first_name + " " + patient?.middle_name + " " + patient?.last_name
    };
    if (patientTabs?.some((tab: any) => tab?.id === patient?.id)) {
      toggle(patient?.id);
      dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
    } else {
      add_patient_tab(tab_info);
      toggle(patient?.id);
      dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
    }
  };

  const remove_patient_tab = useCallback(
    (innertab: any) => {
      dispatch({
        type: ActionTypes.REMOVE_Patient_TAB,
        payload: innertab,
      });
      settab(user_info?.is_super_admin ? 'allpatients' : 'admitted')
      dispatch({
        type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
        payload: undefined,
      });
    },
    [toggle, dispatch, currentPatientActiveTab]
  );

  return (
    <>
      <Header HeaderName="Patient" />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="col-12 col">
              <div className="page-title-box common-top-fix-title d-sm-flex justify-content-between">
                {patientMainTab === '1' && <div className="d-sm-flex align-items-center justify-content-between">
                  {user_info?.is_super_admin === false ? (
                    <Nav tabs className="secondary-tab">
                      <NavItem>
                        <NavLink
                          className={(patientMainTab === '1' && tab === "admitted" && !currentPatientActiveTab) ? "active" : ""}
                          onClick={() => {
                            settab("admitted");
                            dispatch({
                              type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
                              payload: undefined,
                            });
                            dispatch({
                              type: ActionTypes.SET_Patient_TAB,
                              payload: undefined,
                            });
                          }}
                        >
                          Admitted Patients
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={(patientMainTab === '1' && tab === "created" && !currentPatientActiveTab) ? "active" : ""}
                          onClick={() => {
                            settab("created");
                            dispatch({
                              type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
                              payload: undefined,
                            });
                            dispatch({
                              type: ActionTypes.SET_Patient_TAB,
                              payload: undefined,
                            });
                          }}
                        >
                          Created Patients
                        </NavLink>
                      </NavItem>
                      {/* <PatientSubHeader /> */}
                      {patientTabs?.length > 0 &&
                        patientTabs?.map((pTab: any) => {
                          return (
                            <NavItem key={pTab?.id}>
                              <NavLink
                                className={(currentPatientActiveTab !== "allpatients" && currentPatientActiveTab !== "created" && currentPatientActiveTab !== "admitted" && currentPatientActiveTab === pTab?.id) ? "active" : ""}
                                onClick={() => {
                                  dispatch({
                                    type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
                                    payload: undefined,
                                  });
                                  dispatch({
                                    type: ActionTypes.SET_Patient_TAB,
                                    payload: pTab,
                                  });
                                  toggle(pTab?.id);
                                }}
                              >
                                <span>
                                  {pTab?.name}
                                </span>{" "}
                                <i
                                  style={{
                                    color:
                                      (currentPatientActiveTab !== "allpatients" && currentPatientActiveTab !== "created" && currentPatientActiveTab !== "admitted" && currentPatientActiveTab === pTab?.id)
                                        ? "#3D79F7"
                                        : "#fff",
                                  }}
                                  className="mdi mdi-close"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    remove_patient_tab(pTab);
                                  }}
                                ></i>
                              </NavLink>
                            </NavItem>
                          );
                        })}
                    </Nav>
                  ) : (
                    <Nav tabs className="secondary-tab">
                      <NavItem>
                        <NavLink
                          className={(patientMainTab === '1' && tab === "allpatients" && !currentPatientActiveTab) ? "active" : ""}
                          onClick={() => {
                            settab("allpatients");
                            dispatch({
                              type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
                              payload: undefined,
                            });
                            dispatch({
                              type: ActionTypes.SET_Patient_TAB,
                              payload: undefined,
                            });
                          }}
                        >
                          All Patients
                        </NavLink>
                      </NavItem>

                      {/* <PatientSubHeader /> */}
                      {patientTabs?.length > 0 &&
                        patientTabs?.map((pTab: any) => {
                          return (
                            <NavItem key={pTab?.id}>
                              <NavLink
                                className={(currentPatientActiveTab !== "allpatients" && currentPatientActiveTab !== "created" && currentPatientActiveTab !== "admitted" && currentPatientActiveTab === pTab?.id) ? "active" : ""}
                                onClick={() => {
                                  dispatch({
                                    type: ActionTypes.SET_Patient_TAB,
                                    payload: pTab,
                                  });
                                  toggle(pTab?.id);
                                }}
                              >
                                <span>
                                  {pTab?.name}
                                </span>{" "}
                                <i
                                  style={{
                                    color:
                                      (currentPatientActiveTab !== "allpatients" && currentPatientActiveTab !== "created" && currentPatientActiveTab !== "admitted" && currentPatientActiveTab === pTab?.id)
                                        ? "#3D79F7"
                                        : "#fff",
                                  }}
                                  className="mdi mdi-close"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    remove_patient_tab(pTab);
                                  }}
                                ></i>
                              </NavLink>
                            </NavItem>
                          );
                        })}
                    </Nav>
                  )}
                </div>}
                {patientMainTab === '2' && <div className="d-sm-flex align-items-center justify-content-between">
                  <Nav tabs className="secondary-tab">
                    <NavItem>
                      <NavLink className="active">
                        Create New Patient
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>}
                {patientMainTab === '3' && <div className="d-sm-flex align-items-center justify-content-between">
                  <Nav tabs className="secondary-tab">
                    <NavItem>
                      <NavLink className="active">
                        Patient Admission
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>}
                <div className="d-sm-flex align-items-center justify-content-between">
                  <PatientHeader />
                </div>
              </div>
            </div>

            <TabContent
              activeTab={
                currentPatientActiveTab && patientMainTab === '1'
                  ? currentPatientActiveTab
                  : patientMainTab || "1"
              }
              className="text-muted"
            >
              {(patientMainTab === '1' && (tab === "allpatients" || tab === "created" || tab === "admitted" || !currentPatientActiveTab)) &&
                <TabPane tabId="1">
                  {user_info?.user_access?.includes(patient_access.view_all_patients)
                    ? (<PatientsList
                      tab={tab}
                      setIsBasicInfoEditable={setIsBasicInfoEditable}
                      handleBasicInfoEdit={handleBasicInfoEdit}
                      setBasicInfoTab={setBasicInfoTab}
                      handleCardView={handleBasicPatientView}
                    />)
                    : (<NoAccess text="Patient access restricted" />)
                  }
                </TabPane>
              }
              {patientMainTab === "2" &&
                <TabPane tabId="2">
                  {user_info?.user_access?.includes(
                    patient_access.create_patient
                  ) ? (
                    <CreatePatient toggle={toggle} />
                  ) : (
                    <NoAccess text="Create patient access restricted" />
                  )}
                </TabPane>
              }
              {patientMainTab === "3" &&
                <TabPane tabId="3">
                  {user_info?.user_access?.includes(
                    patient_access.admit_patient
                  ) ? (
                    <PatientAdmission />
                  ) : (
                    <NoAccess text="Admit patient access restricted" />
                  )}
                </TabPane>
              }
              {patientTabs.length > 0 && currentPatientActiveTab && (
                <TabPane tabId={currentPatientActiveTab}>
                  <SinglePatient
                    patient_id={patientTab?.id}
                    basicInfoTab={basicInfoTab}
                    isBasicInfoEditable={isBasicInfoEditable}
                    setIsBasicInfoEditable={setIsBasicInfoEditable}
                  />
                </TabPane>
              )}
            </TabContent>
          </div>
        </div>
      </div >
    </>
  );
};

export default Patients;