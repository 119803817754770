import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Doughnut from "../AllCharts/echart/doughnutchart";
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPatientsByHospital, getPatientsByHospitalProperty, getPatientsByHospitalPropertyForChart } from "../../redux/actions/patientAction";
import { ErrorToast } from "../../utils/toastNotifications";
import { ERROR_MESSAGES } from "../../helpers/constants";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import DataModelComponent from "../../components/DataModelComponent/DataModelComponent";
import { all_hospitals_without_pagination } from "../../redux/actions/hospitalAction";
import { hospital_property_by_hospital, hospital_property_with_discharge_count, hospital_property_with_patient_count } from "../../redux/actions/hospitalPropertyAction";
import { DropdownField } from "../../components/CommonHospitalComponent/CommonDropdown";
import HospitalTable from "../../components/DashboardComponets/HospitalWisePatientTable";
import { PatientTable } from "../../components/DashboardComponets/PatientDischargeTable";
import { getDischargeTypesList } from "../../redux/actions/dischargeAction";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import { getPatientsByHospitalForCounts } from "../../redux/actions/patientAction";
import { getPatientsByHospitalForChart } from "../../redux/actions/patientAction";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";
import { ActionTypes } from "../../redux/types/patientTabTypes";

interface Property {
  country: { id: string; country: string };
  state: { id: string; state: string };
  city: { id: string; city: string };
}

interface Option {
  id: string;
  name: string;
}
const Dashboard = () => {
  const location = useLocation();
  const current_Date = new Date();
  const { user_info } = useSelector((state: any) => state.user);
  const [chartData, setChartData] = useState({
    dischargePatientsCount: 0,
    admittedPatientsCount: 0,
  });
  const [header_state, setheader_state]: any = useState(Boolean(localStorage.getItem("header")));
  const [tab, setTab] = useState("today");
  const [totalPatientsCount, setTotalPatientsCount] = useState<number>(0);
  const [currentlyAdmitedPatientCount, setCurrentlyAdmitedPatientCount] = useState<number>(0);
  const [currentlyCriticalCount, setCurrentlyCriticalCount] = useState<number>(0);
  const [currentlyOnVentilator, setCurrentlyOnVentilator] = useState<number>(0)
  const [fromDate, setFromDate] = useState(new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 0, 0, 0));
  const [toDate, setToDate] = useState(new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 23, 59, 59));
  const [fromDateString, setFromDateString] = useState<string>(fromDate.toISOString().split("T")[0]);
  const [toDateString, setToDateString] = useState<string>(toDate.toISOString().split("T")[0]);
  const [hospitals, setHospitals] = useState<any[]>([]);
  const [allPatients, setAllPatients] = useState<any[]>([]);
  const [allDischargeTypes, setallDischargeTypes] = useState<any>([]);
  const [dischargedType, setDischargedTypes] = useState<any>([]);
  const [showTotalPatientsModal, setShowTotalPatientsModal] = useState(false);
  const [showAdmittedPatientsModal, setShowAdmittedPatientsModal] = useState(false);
  const [showCriticalPatientsModal, setShowCriticalPatientsModal] = useState(false);
  const [showOnVentilatorModal, setShowOnVentilatorModal] = useState(false);
  const [hospitalOpenPatientsList, setHospitalOpenPatientsList] = useState(false);
  const [countryOptions, setCountryOptions] = useState<Option[]>([]);
  const [stateOptions, setStateOptions] = useState<Array<Option & { country: string }>>([]);
  const [cityOptions, setCityOptions] = useState<Array<Option & { state: string; country: string }>>([]);
  const [filteredStateOptions, setFilteredStateOptions] = useState<Option[]>([]);
  const [filteredCityOptions, setFilteredCityOptions] = useState<Option[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("All");
  const [selectedState, setSelectedState] = useState<string>("All");
  const [selectedCity, setSelectedCity] = useState<string>("All");
  const [selectedHospital, setSelectedHospital] = useState<any>(null);
  const [hospitalProperties, setHospitalProperties] = useState<any[]>([]);
  const [filteredHospitalProperties, setFilteredHospitalProperties] = useState<any[]>([]);
  const [selectedHospitalId, setSelectedHospitalId] = useState(user_info?.hospital_property?.hospital?.id || "");
  const [selectedHospitalPropertyId, setSelectedHospitalPropertyId] = useState(
    user_info?.is_admin || user_info?.is_super_admin ? "All" : user_info?.hospital_property?.id
  ); const [hospitalWisePatientCount, SetHospitalWisePatientCount] = useState<any[]>([])
  const [hospitalWisePatientcountfilter, setHospitalWisePatientcountfilter] = useState<any[]>([])
  const [dischargeTypeCount, setDischargeTypeCount] = useState<any[]>([])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientTabs, currentPatientActiveTab } = useSelector((state: any) => state?.patientTab);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const toggleFilterModal = () => setFilterModalOpen(!isFilterModalOpen)
  const sharedColumns = {
    tab1: ['Sr', 'Hospital Name', 'Patient Name', 'Admitted Date', 'Is Critical', 'Is Ventilator', 'Length Of Stay', 'Action'],
    tab2: ['Sr', 'Hospital Name', 'Patient Name', 'Admitted Date', 'Discharge Date', 'Discharge Type', 'Is Critical', 'Is Ventilator', 'Length Of Stay', 'Action'],
  };
  const hospitalSpecificColumns = {
    tab1: ['Sr', 'Patient Name', 'Admitted Date', 'Is Critical', 'Is Ventilator', 'Length Of Stay', 'Action'],
    tab2: ['Sr', 'Patient Name', 'Admitted Date', 'Discharge Date', 'Discharge Type', 'Is Critical', 'Is Ventilator', 'Length Of Stay', 'Action'],
  };

  const handlePatientsListClick = (hospital: any) => {
    setSelectedHospital(hospital);
    setHospitalOpenPatientsList(true);
  };

  const renderRow = (patient: any, index: number, activeTab: string, showHospitalName?: boolean) => {
    const admissionDate = new Date(patient?.admission_date).setHours(0, 0, 0, 0);
    const dischargeDate = patient?.discharged
      ? new Date(patient?.discharge_date_entry).setHours(0, 0, 0, 0)
      : new Date().setHours(0, 0, 0, 0);

    const lengthOfStayInMilliseconds = dischargeDate - admissionDate;
    const patientLength = Math.ceil(lengthOfStayInMilliseconds / (1000 * 60 * 60 * 24));
    const hospitalColumn = showHospitalName ? (
      <td>{patient?.hospital_property?.landmark}</td>
    ) : null;
    if (activeTab === '1' && !patient.discharged) {
      return (
        <tr key={patient.id}>
          <td>{index + 1}</td>
          {hospitalColumn}
          <td>{patient?.patient?.first_name} {patient?.patient?.last_name}</td>
          <td>{new Date(admissionDate).toLocaleDateString()}</td>
          <td>{patient?.is_critical ? 'Yes' : 'No'}</td>
          <td>{patient?.is_on_ventilator ? 'Yes' : 'No'}</td>
          <td>{patientLength} days</td>
          <td>
            <a onClick={() => handleNavigatePatient(patient)} className="dashboard-patient-view">
              <SvgFilesIcon iconname={"viewdetails"} />
            </a>
          </td>
        </tr>
      );
    } else if (activeTab === '2' && patient.discharged) {
      return (
        <tr key={patient.id}>
          <td>{index + 1}</td>
          {hospitalColumn}
          <td>{patient?.patient.first_name} {patient?.patient.last_name}</td>
          <td>{new Date(admissionDate).toLocaleDateString()}</td>
          <td>{new Date(dischargeDate).toLocaleDateString()}</td>
          <td>{patient.typeof_discharge?.name}</td>
          <td>{patient.is_critical ? 'Yes' : 'No'}</td>
          <td>{patient.is_on_ventilator ? 'Yes' : 'No'}</td>
          <td>{patientLength} days</td>
          <td>
            <a onClick={() => handleNavigatePatient(patient)} className="dashboard-patient-view">
              <SvgFilesIcon iconname={"viewdetails"} />
            </a>
          </td>
        </tr>
      );
    }
    return null;
  };

  const toggle = useCallback(
    (tab: string) => {
      if (currentPatientActiveTab !== tab) {
        if (location?.pathname !== "/patients") {
          navigate("/patients");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
          payload: { id: tab },
        });
      }
    },
    [currentPatientActiveTab, dispatch, location?.pathname, navigate]
  );

  const add_patient_tab = useCallback((tab: { id: string, name: string }) => {
    const isTabOpen = patientTabs.some((patientTab: { id: string; }) => patientTab.id === tab.id);
    if (!isTabOpen) {
      dispatch({ type: ActionTypes.ADD_Patient_TAB, payload: tab });
    }
    dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab });
  }, [dispatch, patientTabs]);

  const handleNavigatePatient = (patient_admission: any) => {
    navigate("/patients");
    toggle(patient_admission?.patient?.id);

    const tab_info = {
      id: patient_admission?.patient?.id,
      name: `${patient_admission?.patient?.first_name} ${patient_admission?.patient?.middle_name} ${patient_admission?.patient?.last_name}`,
    };
    add_patient_tab(tab_info);
    dispatch({
      type: ActionTypes.SET_Patient_TAB,
      payload: tab_info,
    });
    dispatch({
      type: ActionTypes.SET_Main_Patient_TAB,
      payload: {
        patientMainTab: "1",
      },
    });
  }


  // useEffect(() => {
  //   const chartData = async () => {
  //     if (selectedHospitalPropertyId !== "All" && selectedHospitalPropertyId !== "") {
  //       const chartDataFilterValues = await getPatientsByHospitalPropertyForChart(selectedHospitalPropertyId ? selectedHospitalPropertyId : user_info?.hospital_property?.id, fromDate.toISOString(), toDate.toISOString());
  //       if (chartDataFilterValues?.statusCode === 200) setChartData(chartDataFilterValues?.body)
  //       else setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
  //     } else {
  //       const chartDataFilterValues = await getPatientsByHospitalForChart(selectedHospitalId ? selectedHospitalId : user_info?.hospital_property?.hospital?.id, fromDate.toISOString(), toDate.toISOString(), selectedCountry, selectedCity, selectedState);
  //       if (chartDataFilterValues?.statusCode === 200) setChartData(chartDataFilterValues?.body)
  //       else setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
  //     }
  //   }
  //   chartData();
  // }, [tab, selectedHospitalPropertyId])

  useEffect(() => {
    const chartData = async () => {
      if (selectedHospitalPropertyId !== "All" && selectedHospitalPropertyId !== "") {
        const chartDataFilterValues = await getPatientsByHospitalPropertyForChart(
          selectedHospitalPropertyId ? selectedHospitalPropertyId : user_info?.hospital_property?.id,
          fromDate.toISOString(),
          toDate.toISOString()
        );
        if (chartDataFilterValues?.statusCode === 200) setChartData(chartDataFilterValues?.body);
        else setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
      } else {
        const chartDataFilterValues = await getPatientsByHospitalForChart(
          selectedHospitalId ? selectedHospitalId : user_info?.hospital_property?.hospital?.id,
          fromDate.toISOString(),
          toDate.toISOString(),
          selectedCountry,
          selectedCity,
          selectedState
        );
        if (chartDataFilterValues?.statusCode === 200) setChartData(chartDataFilterValues?.body);
        else setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
      }
    };
    chartData();
  }, [tab, selectedHospitalPropertyId]);



  const chartDataFiltering = async () => {
    if ((selectedHospitalId && (selectedHospitalPropertyId === "" || selectedHospitalPropertyId === "All"))) {
      const chartdata = await getPatientsByHospitalForChart(selectedHospitalId ? selectedHospitalId : user_info?.hospital_property?.hospital?.id, fromDate.toISOString(), toDate.toISOString(), selectedCountry, selectedCity, selectedState);
      if (chartdata?.statusCode === 200) setChartData(chartdata?.body)
      else setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
    }
  }

  // useEffect(() => {
  //   chartDataFiltering();
  // }, [tab, selectedHospitalId, selectedCity, selectedCountry, selectedState])


  const fetch_patients = async () => {
    try {
      if (selectedHospitalPropertyId !== "All" && selectedHospitalPropertyId) {
        const allPatientsResponse = await getPatientsByHospitalProperty(selectedHospitalPropertyId ? selectedHospitalPropertyId : user_info?.hospital_property?.id);
        if (allPatientsResponse?.statusCode === 200) {
          const allPatients = allPatientsResponse?.body?.data;
          setAllPatients(allPatients);
          setTotalPatientsCount(allPatientsResponse?.body?.page_count || 0);
          setCurrentlyAdmitedPatientCount(allPatientsResponse?.body?.admitted_count || 0);
          setCurrentlyCriticalCount(allPatientsResponse?.body?.critical_count || 0);
          setCurrentlyOnVentilator(allPatientsResponse?.body?.vantilator_Count);
        } else {
          setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
          setAllPatients([]);
          setCurrentlyCriticalCount(0)
          setCurrentlyOnVentilator(0)
          setTotalPatientsCount(0);
          setCurrentlyAdmitedPatientCount(0);
        }
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  const settodayDate = () => {
    const startOfDay = new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 0, 0, 0);
    const endOfDay = new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 23, 59, 59);
    setFromDate(startOfDay);
    setToDate(endOfDay);
    setTab("today")
  };
  const setCustomeDate = () => {
    const startOfDay = new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 0, 0, 0);
    const endOfDay = new Date(current_Date.getFullYear(), current_Date.getMonth(), current_Date.getDate(), 23, 59, 59);
    setFromDate(startOfDay);
    setToDate(endOfDay);
    setTab("custom")
  };

  const setmonthDate = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    setFromDate(firstDayOfMonth);
    setToDate(lastDayOfMonth);
    setTab("month");
  };
  const handleCustomClick = () => {
    toggleFilterModal();
    setCustomeDate();
    setTab("custom");
  };

  const setYearDate = () => {
    const currentDate = new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

    setFromDate(firstDayOfYear);
    setToDate(endOfCurrentDate);
    setTab("year");
  };

  useEffect(() => {
    fetch_patients();
  }, [selectedHospitalPropertyId, selectedCity, selectedCountry, selectedState]);

  useEffect(() => {
    setFromDate(new Date(fromDateString));
  }, [fromDateString]);

  useEffect(() => {
    setToDate(new Date(toDateString));
  }, [toDateString]);

  const applyFilters = async () => {
    try {
      if ((fromDate && !toDate) || (!fromDate && toDate)) {
        ErrorToast(ERROR_MESSAGES.missingDates);
        return;
      }
      if (
        (fromDate && !toDate) ||
        (!fromDate && toDate) ||
        (fromDate && toDate && (!fromDate.toString().trim() || !toDate.toString().trim()))
      ) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        return;
      }

      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      if (toDateObj < fromDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        return;
      }
      chartDataFiltering();
      fetch_patients();
      setTab("custom");
      setFilterModalOpen(false);
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  }
  const resetDateFilters = async () => {
    const currentDate = new Date();
    setFromDate(currentDate);
    setToDate(currentDate);
    setFromDateString(currentDate.toISOString());
    setToDateString(currentDate.toISOString());
    setChartData({ dischargePatientsCount: 0, admittedPatientsCount: 0 });
    fetch_patients()
  };


  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const request = await all_hospitals_without_pagination();
        const hospitalsData = request?.body?.data;
        let filteredHospitals;
        const response = await getDischargeTypesList({});
        if (response?.statusCode === 200) {
          setDischargedTypes(response?.body?.data)
        } else {
          setDischargedTypes([])
        }
        if (user_info?.is_admin) {
          filteredHospitals = hospitalsData?.filter((hospital: any) => hospital?.user_info !== null);
        } else {
          filteredHospitals = hospitalsData;
        }
        setHospitals(filteredHospitals);
      } catch (error) {
        setHospitals([]);
      }
    };
    fetchHospitals();
    // hospitalWisePatient()
    // fetchData_descgarged_Patient_table()
  }, []);

  const fetchHospitalProperties = async () => {
    try {
      const request = await hospital_property_by_hospital(selectedHospitalId);
      if (request?.statusCode === 200) {
        const countrySet = new Set<string>();
        const stateSet = new Set<string>();
        const citySet = new Set<string>();
        const countries: Option[] = [];
        const states: Array<Option & { country: string }> = [];
        const cities: Array<Option & { state: string; country: string }> = [];
        setHospitalProperties(request?.body?.data);
        setFilteredHospitalProperties(request?.body?.data)
        await request?.body?.data.forEach((property: any) => {
          const countryId = property?.country?.id;
          const stateId = property?.state?.id;
          const cityId = property?.city?.id;
          if (countryId && !countrySet.has(countryId)) {
            countries.push({ id: countryId, name: property.country.country });
            countrySet.add(countryId);
          }
          if (stateId && !stateSet.has(stateId)) {
            states.push({
              id: stateId,
              name: property.state.state,
              country: countryId,
            });
            stateSet.add(stateId);
          }
          if (cityId && !citySet.has(cityId)) {
            cities.push({
              id: cityId,
              name: property.city.city,
              state: stateId,
              country: countryId,
            });
            citySet.add(cityId);
          }
        });
        setCountryOptions([{ id: "All", name: "All" }, ...countries]);
        setStateOptions([{ id: "All", name: "All", country: "" }, ...states]);
        setCityOptions([{ id: "All", name: "All", state: "", country: "" }, ...cities]);
      };
    } catch (error) {
      setHospitalProperties([]);
    }
  }

  const patientsCountsByHospital = async () => {
    try {
      if (selectedHospitalId) {
        const response = await getPatientsByHospitalForCounts(selectedHospitalId, selectedCountry, selectedCity, selectedState);
        const patientsAllData = await getPatientsByHospital(selectedHospitalId, selectedCountry, selectedState, selectedCity);
        if (patientsAllData?.statusCode === 200) {
          setAllPatients(patientsAllData?.body?.data)
        } else {
          setAllPatients([])
        }
        if (response?.statusCode === 200) {
          setTotalPatientsCount(response?.body?.total_patients || 0);
          setCurrentlyAdmitedPatientCount(response?.body?.total_admitted || 0);
          setCurrentlyCriticalCount(response?.body?.currently_critical || 0);
          setCurrentlyOnVentilator(response?.body?.currently_on_ventilator || 0);
        } else {
          setTotalPatientsCount(0);
          setCurrentlyAdmitedPatientCount(0);
          setCurrentlyCriticalCount(0);
          setCurrentlyOnVentilator(0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchHospitalProperties();
  }, [selectedHospitalId]);

  useEffect(() => {
    hospitalWisePatient();
    fetchData_descgarged_Patient_table();
    if (user_info?.is_super_admin || user_info?.is_admin) {
      patientsCountsByHospital();
    }
  }, [selectedHospitalId, selectedCity, selectedState]);

  const handleHospitalChange = async (hospitalId: string) => {
    if (hospitalId === selectedHospitalId) {
      setSelectedHospitalPropertyId("");
    } else {
      setSelectedHospitalId(hospitalId);
      if (selectedHospitalPropertyId !== "All") {
        setSelectedHospitalPropertyId("All");
      }
      const hospitalProperties1 = hospitalProperties?.filter((property) => property?.hospital?.id === hospitalId);
      if (hospitalProperties1?.length > 0) {
        setSelectedHospitalPropertyId(hospitalProperties1[0].id);
      }
    }
  };

  useEffect(() => {
    if (selectedHospitalPropertyId && selectedHospitalPropertyId !== "All") {
      const filterDataP = [hospitalWisePatientCount.find((item) => item?.id === selectedHospitalPropertyId)]
      const filterDataP1 = [allDischargeTypes.find((item: any) => item?.id === selectedHospitalPropertyId)]
      setHospitalWisePatientcountfilter(filterDataP)
      setDischargeTypeCount(filterDataP1)
    }
    else {
      setHospitalWisePatientcountfilter(hospitalWisePatientCount)
      setDischargeTypeCount(allDischargeTypes)
    }
  }, [selectedHospitalPropertyId])

  const fetchData_descgarged_Patient_table = async () => {
    try {
      const hospitalId = user_info?.is_super_admin || user_info?.is_admin
        ? selectedHospitalId
        : user_info?.hospital_property?.hospital?.id;
      const response = await hospital_property_with_discharge_count(hospitalId, selectedCountry, selectedState, selectedCity);
      if (response?.statusCode === 200 && Array.isArray(response.body) && response.body.length > 0) {
        let data = response?.body;
        if (!user_info?.is_super_admin && !user_info?.is_admin) {
          data = data.filter((item: any) => item.id === user_info?.hospital_property?.id);
        }
        setallDischargeTypes(data);
        setDischargeTypeCount(data);
      } else {
        setallDischargeTypes([]);
        setDischargeTypeCount([]);
      }
    } catch (error) {
      console.error("Error fetching discharge data:", error);
    }
  };

  useEffect(() => {
    if (selectedCountry === "All") {
      setFilteredStateOptions(stateOptions);
    } else {
      const newFilteredStateOptions = stateOptions.filter(
        (state) => state.country === selectedCountry
      );
      setFilteredStateOptions([
        { id: "All", name: "All" },
        ...newFilteredStateOptions,
      ]);
    }
    setSelectedHospitalPropertyId("All");
    setSelectedState("All");
    setSelectedCity("All");
  }, [selectedCountry, stateOptions]);

  useEffect(() => {
    if (selectedCountry === "All" && selectedState === "All") {
      setFilteredCityOptions(cityOptions);
    } else if (selectedState === "All") {
      const newFilteredCityOptions = cityOptions.filter(
        (city) => city.country === selectedCountry
      );
      setFilteredCityOptions([
        { id: "All", name: "All" },
        ...newFilteredCityOptions,
      ]);
    } else if (selectedCountry === "All" && selectedState !== "All") {
      const newFilteredCityOptions = cityOptions.filter(
        (city) => city.state === selectedState
      );
      setFilteredCityOptions([
        { id: "All", name: "All" },
        ...newFilteredCityOptions,
      ]);

    } else {
      const newFilteredCityOptions = cityOptions.filter(
        (city) => city.state === selectedState && city.country === selectedCountry
      );
      setFilteredCityOptions([
        { id: "All", name: "All" },
        ...newFilteredCityOptions,
      ]);
    }
    setSelectedCity("All");
  }, [selectedCountry, selectedState, cityOptions]);

  useEffect(() => {
    let filtered = hospitalProperties;
    if (selectedCountry !== "All") {
      filtered = filtered.filter(
        (property) => (property?.country?.id === selectedCountry)
      );
    }
    if (selectedState !== "All") {
      filtered = filtered.filter(
        (property) => (property?.state?.id === selectedState)
      );
    }
    if (selectedCity !== "All") {
      filtered = filtered.filter((property) => (property?.city?.id === selectedCity));
    }
    setSelectedHospitalPropertyId("All");
    setFilteredHospitalProperties(filtered);
  }, [selectedCountry, selectedState, selectedCity, hospitalProperties]);

  const hospitalWisePatient = async () => {
    try {
      const hospitalId = user_info?.is_super_admin || user_info?.is_admin
        ? selectedHospitalId
        : user_info?.hospital_property?.hospital?.id;
      const request = await hospital_property_with_patient_count(hospitalId, selectedCountry, selectedState, selectedCity);
      if (request?.statusCode === 200) {
        let data = request?.body;
        if (!user_info?.is_super_admin && !user_info?.is_admin) {
          data = data.filter((item: any) => item.id === user_info?.hospital_property?.id);
        }
        setHospitalWisePatientcountfilter(data);
        SetHospitalWisePatientCount(data);
      } else {
        SetHospitalWisePatientCount([]);
        setHospitalWisePatientcountfilter([]);
      }
    } catch (error) {
      console.error("Error in hospitalWisePatient Data:", error);
    }
  }


  return (
    <>
      <Header HeaderName="Dashboard" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="col-12 col">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className={`row dashboard-dropdown ${user_info?.is_super_admin === false ? "four-drpdown" : ""}`}>
                  {(user_info?.is_super_admin === true && hospitals?.length > 0 && (
                    <div className="col-3">
                      <DropdownField
                        label="Hospital"
                        options={hospitals}
                        value={selectedHospitalId}
                        onChange={(e) => {
                          setSelectedCountry("All");
                          setSelectedState("All");
                          setSelectedCity("All");
                          handleHospitalChange(String(e.target.value));
                        }}
                        name="hospital_id"
                      />
                    </div>
                  ))}
                  {/* {countryOptions?.length > 0 && (
                    <div className="col-3">
                      <DropdownField
                        label="Country"
                        options={countryOptions}
                        value={selectedCountry}
                        onChange={(e: any) => setSelectedCountry(String(e.target.value))}
                        name="country"
                      />
                    </div>
                  )}*/}
                  {filteredStateOptions?.length > 0 && (
                    <div className="col-3">
                      <DropdownField
                        label="State"
                        options={filteredStateOptions}
                        value={selectedState}
                        onChange={(e: any) => setSelectedState(e.target.value)}
                        name="state"
                      />
                    </div>
                  )}
                  {filteredCityOptions?.length > 0 && (
                    <div className="col-3">
                      <DropdownField
                        label="City"
                        options={filteredCityOptions}
                        value={selectedCity}
                        onChange={(e: any) => setSelectedCity(e.target.value)}
                        name="city"
                      />
                    </div>
                  )}
                  <div className="col-3">
                    <DropdownField
                      label="Hospital Properties"
                      options={[{ id: "All", landmark: "All" }, ...filteredHospitalProperties]}
                      value={selectedHospitalPropertyId}
                      onChange={(e) => {
                        const value = String(e.target.value);
                        setSelectedHospitalPropertyId(value);
                        if (value === "All") {
                          patientsCountsByHospital();
                        }
                      }}
                      name="hospital_property_id"
                    /></div>
                </div>
              </div>
            </div>
            <div className="dashboard-con-scroll"
              style={{
                height: "calc(100vh - 152px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="row top-box-row">
                <div className="col col-lg-3">
                  <Card className="top-box-1">
                    <CardBody>
                      <div className="d-flex flex-wrap dashboard-small-box" onClick={() => setShowTotalPatientsModal(true)}>
                        <div className="avatar-sm mr-2">
                          <div className="avatar-title">
                            <i className="bx bxs-group"></i>
                          </div>
                        </div>
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Patients</p>
                          <h5 className="mb-0">{totalPatientsCount}</h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col col-lg-3">
                  <Card className="top-box-2">
                    <CardBody>
                      <div className="d-flex flex-wrap dashboard-small-box" onClick={() => setShowAdmittedPatientsModal(true)}>
                        <div className="avatar-sm mr-2">
                          <div className="avatar-title">
                            <i className="mdi mdi-bed"></i>
                          </div>
                        </div>
                        <div className="me-3">
                          <p className="text-muted mb-2">Currently Admitted</p>
                          <h5 className="mb-0">
                            {currentlyAdmitedPatientCount}
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col col-lg-3">
                  <Card className="top-box-3">
                    <CardBody>
                      <div className="d-flex flex-wrap dashboard-small-box" onClick={() => setShowCriticalPatientsModal(true)}>
                        <div className="avatar-sm mr-2">
                          <div className="avatar-title">
                            <i className="mdi mdi-heart-pulse"></i>
                          </div>
                        </div>
                        <div className="me-3">
                          <p className="text-muted mb-2">Currently Critical</p>
                          <h5 className="mb-0">{currentlyCriticalCount}</h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col col-lg-3">
                  <Card className="top-box-4">
                    <CardBody>
                      <div className="d-flex flex-wrap dashboard-small-box" onClick={() => setShowOnVentilatorModal(true)}>
                        <div className="avatar-sm mr-2">
                          <div className="avatar-title">
                            <i className="mdi mdi-medical-bag"></i>
                          </div>
                        </div>
                        <div className="me-3">
                          <p className="text-muted mb-2">Currently On Ventilator</p>
                          <h5 className="mb-0">{currentlyOnVentilator}</h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Card className="card-common-dashboard overview-box">
                    <CardBody>
                      <div className="bg-transparent border-bottom card-header">
                        <div className="d-flex flex-wrap">
                          <div className="me-2">
                            <h5 className="card-title mt-1 mb-0">Overview</h5>
                          </div>
                          <Nav
                            tabs
                            className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto"
                          >
                            <NavItem className="nav-item">
                              <NavLink
                                className={tab === "today" ? "active" : ""}
                                onClick={() => settodayDate()}
                              >
                                Today
                              </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                              <NavLink
                                className={tab === "month" ? "active" : ""}
                                onClick={() => setmonthDate()}
                              >
                                Month
                              </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                              <NavLink
                                className={tab === "year" ? "active" : ""}
                                onClick={() => setYearDate()}
                              >
                                Year
                              </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                              <NavLink
                                className={tab === "custom" ? "active" : ""}
                                onClick={handleCustomClick}
                              >
                                Custom
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-6">
                            {/* <Apaexlinecolumn dataColors='["--bs-primary", "--bs-success"]' /> */}
                            <Doughnut
                              dataColors='["#000de9","#389a09"]'
                              chartData={[
                                {
                                  value: chartData?.admittedPatientsCount,
                                },
                                {
                                  value: chartData?.dischargePatientsCount,
                                },
                              ]}
                            />
                          </div>
                          {chartData !== null && (
                            <div className="col-lg-6 mt-3">
                              <div className="inner-col-box chart-box-1">
                                <p>{tab === "today" ? "Today Admit" : tab === "month" ? "Month Admit" : tab === "custom" ? "Admit" : "Year Admit"}</p>
                                <h4>
                                  {tab === "today"
                                    ? chartData.admittedPatientsCount !== null
                                      ? chartData.admittedPatientsCount
                                      : "-"
                                    : tab === "month"
                                      ? chartData.admittedPatientsCount
                                      : tab === "custom"
                                        ? chartData.admittedPatientsCount
                                        : chartData.admittedPatientsCount}
                                </h4>
                              </div>
                              <div className="inner-col-box chart-box-2">
                                <p>{tab === "today" ? "Today Discharge" : tab === "month" ? "Month Discharge" : tab === "custom" ? "Discharge" : "Year Discharge"}</p>
                                <h4>
                                  {tab === "today"
                                    ? chartData.dischargePatientsCount !== null
                                      ? chartData.dischargePatientsCount
                                      : "-"
                                    : tab === "month"
                                      ? chartData.dischargePatientsCount
                                      : tab === "custom"
                                        ? chartData.dischargePatientsCount
                                        : chartData.dischargePatientsCount}
                                </h4>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Modal isOpen={isFilterModalOpen} backdrop="static" keyboard={false}  toggle={toggleFilterModal} centered={true}>
                    <ModalHeader toggle={toggleFilterModal}>Date Filter Options</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col md={6} className="mb-3">
                          <Label className="form-label">From Date</Label>
                          <CustomMUIDatePicker
                            name="fromDate"
                            value={fromDateString}
                            setState={setFromDateString}
                            type="simple"
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <Label className="form-label">To Date</Label>
                          <CustomMUIDatePicker
                            name="toDate"
                            value={toDateString}
                            setState={setToDateString}
                            type="simple"
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                      <Button color="info" onClick={applyFilters}>
                        Apply
                      </Button>
                      <Button color="secondary" onClick={resetDateFilters}>
                        Reset
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <HospitalTable hospitalWisePatientCount={hospitalWisePatientcountfilter} handlePatientsListClick={handlePatientsListClick} />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <PatientTable headers={dischargedType} allDischargeTypes={dischargeTypeCount} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataModelComponent
        isOpen={showTotalPatientsModal}
        toggle={() => setShowTotalPatientsModal(false)}
        title="Total Patients"
        showTabs={true}
        data={allPatients}
        columns={[
          { tab: '1', columns: sharedColumns.tab1 },
          { tab: '2', columns: sharedColumns.tab2 },
        ]}
        renderRow={(patient, index, activeTab) => renderRow(patient, index, activeTab, true)}
      />

      <DataModelComponent
        isOpen={showAdmittedPatientsModal}
        toggle={() => setShowAdmittedPatientsModal(false)}
        title="Currently Admitted"
        data={allPatients?.filter((patient) => patient?.admitted)}
        columns={[{ tab: '1', columns: sharedColumns.tab1 }]}
        renderRow={(patient, index, activeTab) => renderRow(patient, index, activeTab, true)}
      />

      <DataModelComponent
        isOpen={showCriticalPatientsModal}
        toggle={() => setShowCriticalPatientsModal(false)}
        title="Currently Critical"
        data={allPatients?.filter((patient) => patient?.is_critical)}
        columns={[
          { tab: '1', columns: sharedColumns.tab1 },
        ]}
        renderRow={(patient, index, activeTab) => renderRow(patient, index, activeTab, true)}
      />

      <DataModelComponent
        isOpen={showOnVentilatorModal}
        toggle={() => setShowOnVentilatorModal(false)}
        title="Currently On Ventilator"
        data={allPatients?.filter((patient) => patient?.is_on_ventilator)}
        columns={[
          { tab: '1', columns: sharedColumns.tab1 },
        ]}
        renderRow={(patient, index, activeTab) => renderRow(patient, index, activeTab, true)}
      />

      <DataModelComponent
        isOpen={hospitalOpenPatientsList}
        toggle={() => setHospitalOpenPatientsList(false)}
        title={selectedHospital ? `Patients List In- ${selectedHospital?.hospital?.name}-${selectedHospital?.landmark}` : 'Patients List'}
        showTabs={true}
        data={allPatients?.filter((patient) => patient?.hospital_property?.id === selectedHospital?.id)}
        renderRow={(patient, index, activeTab) => renderRow(patient, index, activeTab, false)}
        columns={[
          { tab: '1', columns: hospitalSpecificColumns.tab1 },
          { tab: '2', columns: hospitalSpecificColumns.tab2 },
        ]}
      />
    </>
  );
};
export default Dashboard;