import React, { ChangeEvent, useEffect, useState, FC } from "react";
import {
  hospital_access,
  hospital_property_access,
  master_pages_access,
  patient_access,
} from "../../helpers/access_policies";
import { remove_duplicate } from "../../helpers/duplicate";
import { ErrorMessage, Field } from "formik";
import { getRolesList } from "../../redux/actions/roleAction";
import { Label } from "reactstrap";
import { useLocation } from "react-router-dom";

const AccessPolicyForm: React.FC<any> = ({
  access_policy,
  setaccess_policy,
}) => {
  const [all_user_roles, set_all_user_roles] = useState<{ id: string | number; role: string; user_access: string[] }[]>([]);
  const location = useLocation();

  const fetch_user_roles = async () => {
    const { body } = await getRolesList({});
    if (body?.data) {
      const get_list_all_of_roles = body?.data?.map(
        ({
          id,
          role,
          user_access,
        }: {
          id: string;
          role: string;
          user_access: string[];
        }) => ({ role, id, user_access })
      );
      set_all_user_roles(get_list_all_of_roles);
    } else {
      set_all_user_roles([]);
    }
  };

  useEffect(() => {
    if (location.pathname === "/users") {
      fetch_user_roles();
    }
  }, [location.pathname]);

  const handle_user_role_change = (e: ChangeEvent<HTMLSelectElement>) => {
    const find_access_policies = all_user_roles.find(
      ({ id }) => id === e.target.value
    );
    if (!find_access_policies) return;
    const { user_access } = find_access_policies;
    setaccess_policy(user_access);
  };

  const hasAccess = (accessPolicy: string | any[], data: any) => accessPolicy?.includes(data);

  const [selectedRole, setSelectedRole] = useState<string | number>("");


  return (
    <div className="row">
      <div className="col-12">
        {location?.pathname === "/users" && (
          <div className="mb-3">
            <Label className="form-label">User Role</Label>
            <Field
              as="select"
              className="form-control"
              value={selectedRole || ""}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const selectedRoleId = e.target.value;

                setSelectedRole(selectedRoleId);
                const selectedRole = all_user_roles?.find((user) => user.id === selectedRoleId);

                if (selectedRole) {
                  setaccess_policy({
                    selectedRoleId,
                    user_access: selectedRole.user_access,
                  });
                } else {
                  setaccess_policy({ selectedRoleId: "", user_access: [] });
                }
                handle_user_role_change(e);
              }}
            >
              <option value="">Select User Role</option>
              {all_user_roles?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.role}
                </option>
              ))}
            </Field>

          </div>
        )}
      </div>
      <div className="col-12">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="hospitals_page"
            value=""
            // defaultChecked={
            //   !Object.keys(hospital_access).some(
            //     (string) => access_policy.indexOf(string) === -1
            //   )
            //     ? true
            //     : false
            // }
            checked={
              !Object.keys(hospital_access).some(
                (string) => access_policy.indexOf(string) === -1
              )
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setaccess_policy(
                  remove_duplicate(
                    [...access_policy, Object.keys(hospital_access)].flat()
                  )
                );
              } else {
                remove_duplicate(
                  setaccess_policy(
                    access_policy.filter((data: any) => {
                      return Object.keys(hospital_access).indexOf(data) < 0;
                    })
                  )
                );
              }
            }}
          />
          <label
            className="form-check-label text-primary"
            htmlFor="hospitals_page"
          >
            Hospitals
          </label>
        </div>

        <div className="row">
          {Object.keys(hospital_access).map((data: any) => {
            return (
              <div className="col-6" key={data}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // defaultChecked={
                    //   hasAccess(access_policy, data) ? true : false
                    // }
                    id={data}
                    checked={hasAccess(access_policy, data) ? true : false}
                    name={data}
                    onChange={(e) => {
                      if (hasAccess(access_policy, data)) {
                        setaccess_policy(
                          remove_duplicate(
                            access_policy?.filter((d: any) => {
                              return d !== data;
                            })
                          )
                        );
                      } else {
                        setaccess_policy(
                          remove_duplicate([...access_policy, data])
                        );
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={data}
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.replaceAll("_", " ")}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-12">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="hospital_property_page"
            value=""
            // defaultChecked={
            //   !Object.keys(hospital_property_access).some(
            //     (string) => access_policy.indexOf(string) === -1
            //   )
            //     ? true
            //     : false
            // }
            checked={
              !Object.keys(hospital_property_access).some(
                (string) => access_policy.indexOf(string) === -1
              )
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setaccess_policy(
                  remove_duplicate(
                    [
                      ...access_policy,
                      Object.keys(hospital_property_access),
                    ].flat()
                  )
                );
              } else {
                remove_duplicate(
                  setaccess_policy(
                    access_policy.filter((data: any) => {
                      return (
                        Object.keys(hospital_property_access).indexOf(data) < 0
                      );
                    })
                  )
                );
              }
            }}
          />
          <label
            className="form-check-label text-primary"
            htmlFor="hospital_property_page"
          >
            Hospital Property Access
          </label>
        </div>

        <div className="row">
          {Object.keys(hospital_property_access).map((data: any) => {
            return (
              <div className="col-6" key={data}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // defaultChecked={
                    //   hasAccess(access_policy, data) ? true : false
                    // }
                    id={data}
                    checked={hasAccess(access_policy, data) ? true : false}
                    name={data}
                    onChange={(e) => {
                      if (hasAccess(access_policy, data)) {
                        setaccess_policy(
                          remove_duplicate(
                            access_policy?.filter((d: any) => {
                              return d !== data;
                            })
                          )
                        );
                      } else {
                        setaccess_policy(
                          remove_duplicate([...access_policy, data])
                        );
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={data}
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.replaceAll("_", " ")}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-12">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="patients_page"
            value=""
            // defaultChecked={
            //   !Object.keys(patient_access).some(
            //     (string) => access_policy.indexOf(string) === -1
            //   )
            //     ? true
            //     : false
            // }
            checked={
              !Object.keys(patient_access).some(
                (string) => access_policy.indexOf(string) === -1
              )
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setaccess_policy(
                  remove_duplicate(
                    [...access_policy, Object.keys(patient_access)].flat()
                  )
                );
              } else {
                remove_duplicate(
                  setaccess_policy(
                    access_policy.filter((data: any) => {
                      return Object.keys(patient_access).indexOf(data) < 0;
                    })
                  )
                );
              }
            }}
          />
          <label
            className="form-check-label text-primary"
            htmlFor="patients_page"
          >
            Patients
          </label>
        </div>

        <div className="row">
          {Object.keys(patient_access).map((data: any) => {
            return (
              <div className="col-6" key={data}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // defaultChecked={
                    //   hasAccess(access_policy, data) ? true : false
                    // }
                    id={data}
                    checked={hasAccess(access_policy, data) ? true : false}
                    name={data}
                    onChange={(e) => {
                      if (hasAccess(access_policy, data)) {
                        setaccess_policy(
                          remove_duplicate(
                            access_policy?.filter((d: any) => {
                              return d !== data;
                            })
                          )
                        );
                      } else {
                        setaccess_policy(
                          remove_duplicate([...access_policy, data])
                        );
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={data}
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.replaceAll("_", " ")}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-12">
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="master_page"
            value=""
            // defaultChecked={
            //   !Object.keys(master_pages_access).some(
            //     (string) => access_policy.indexOf(string) === -1
            //   )
            //     ? true
            //     : false
            // }
            checked={
              !Object.keys(master_pages_access).some(
                (string) => access_policy.indexOf(string) === -1
              )
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setaccess_policy(
                  remove_duplicate(
                    [...access_policy, Object.keys(master_pages_access)].flat()
                  )
                );
              } else {
                remove_duplicate(
                  setaccess_policy(
                    access_policy.filter((data: any) => {
                      return Object.keys(master_pages_access).indexOf(data) < 0;
                    })
                  )
                );
              }
            }}
          />
          <label
            className="form-check-label text-primary"
            htmlFor="master_page"
          >
            Masters
          </label>
        </div>

        <div className="row">
          {Object.keys(master_pages_access).map((data: any) => {
            return (
              <div className="col-6" key={data}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // defaultChecked={
                    //   hasAccess(access_policy, data) ? true : false
                    // }
                    id={data}
                    checked={hasAccess(access_policy, data) ? true : false}
                    name={data}
                    onChange={(e) => {
                      if (hasAccess(access_policy, data)) {
                        setaccess_policy(
                          remove_duplicate(
                            access_policy?.filter((d: any) => {
                              return d !== data;
                            })
                          )
                        );
                      } else {
                        setaccess_policy(
                          remove_duplicate([...access_policy, data])
                        );
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={data}
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.replaceAll("_", " ")}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ErrorMessage
        name="user_access"
        component="div"
        className="text-danger mb-3"
      />
    </div>
  );
};

export default AccessPolicyForm;
