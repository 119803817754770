import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import {postStoreToken } from "../redux/actions/firebaseNotification";

const firebaseConfig = {
  apiKey: "AIzaSyCfUqn1bme7z8aITrdUsEDpXxtdHJTOK9Y",
  authDomain: "entelteleicu-d3eb0.firebaseapp.com",
  projectId: "entelteleicu-d3eb0",
  storageBucket: "entelteleicu-d3eb0.firebasestorage.app",
  messagingSenderId: "235222009660",
  appId: "1:235222009660:web:edca382fade37110ad4039",
  measurementId: "G-RB60ZV73YB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// 🔹 Register service worker if not registered
export const getOrRegisterServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const existingServiceWorker = await navigator.serviceWorker.getRegistration("/firebase-push-notification-scope");
    if (existingServiceWorker) {
      return existingServiceWorker;
    }
    // 🔸 Register the service worker if not found
    return navigator.serviceWorker.register("/firebase-messaging-sw.js", {
      scope: "/firebase-push-notification-scope",
    });
  }
  throw new Error("Service workers are not supported in this browser.");
};

// 🔹 Get or refresh FCM token
export const getFirebaseToken = async (userInfo: string) => {
  try {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();

    const newToken = await getToken(messaging, {
      vapidKey: "BDwIiKf5iVJn0aFiZ0ZCTXiSLxkqNs4ytkCloYmTy6vhDP3YLT5Ls3d_idK3ItGfQC8pCe7PQTuG6lFvzkH5zG8",
      serviceWorkerRegistration,
    });

    if (newToken) {
         await postStoreToken({ userId: userInfo, fcmToken: newToken });
    }
    return newToken;
  } catch (error) {
    console.error("Error retrieving FCM token:", error);
    return null;
  }
};

export const onTokenRefresh = (userInfo: string) => {
  navigator.serviceWorker.ready.then((registration) => {
    return registration.pushManager.getSubscription();
  }).then((subscription) => {
    if (subscription) {
      getFirebaseToken(userInfo);
    }
  }).catch((error) => {
    console.error("Error during token refresh:", error);
  });
};
// 🔹 Listen for foreground push notifications and refresh token if needed
export const onForegroundMessage = (userInfo: string) =>
  new Promise((resolve) => onMessage(messaging, async (payload) => {
    const token = await getFirebaseToken(userInfo);
    resolve({ token, payload });
  }));
