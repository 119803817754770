// import React from "react";
import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import States from "../../../components/LocationComponents/state/States";
import Countries from "../../../components/LocationComponents/country/Countries";
import Cities from "../../../components/LocationComponents/city/Cities";
import Header from "../../../components/Header/Header";

const Location = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState("2");

  // Toggle active state for Tab
  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <>
      <Header HeaderName="Master Pages" />
      {/* <Sidebar /> */}
      {/* <div className="main-content" style={{ padding: 10 }}>
        <div className="page-content">
          <div className="container-fluid"> */}
      <div className="row">
        <div className="col-12 col">
          <div className="page-title-box master-title">
            <h4 className="mb-sm-0 font-size-18">
              {currentActiveTab === "1"
                ? "Countries"
                : currentActiveTab === "2"
                  ? "States"
                  : currentActiveTab === "3"
                    ? "Cities"
                    : ""}
            </h4>
            <div className="page-title-right d-flex location-tabs">
              <Nav tabs className="nav-tabs-custom list-view-tab nav-justified mr-2">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={currentActiveTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Country</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={currentActiveTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block"> State</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={currentActiveTab === "3" ? "active" : ""}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block"> City</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
        <div className="col-12">
          <TabContent activeTab={currentActiveTab} className="text-muted setting-tab-content">
            {currentActiveTab === "1" && <TabPane tabId="1">
              <Countries isActiveTab={currentActiveTab === "1"} />
            </TabPane>}
            {currentActiveTab === "2" && <TabPane tabId="2">
              <States isActiveTab={currentActiveTab === "2"} />
            </TabPane>}
            {currentActiveTab === "3" && <TabPane tabId="3">
              <Cities isActiveTab={currentActiveTab === "3"} />
            </TabPane>}
          </TabContent>
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default Location;
