import React, { useState, useEffect, useCallback } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  save_token,
  update_user_web_token,
} from "../../redux/actions/userAction";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import "../../assets/scss/toggle_switch.css";
import { getNotificationsList } from "../../redux/actions/notificationsAction";
import { ISingleNotification } from "../../redux/types/notificationsTypes";
import { formatDateToCustomDateString, formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import { getEndOfDayISO, getStartOfDayISO } from "../../pages/patients/patientDetailCards/PatientDetailDashboard";
import { useNavigate } from "react-router-dom";
import maleAvatar from "../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../assets/images/users/female_avatar.png";
import { HospitalProperty } from "../../redux/types/patientAdmissionInstructionsTypes";
import { useNotifications } from "../Notification/notificationContainer";


export interface NotificationProps {
  id: string,
  notification_type: string,
  notification_text: string,
  is_deleted: boolean,
  created_on: string,
  modified_on: string,
  is_read: boolean,
  created_by: string,
  modified_by: string | null,
  patient_admission: string | null,
  hospital_property: HospitalProperty
}

const SideHeader = () => {
  const navigate = useNavigate();
  const { notifications, fetchNotifications, totalCount, unReadCount } = useNotifications();
  const [menu, setMenu] = useState(false);
  const [notify_menu, setnotify_menu] = useState(false);
  const dispatch: any = useDispatch();
  const [token_check, settoken_check] = useState(false);
  const [current_browser_token, setcurrent_browser_token] = useState("");
  const { user_info, token } = useSelector((state: any) => state.user);
  // State for theme
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [selected_date, setSelectedDate] = useState<string>(getStartOfDayISO(new Date()));


  // const remove_token = async () => {
  //   let web_tokens_data = user_info?.web_token?.filter((d: any) => {
  //     return d !== current_browser_token;
  //   });
  //   let data = await update_user_web_token({ web_token: web_tokens_data });
  //   dispatch(save_token(data?.body?.updated_user, token));

  //   SuccessToast("Notifications disabled successfully");
  //   update_token_check();
  // };


  // const add_token = async () => {
  //   let web_tokens_data = user_info?.web_token;
  //   getFirebaseToken()
  //     .then(async (firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       let upload_data = [...web_tokens_data, firebaseToken.toString()];
  //       let data = await update_user_web_token({ web_token: upload_data });
  //       dispatch(save_token(data?.body?.updated_user, token));

  //       SuccessToast("Notifications enabled successfully");
  //       update_token_check();
  //       if (!firebaseToken) {
  //         ErrorToast("Please enable notification in browser");
  //       }
  //     })
  //     .catch((err) => {
  //       ErrorToast("Please enable notification in browser");
  //     });
  // };
  // const fetchNotifications = async () => {
  //   const params = user_info?.is_super_admin === false ? { hospital_property_id: user_info?.hospital_property?.id } : {}
  //   const { body } = await getNotificationsList(params);
  //   if (body?.data) {
  //     const latestNotifications = body?.data?.slice(0, 10);
  //     setNotifications(latestNotifications);
  //   }
  // };

  // useEffect(() => {
  //   fetchNotifications();
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       setcurrent_browser_token(firebaseToken);
  //       // if (!firebaseToken) {
  //       //   ErrorToast("Please enable notification in browser");
  //       // }
  //     })
  //     .catch((err) => {
  //       // ErrorToast("Please enable notification in browser");
  //     });
  // }, []);

  // let update_token_check = useCallback(() => {
  //   let web_tokens =
  //     user_info?.web_token === undefined || null ? [] : user_info?.web_token;
  //   let token_check = web_tokens.includes(current_browser_token?.toString());
  //   settoken_check(token_check);
  // }, [current_browser_token, user_info?.web_token]);

  // useEffect(() => {
  //   update_token_check();
  // }, [update_token_check]);

  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.body.setAttribute("data-layout-mode", newTheme);
  }

  useEffect(() => {
    document.body.classList.remove("light-theme", "dark-theme");
    document.body.classList.add(`${theme}-theme`);
    document.body.setAttribute("data-layout-mode", theme);
  }, [theme]);

  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
  };

  const getNotificationString = (notification: any) => {
    const { date: createdDate, time: createdTime } = formatDateTime(notification.created_on);
    let message;

    if (notification.notification_type === 'Update_Clinical_Note') {
      const { note, doctor_refer_note, notification_date } = notification.clinical_note || {};
      const { date: noteDate, time: noteTime } = formatDateTime(notification_date);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had clinical notes updated: {stripHtmlTags(note)} on <b>{noteDate}</b> at <b>{noteTime}</b>.
          {doctor_refer_note && (
            <span> After that, the refer doctor modified the doctor refer note: {stripHtmlTags(doctor_refer_note)}.</span>
          )}
        </p>
      );
    } else if (notification.notification_type === 'Created_Clinical_Notes') {
      const { note, notification_date } = notification.clinical_note || {};
      const { date: noteDate, time: noteTime } = formatDateTime(notification_date);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had clinical notes: {stripHtmlTags(note)} on <b>{noteDate}</b> at <b>{noteTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Prescriptions') {
      const { notification_text, priscription_note } = notification;
      const contentName = priscription_note?.medicine_content?.content_name || "Unknown medicine";

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had a prescription: {notification_text} for {contentName} on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Update_Instructions') {
      const { date: instructionDate, time: instructionTime } = formatDateTime(notification.instruction?.notification_date);
      const instructionDescription = stripHtmlTags(notification.instruction?.instruction_description);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had an instruction update: {instructionDescription} on <b>{instructionDate}</b> at <b>{instructionTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Create_Instructions') {
      const { date: instructionDate, time: instructionTime } = formatDateTime(notification.instruction?.notification_date);
      const instructionDescription = stripHtmlTags(notification.instruction?.instruction_description);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has received an instruction: {instructionDescription} on <b>{instructionDate}</b> at <b>{instructionTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Update_Critical') {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had a patient admission details: the condition is critical, updated on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Create_Critical') {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name}  {notification?.patient_admission?.patient?.last_name}</b> has had a patient admission with details: the condition is critical as of <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name}  {notification?.patient_admission?.patient?.last_name}</b> has had a {notification?.notification_text?.toLowerCase()} on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    }

    return (
      <li key={notification.id}>
        <div className="d-sm-flex align-items-center justify-content-between">
          {message}
        </div>
      </li>
    );
  };

  return (
    <div className="d-flex padding-top">
      <div className="dropdown d-none d-lg-inline-block ms-1">
        {/* <button
          type="button"
          className="btn header-item noti-icon "
          data-toggle="fullscreen"
        >
          <i className="bx bx-fullscreen" />
        </button> */}
        <div
          className="form-check form-switch d-lg-inline-block"
          style={{ marginTop: 7 }}
        >
          {/*  <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizesm"
            checked={token_check}
            onChange={(e) => {
              if (token_check === false) {
                add_token();
              } else {
                remove_token();
              }
            }}
          /> */}
          <label className="form-check-label"></label>
        </div>
      </div>
      <div className="theme-toggle-container">
        <div
          className="form-check form-switch d-inline-block position-relative"
          style={{
            marginTop: "4px",
            width: "41px",
            height: "27px",
            backgroundColor: theme === "dark" ? "#212529" : "#f8f9fa",
            borderRadius: "50px",
            padding: "3px",
            position: "relative",
            transition: "background-color 0.1s, border-color 0.3s",
            border: `2px solid ${theme === "dark" ? "#ffc107" : "#6c757d"}`, // Dynamic border color
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizesm"
            checked={theme === "dark"}
            onChange={toggleTheme}
            style={{
              opacity: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              cursor: "pointer",
              zIndex: 2,
            }}
          />
          <label
            className="form-check-label d-flex justify-content-between align-items-center"
            htmlFor="customSwitchsizesm"
            style={{
              width: "100%",
              height: "100%",
              padding: "0 7px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            {theme === "light" ? (
              <i
                className={`bx ${theme === "light" ? "bx-moon" : "bx-sun"}`}
                style={{
                  fontSize: "18px",
                  color: theme === "light" ? "#6c757d" : "#ffc107",
                  transition: "color 0.1s",
                }}
              />

            ) :
              (<i
                className="bx bx-sun"
                style={{
                  fontSize: "18px",
                  color: theme === "light" ? "#ffc107" : "#6c757d",
                  transition: "color 0.1s",
                }}
              />)}
          </label>
        </div>
      </div>
      <Dropdown
        isOpen={notify_menu}
        toggle={() => setnotify_menu(!notify_menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {unReadCount > 0 && (
            <span className="bellcount translate-middle badge rounded-pill bg-danger">
              {unReadCount}
            </span>
          )}
          <i className="bx bx-bell bx-tada" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end notification-menu">
          <div className="p-3">
            <div className="align-items-center row">
              <div className="col">
                <h6 className="m-0"> Notifications </h6>
              </div>
              <div className="col-auto">
                <a
                  href="#"
                  className="small"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/notifications");
                  }}
                >
                  View All
                </a>
              </div>
            </div>
          </div>
          <div>
            <ul className="p-3">
              {notifications && notifications.length > 0 ? (
                notifications.filter(notification => !notification.is_read).length > 0 ? (
                  notifications.filter(notification => !notification.is_read).map((notification: NotificationProps, index: number) =>
                    getNotificationString(notification)
                  )
                ) : (
                  <li className="text-muted">
                    <p className="m-0">No Unread Notifications.</p>
                  </li>
                )
              ) : (
                <li className="text-muted">
                  <p className="m-0">No Notifications Received.</p>
                </li>
              )}
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user_info?.gender === "female" ? femaleAvatar : maleAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user_info?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="button" onClick={() => navigate("/profile")}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </DropdownItem>

          <DropdownItem tag="button" onClick={(e) => e.preventDefault()}>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            Settings
          </DropdownItem>

          <DropdownItem tag="a" onClick={toggleTheme}>
            {theme === "light" ? <i className="bx bx-moon font-size-18 align-middle me-1" /> : <i className="bx bx-sun font-size-18 align-middle me-1" />}
            {theme === "light" ? "Dark Theme" : "Light Theme"}
          </DropdownItem>

          <div className="dropdown-divider" />
          <button
            className="dropdown-item"
            onClick={() => dispatch(logoutUser())}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </button>
        </DropdownMenu>
      </Dropdown>
      {/* <div className="dropdown d-inline-block">
        <button
          type="button"
          className="btn header-item noti-icon right-bar-toggle "
        >
          <i className="bx bx-cog bx-spin" />
        </button>
      </div> */}
    </div>
  );
};

export default SideHeader;