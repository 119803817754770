
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import React, { useEffect, useState } from "react";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import {Card, CardBody, CardTitle, Label,} from "reactstrap";
import { getLineListingReports } from "../../redux/actions/reports";
import { ErrorToast } from "../../utils/toastNotifications";
import { all_hospitals_without_pagination } from "../../redux/actions/hospitalAction";
import { hospital_property_by_hospital } from "../../redux/actions/hospitalPropertyAction";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { useSelector } from "react-redux"
import DashedLinenew from "../AllCharts/apex/dashedLinenew";
import { IAgeRangeProps } from "../../redux/types/reportsTypes";

const AgeRange = ({ subTab, subTabtoggle }: IAgeRangeProps) => {
  const initialFilters = {
    start_date: "",
    end_date: "",
    property_id: "",
    sort_order: "",
    selectedYear: "",
    subTab: "2",
  };
  const [filters, setFilters] = useState(initialFilters); 
  useEffect(() => {
    subTabtoggle("2"); 
}, [subTabtoggle]);
const { user_info } = useSelector((state: any) => state.user);
const [lineListingArray, setLineListingArray] = useState([]);
const [lineListingArray_new, setLineListingArray_new] = useState<number[]>([]);
const [lineListingArrayInitial, setLineListingArrayInitial] = useState<number[]>([]);
const [all_hospitals, set_all_hospitals] = useState([]);
const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
const [selectedHospital, setSelectedHospital] = useState("");
const [ageDistribution, setAgeDistribution] = useState<number[]>([]);

const fetchLineListingReportsData = async () => {
    const { body } = await getLineListingReports({});
    if (body?.data) {
        setLineListingArray(body?.data);
        const lineListingArrayNew = body.data.map((item: { birth_date: any }) => item.birth_date) || [];
        setLineListingArray_new(lineListingArrayNew);
        const distribution = calculateAgeDistribution(lineListingArrayNew);
        setAgeDistribution(distribution); 
        setLineListingArray_new(distribution); 
        const distribution_temp = calculateAgeDistribution_outside(lineListingArrayNew);
        setLineListingArrayInitial(distribution_temp)
    } else {
        setLineListingArray([]);
        setLineListingArrayInitial([]); 
    }
};

const handleReset = () => {
    setAgeDistribution(lineListingArrayInitial);
    setSelectedHospital("");
    setFilters(initialFilters);
};

const calculateAgeDistribution_outside = (birthDates: any[]) => {
    const currentDate = new Date();
    const ageCounts = [0, 0, 0, 0, 0, 0]; 
    birthDates.forEach(birthDate => {
        const birthYear = new Date(birthDate).getFullYear();
        const age = currentDate.getFullYear() - birthYear;
        if (age < 10) {
            ageCounts[0]++; 
        } else if (age < 20) {
            ageCounts[1]++; 
        } else if (age < 30) {
            ageCounts[2]++; 
        } else if (age < 40) {
            ageCounts[3]++; 
        } else if (age < 50) {
            ageCounts[4]++; 
        } else {
            ageCounts[5]++; 
        }
    });
    return ageCounts; 
};


    const handleSearchDateFeature = async () => {
        const { start_date, end_date } = filters;

        if ((start_date && !end_date) || (!start_date && end_date)) {
            ErrorToast(ERROR_MESSAGES.missingDates);
            return;
        }

        if (start_date && end_date) {
            if (!start_date.trim() || !end_date.trim()) {
                ErrorToast(ERROR_MESSAGES.emptyDates);
                return;
            }

            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

        if (endDateObj < startDateObj) {
            ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
            return;
        }
    }
    const updatedFilters: any = {};
    Object.entries(filters).forEach(([key, value]) => {
        if (value !== "") {
            updatedFilters[key] = value;
        }
    });
    if (
        selectedHospital !== "" &&
        filters.property_id === "" &&
        all_hospitals_properties.length > 0
    ) {
        ErrorToast(ERROR_MESSAGES.noHospitalProperty);
        return;
    }
    if (
        user_info?.is_super_admin === false &&
        filters.property_id !== "" &&
        (!start_date.trim() || !end_date.trim())
    ) {
        ErrorToast(ERROR_MESSAGES.noFiltersSelected);
        return;
    }
    if (Object.entries(updatedFilters).length === 0) {
        ErrorToast(ERROR_MESSAGES.noFiltersSelected);
        return;
    }
    const request = await getLineListingReports(updatedFilters);
    if (request?.statusCode === 200) {
        setLineListingArray(request?.body?.data);
        
        const lineListingArrayNew = request.body.data.map((item: { birth_date: any }) => item.birth_date) || [];
        setLineListingArray_new(lineListingArrayNew);

        const distribution = calculateAgeDistribution(lineListingArrayNew);
        setAgeDistribution(distribution); 
    } else {
        setLineListingArray([]);
        setLineListingArray_new([]);
        setAgeDistribution([]);
    }
};

    const calculateAgeDistribution = (birthDates: any[]) => {
        const currentDate = new Date();
        const ageCounts = [0, 0, 0, 0, 0, 0];
        birthDates.forEach(birthDate => {
            const birthYear = new Date(birthDate).getFullYear();
            const age = currentDate.getFullYear() - birthYear;
            if (age < 10) {
                ageCounts[0]++;
            } else if (age < 20) {
                ageCounts[1]++;
            } else if (age < 30) {
                ageCounts[2]++;
            } else if (age < 40) {
                ageCounts[3]++;
            } else if (age < 50) {
                ageCounts[4]++;
            } else {
                ageCounts[5]++;
            }
        });
        return ageCounts;
    };

    const handleFilterChange = (name: string, value?: string) => {
        setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
    };

    const fetch_hospitals = async () => {
        try {
            const request = await all_hospitals_without_pagination();
            if (request?.statusCode === 200) {
                set_all_hospitals(request?.body?.data);
            } else {
                set_all_hospitals([]);
            }
        } catch (error) {
            console.error("Error fetch_hospitals:", error);
            set_all_hospitals([]);
        }
    };

    const fetchHospitalProperties = async (selectedHospital: string) => {
        try {
            if (selectedHospital.trim() !== "") {
                const request = await hospital_property_by_hospital(selectedHospital);
                if (request?.statusCode === 200) {
                    set_all_hospitals_properties(request?.body?.data);
                    setFilters({ ...filters, property_id: request?.body?.data[0]?.id });
                } else {
                    set_all_hospitals_properties([]);
                    setFilters({ ...filters, property_id: "" });
                }
            }
        } catch (error) {
            console.error("Error : fetchHospitalProperties", error);
        }
    };

    useEffect(() => {
        fetchHospitalProperties(selectedHospital);
    }, [selectedHospital]);

    useEffect(() => {
        fetchLineListingReportsData();
        fetch_hospitals();
    }, []);

    useEffect(() => {
        if (user_info?.is_super_admin === false) {
            setFilters({ ...filters, property_id: user_info?.hospital_property?.id });
        }
    }, [user_info]);


    return (
        <div className="row">
            <div className="col-12 col">
                <div className="page-title-box age-range-top-box align-items-center justify-content-between">
                    <div className="line">
                        {/* <div className="row"> */}
                            {/* <div className="col-2 mb-3">
                                <Label className="form-label mr-5"> Start Date </Label> */}
                                <CustomMUIDatePicker
                                    name="start_date"
                                    type="formik"
                                    value={filters?.start_date}
                                    setState={handleFilterChange} placeholder="Start Date" className="form-control"
                                />
                            {/* </div> */}
                            {/* <div className="col-2 mb-3">
                                <Label className="form-label mr-5"> End Date </Label> */}
                                <CustomMUIDatePicker
                                    name="end_date"
                                    type="formik"
                                    value={filters?.end_date}
                                    setState={handleFilterChange} placeholder="End Date" className="form-control"
                                />
                            {/* </div> */}

              {(user_info?.is_super_admin || user_info?.is_admin) && (
                <>
                    {/* <div className="col-3 mb-1">
                        <Label className="form-label mr-2 d-flex"> Select Hospital </Label>{" "} */}
                        <select
                            style={{ width: "100% !important" }}
                            className="form-control"
                            name="hospitals"
                            value={selectedHospital}
                            onChange={(e) => setSelectedHospital(e.target.value)}
                        >
                            <option hidden>Select Hospital</option>
                            {all_hospitals && all_hospitals.length > 0 ? (
                                all_hospitals.map(({ id, name }, index) => (
                                    <option key={index} style={{ color: "#000" }} value={id}>
                                        {name}
                                    </option>
                                ))
                            ) : (
                                <option
                                    style={{ color: "#000" }}
                                    value={undefined}
                                    disabled
                                >
                                    No Hospitals found
                                </option>
                            )}
                        </select>
                    {/* </div>
                    <div className="col-3 mb-1">
                        <Label className="form-label mr-2 d-flex"> Select Hospital Property</Label>{" "} */}
                        <select
                            value={filters?.property_id}
                            className="form-control"
                            name="property_id"
                            style={{ width: "100% !important" }}
                            onChange={(e) => handleFilterChange("property_id", e.target.value)}
                        >
                            <option hidden>Select Hospital Proeprty</option>
                            {all_hospitals_properties &&
                                all_hospitals_properties.length > 0 ? (
                                all_hospitals_properties.map(({ id, landmark }, index) => (
                                    <option key={index} style={{ color: "#000" }} value={id}>
                                        {landmark}
                                    </option>
                                ))
                            ) : (
                                <option
                                    style={{ color: "#000" }}
                                    disabled
                                    value={undefined}
                                >
                                    No Hospital Properties found
                                </option>
                            )}
                        </select>
                    {/* </div> */}
                </>
            )}
            {/* <div className="col-2 mb-2 combo-btn-box"> */}
                <button
                    className="btn btn-primary mr-2"
                    onClick={() => handleSearchDateFeature()}
                >
                    Search
                </button>
                <button
                    className="btn btn-secondary"                    
                    onClick={handleReset}
                >
                    Reset
                </button>
            {/* </div> */}
              {/* <div className="col-3 mb-3" style={{position: 'relative', right: '-1000px'}}>                */}
                <div className="page-title-right age-tange-rignt-box d-flex">
                  <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                    <NavItem>
                      <NavLink
                        className={subTab === "1" ? "active" : ""}
                        onClick={() => {
                          subTabtoggle("1");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="mdi mdi-chart-bar"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={subTab === "2" ? "active" : ""}
                        onClick={() => {
                          subTabtoggle("2");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="mdi mdi-format-list-bulleted"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="col-12 col scroll-bar">
        <TabContent activeTab={subTab} className="text-muted">
          <TabPane tabId="1">
            <Card>
              <CardBody>
                <CardTitle className="h4 hospital-card-title"></CardTitle>
                <DashedLinenew dataColors='["--bs-success"]' ageDistribution={ageDistribution} />
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card>
              <CardBody>
                <CardTitle className="h4 hospital-card-title"></CardTitle>
                <div className="col-12">
                <Card>
                    <CardTitle></CardTitle>
                    <CardBody>
                        <div className="report-text-right">                           
                            
                        </div>
                        <div className="table-responsive fixed-table-head">                            
                            <table className="table">
                                <thead className="table-light">
                                    <tr>
                                        <th>Age Range</th>
                                        <th>Patient Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ageDistribution && ageDistribution.length > 0 ? (
                                        <>
                                            <tr>
                                                <td>0-10</td>
                                                <td>{ageDistribution[0]}</td>
                                            </tr>
                                            <tr>
                                                <td>11-20</td>
                                                <td>{ageDistribution[1]}</td>
                                            </tr>
                                            <tr>
                                                <td>21-30</td>
                                                <td>{ageDistribution[2]}</td>
                                            </tr>
                                            <tr>
                                                <td>31-40</td>
                                                <td>{ageDistribution[3]}</td>
                                            </tr>
                                            <tr>
                                                <td>41-50</td>
                                                <td>{ageDistribution[4]}</td>
                                            </tr>
                                            <tr>
                                                <td>50+</td>
                                                <td>{ageDistribution[5]}</td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                No Rows Available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </div>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default AgeRange;