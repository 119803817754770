
import axiosApi from "../../api/apiHelper";
import { firebase_notification, getFirebaseToken1, store_notification } from "../api_path";
import { IAddFireBaseNotification, IStoreFcmToken } from "../types/firebaseNotification";


export const postFireBaseNotification = async (postData: IAddFireBaseNotification) => {
    try {
        const { data } = await axiosApi.post(firebase_notification.post_firebase_notification, postData);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}
export const postStoreToken = async ({ userId, fcmToken }: IStoreFcmToken) => {
    try {
        const { data } = await axiosApi.post(store_notification.post_store_notification, { userId, fcmToken });
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}



export const getFCMToken = async (userId: string) => {
    try {
        const { data } = await axiosApi.get(`${getFirebaseToken1.get_firebase_token}/${userId}`);
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
}
