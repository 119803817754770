import React, { useState, useEffect, useCallback } from "react";
import logo from "../../assets/images/logo-icu-sm.png";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/hospitalTabsTypes";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import user1 from "../../assets/images/users/avatar-1.jpg";
import SideHeader from "./SideHeader";

const HospitalHeader = () => {
  const { user_info } = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );
  const dispatch: any = useDispatch();
  const { HospitalTabs, currentActiveTab } = useSelector(
    (state: any) => state.hospitalTab
  );
  const change_header_state = () => {
    setheader_state(!header_state);
    localStorage.setItem("header", JSON.stringify(!header_state));
  };
  const toggle = useCallback(
    (tab: string) => {
      if (currentActiveTab !== tab) {
        if (location?.pathname !== "/hospitals") {
          navigate("/hospitals");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_ACTIVE_TAB,
          payload: {
            id: tab,
          },
        });
      }
    },
    [currentActiveTab, dispatch, location?.pathname, navigate]
  );

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  const remove_hospital_tab = useCallback(
    (tab: any) => {
      dispatch({
        type: ActionTypes.REMOVE_HOSPITAL_TAB,
        payload: tab,
      });
      if (tab?.id === currentActiveTab) {
        toggle("1");
      }
    },
    [toggle, dispatch, currentActiveTab]
  );

  return (
    // <header className="topbar" id="page-topbar">
    // <div className="navbar-header">
    <>
      {/* <div className="d-flex"> */}
      {/* <div className="navbar-brand-box d-lg-none d-md-block">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
          </Link>
        </div> 
        <button
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item "
          id="vertical-menu-btn"
          onClick={change_header_state}
        >
          <i className="fa fa-fw fa-bars" />
        </button> */}
      {(user_info?.is_super_admin && (
        <Nav tabs className="nav-tabs-custom create-btn-box">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={currentActiveTab === "2" ? "active" : ""}
              onClick={() => {
                toggle("2");
                dispatch({
                  type: ActionTypes.SET_HOSPITAL_TAB,
                  payload: undefined,
                });
              }}
            >
              <span className="d-none d-sm-block hospital-name">
                <i className="mdi mdi-hospital-building"></i>
                {" "}
                Create
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      ))}
      {/* <Nav tabs className="nav-tabs-custom list-view-tab nav-justified mr-2">
      {HospitalTabs?.length > 0 &&
          HospitalTabs?.map((tab: any) => {
            return (
              // <div key={tab?.id} className={HospitalTabs?.length > 5 ? "max-tab-con" : ""}>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    className={currentActiveTab === tab?.id ? "active" : ""}
                  >
                    <span
                      className="d-none d-sm-block hospital-name"
                      style={{
                        color: currentActiveTab === tab?.id ? "#3D79F7" : "#fff",
                      }}
                      onClick={() => {
                        // setcurrent_hospital_tab(tab);
                        dispatch({
                          type: ActionTypes.SET_HOSPITAL_TAB,
                          payload: tab,
                        });
                        toggle(tab?.id);
                      }}
                    >
                      {tab.name}{" "}
                    </span>{" "}
                    <i
                      style={{
                        color: currentActiveTab === tab?.id ? "#3D79F7" : "#fff",
                      }}
                      className="mdi mdi-close"
                      onClick={() => remove_hospital_tab(tab)}
                    ></i>
                  </NavLink>
                </NavItem>
              // </div>
            );
          })}
      </Nav> */}
      {/* </div><div className="d-flex"></div> */}
    </>

    // <SideHeader />
    // </div>
    // </header>
  );
};

export default HospitalHeader;
