import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import SvgFilesIcon from "../SvgFiles/SvgFiles";
 
interface DataModelComponentProps<T> {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  data: T[];
  renderRow: (item: T, index: number, activeTab: string) => JSX.Element | null;
  showTabs?: boolean;
  columns?: { tab: string; columns: string[] }[];
}
 
const DataModelComponent: React.FC<DataModelComponentProps<any>> = ({
  isOpen,
  toggle,
  title,
  data,
  columns,
  renderRow,
  showTabs = false,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Show 10 rows by default
 
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setPage(0); // Reset pagination on tab change
    }
  };
 
  const filteredData = data?.filter((item) =>
    activeTab === "1" ? !item?.discharged : item?.discharged
  );
 
  const getColumns = () => {
    return columns?.find((column) => column?.tab === activeTab)?.columns || [];
  };
 
  // Pagination Handlers
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const paginatedData = filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
 
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
      style={{ width: "70%", maxWidth: "70%" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
 
      {showTabs && (
        <Nav tabs className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto" style={{ marginLeft: "10px" }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
            >
              Admitted Patient
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
            >
              Discharge Patient
            </NavLink>
          </NavItem>
        </Nav>
      )}
 
      <ModalBody className="dashboard-model">
        {filteredData.length > 0 ? (
          <>
            <TableContainer component={Paper} className="table-container" sx={{ maxHeight: 400 }}>
              <Table stickyHeader className="table table-hover">
                <TableHead>
                  <TableRow >
                    {getColumns().map((column, index) => (
                      <TableCell key={index}  /* sx={{ fontWeight: "bold", backgroundColor: "#f8f9fa" }} */>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((item, index) => renderRow(item, index, activeTab))}
                </TableBody>
              </Table>
            </TableContainer>
 
            {/* MUI TablePagination */}
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              labelRowsPerPage="Rows per page:"
              className="table-pagination"
            />
          </>
        ) : (
          <div className="no-data-found">
            <SvgFilesIcon iconname={"nodatafound"} />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
 
export default DataModelComponent;