import { FC } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import logo1 from "../../assets/images/logo-1.png";
import SvgFilesIcon from "../SvgFiles/SvgFiles";

const HospitalCardView: FC<any> = ({ hospital, handleEditHospital, handleDeleteHospital }) => {
  return (
    <>
      <div className="patient-title-box">
        <div className="icon-box">
          <SvgFilesIcon iconname="hospital" />
        </div>
        <h4>{hospital.name.length > 20 ? `${hospital.name.slice(0, 20)}...` : hospital.name}</h4>
      </div>
      <div className="user-setting-box">
        <div className="edit-box" onClick={(e) => { e.stopPropagation(); handleEditHospital(e, hospital) }}>
          <SvgFilesIcon iconname={"edit"} />
        </div>
        <div className="delete-box" onClick={(e) => handleDeleteHospital(e, hospital)}>
          <SvgFilesIcon iconname={"delete"} />
        </div>
      </div>
      <div className="contact-box">
        <div className="common-box">
          <span>Contact Person</span>
          <p>{hospital?.contact_person || "-"}</p>
        </div>
        <div className="common-box">
          <span>Contact Number</span>
          <p>{hospital?.mobile || "-"}</p>
        </div>
      </div>
      <div className="address-box">
        <span>Address</span>
        <p>{hospital?.address || "-"} {hospital?.city?.city || " "} {hospital?.state?.state || " "} {hospital?.country?.country || " "} {hospital?.zipcode || " "}</p>
      </div>
      <div className="status-box">
        <div className="discharge-box">
          <SvgFilesIcon iconname="active" />
          <p>Active</p>
        </div>
        <p className="detail text-right mb-0">
          {hospital?.active_properties_count === 0 ? (
            <>No active property</>
          ) : (
            <>
              <b>
                {hospital?.active_properties_count}/
                {hospital?.all_properties_count}
              </b>{" "}
              Active property
            </>
          )}
        </p>
      </div>

    </>
  );
};

export default HospitalCardView;