import React, { createContext, useContext, useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
 
 
const FirebaseMessagingContext = createContext({});
 
export const FirebaseMessagingProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const messaging = getMessaging();
 
  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        if (Notification.permission !== "granted") {
          const permission = await Notification.requestPermission();
          if (permission !== "granted") {
            console.warn("Notification permission denied by user.");
          }
        }
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    }
 
    const handleForegroundMessages = () => {
      const unsubscribe = onMessage(messaging, (payload) => {
        try {
          if (payload?.notification) {
            const { title = "Default Title", body = "Default Body" } = payload.notification;
            new Notification(title, {
              body,
              icon: "/Entel-Icon-New.png",
            });
          }
        } catch (error) {
          console.error("Error handling foreground message:", error);
        }
      });
 
      return unsubscribe; // Return unsubscribe function
    };
    const initializeFirebaseMessaging = async () => {
      await requestNotificationPermission();
      const unsubscribe = handleForegroundMessages();
      return () => unsubscribe(); // Ensure cleanup
    };
 
    const cleanup = initializeFirebaseMessaging();
 
    return () => {
      cleanup.then((unsubscribe) => unsubscribe && unsubscribe());
    };
  }, [dispatch, messaging]);
  return (
    <FirebaseMessagingContext.Provider value={{}}>
      {children}
    </FirebaseMessagingContext.Provider>
  );
};
 
export const useFirebaseMessagingContext = () => useContext(FirebaseMessagingContext);