import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import entelIconNew from "../../assets/images/Entel-Icon-New.png";
import entelLogoNew from "../../assets/images/Entel-Logo-New.png";
import entelLightLogoNew from "../../assets/images/Entel-Logo-New-Light.png";

const Sidebar = () => {
  const { user_info } = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [activeItem, setActiveItem] = useState(location.pathname);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setActiveItem(location.pathname);
    setLoader(false); // Ensure loader stops when the route updates
  }, [location.pathname]);

  const handleNavigation = (path: string) => {
    if (activeItem !== path) {
      setActiveItem(path);
      setLoader(true);
      navigate(path);
    }
    
    if (path === "/patients") {
      dispatch({
        type: ActionTypes.SET_Main_Patient_TAB,
        payload: { patientMainTab: "1" },
      });
    }
  };

  return (
    <>
      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="vertical-menu sidebar-background">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={entelIconNew} alt="Entel Icon" height="22" />
            </span>
            <span className="logo-lg">
              <img src={entelLogoNew} className="dark-logo-image" alt="Entel Logo" height="45" />
              <img src={entelLightLogoNew} className="light-logo-image" alt="Entel Light Logo" height="45" />
            </span>
          </Link>
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="Logo Light" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="Logo Light PNG" height="19" />
            </span>
          </Link>
        </div>

        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className={classNames({ active: activeItem === "/dashboard" })}>
                <a onClick={() => handleNavigation("/dashboard")}>
                  <i className="mdi mdi-view-dashboard-outline"></i>
                  <span>Dashboards</span>
                </a>
              </li>
              <li className={classNames({ active: activeItem === "/patients" })}>
                <a onClick={() => handleNavigation("/patients")}>
                  <i className="mdi mdi-human-wheelchair"></i>
                  <span>Patients</span>
                </a>
              </li>
              {(user_info?.is_super_admin || user_info?.is_admin) && (
                <>
                  <li className={classNames({ active: activeItem === "/users" })}>
                    <a onClick={() => handleNavigation("/users")}>
                      <i className="mdi mdi-account-group-outline"></i>
                      <span>Users</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/user-roles" })}>
                    <a onClick={() => handleNavigation("/user-roles")}>
                      <i className="mdi mdi-account-group-outline"></i>
                      <span>User Roles</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/instructions" })}>
                    <a onClick={() => handleNavigation("/instructions")}>
                      <i className="mdi mdi-view-list-outline"></i>
                      <span>Instructions</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/activities" })}>
                    <a onClick={() => handleNavigation("/activities")}>
                      <i className="mdi mdi-television-ambient-light"></i>
                      <span>Activities</span>
                    </a>
                  </li>
                  <li className={classNames({ active: activeItem === "/reports" })}>
                    <a onClick={() => handleNavigation("/reports")}>
                      <i className="mdi mdi-chart-bar"></i>
                      <span>Reports</span>
                    </a>
                  </li>
                </>
              )}
              <li className={classNames({ active: activeItem === "/settings" })}>
                <a onClick={() => handleNavigation("/settings")}>
                  <i className="mdi mdi-cog-outline"></i>
                  <span>Master Pages</span>
                </a>
              </li>
              <li className={classNames({ active: activeItem === "/hospitals" })}>
                <a onClick={() => handleNavigation("/hospitals")}>
                  <i className="mdi mdi-hospital-building"></i>
                  <span>Hospitals</span>
                </a>
              </li>
              {(user_info?.is_super_admin || user_info?.is_admin || user_info?.user_type?.user_type?.toLowerCase() === "doctor") && (
                <li className={classNames({ active: activeItem === "/doctors-list" })}>
                  <a onClick={() => handleNavigation("/doctors-list")}>
                    <i className="mdi mdi-group"></i>
                    <span>Doctors List</span>
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className="sidebar-bottom">
            <p>Powered By</p>
            <div className="logo logo-dark">
              <span className="logo-sm">
                <img src={entelIconNew} alt="Entel Icon" height="22" />
              </span>
              <span className="logo-lg">
                <img src={entelLogoNew} className="dark-logo-image" alt="Entel Logo" height="45" />
                <img src={entelLightLogoNew} className="light-logo-image" alt="Entel Light Logo" height="45" />
              </span>
            </div>

            <div className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightSvg} alt="Logo Light" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLightPng} alt="Logo Light PNG" height="19" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
