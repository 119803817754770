import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Input } from "reactstrap";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { LinearProgress, MenuItem, Select } from "@mui/material";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { useSelector } from "react-redux";
import { grid_styles } from "../grid_styles";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import {
  deletePatientAdmissionLineTubes,
  getPatientAdmissionLineTubesByPatientAdmissionId,
  postPatientAdmissionLineTubes,
  putPatientAdmissionLineTubes,
} from "../../../../redux/actions/patientAdmissionLineTubes";
import { IDiseaseOptions } from "../../CreatePatient";
import { getLinetubeTypesList } from "../../../../redux/actions/linetubeAction";
import CustomMUIDatePicker from "../../../../components/UI/CustomMUIDatePicker";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";
 
const LineTubes: React.FC<any> = ({ selected_admission }) => {
  const initialRows = useMemo(
    () => [
      {
        id: "1",
        line_tube_type: { id: "", name: " " },
        date_time_ejection: new Date().toISOString().split('T')[0],
        date_time_removal:"",
        day_count: "",
        remarks: ""
      },
    ],
    []
  );
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const { user_info } = useSelector((state: any) => state?.user);
  const [rowId, setrowId] = useState(null);
  const handleRowUpdateError = (error: any) =>
    console.error("LineTubes Error : ", error);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const [lineTubeTypes, setLineTubeTypes] =
    useState<{ name: string; id: string }[]>();
 
  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      {
        id: "1",
        line_tube_type: { id: "", name: " " },
        // dr_name: "",
        date_time_ejection: new Date().toISOString().split('T')[0],
        date_time_removal: "",
        day_count: "",
        remarks: ""
      },
      ...prevRows,
    ]);
  };
 
  const getLineTubesByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionLineTubesByPatientAdmissionId(
        selected_admission?.id
      );
      if (request?.statusCode === 200) setRows(request?.body?.data);
      else setRows(initialRows);
    }
  }, [selected_admission]);
 
  const isValidDate = (dateString: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };
 
  const handleAdmissionLineTubes = useCallback(
    async ({
      id,
      line_tube_type,
      date_time_ejection,
      date_time_removal,
      day_count,
      remarks,
    }: {
      id: string;
      patient_admission_id: string;
      line_tube_type: { id: string; name: string };
      date_time_ejection: string;
      date_time_removal: string;
      day_count: string;
      remarks: string;
    }) => {
      try {
        if (line_tube_type.name.trim() === "" || !line_tube_type)
          return ErrorToast("Line tube type is a required field!");
        if (!date_time_ejection && !date_time_removal) {
          return ErrorToast("Both insertion and removal dates are empty!");
        }
 
        const ejectionDate = new Date(date_time_ejection);
        const removalDate = date_time_removal ? new Date(date_time_removal) : null;
 
        if (removalDate && ejectionDate.getTime()>removalDate.getTime()) {
          return ErrorToast("Removal date must be equal to or greater than ejection date!");
        }
        // if (date_time_ejection.trim() === "")
        //   return ErrorToast("Insertion Date is a required field!");
        // if (!isValidDate(date_time_ejection))
        //   return ErrorToast("Invalid Insertion Date!");
        // if (date_time_removal.trim() === "")
        //   return ErrorToast("Time and Date of removal is a required field!");
        // if (day_count.trim() === "")
        //   return ErrorToast("Day count is a required field!");
        // if (remarks.trim() === "")
        //   return ErrorToast("Remark is a required field!");
 
        let response;
        if (id === initialRows[0]?.id) {
          response = await postPatientAdmissionLineTubes({
            patient_admission_id: selected_admission?.id,
            line_tube_type_id: line_tube_type?.id,
            date_time_ejection,
            date_time_removal,
            day_count,
            remarks,
          });
        } else {
          response = await putPatientAdmissionLineTubes({
            id,
            patient_admission_id: selected_admission?.id,
            line_tube_type,
            date_time_ejection,
            date_time_removal,
            day_count,
            remarks,
          });
        }
 
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getLineTubesByPatientAdmissionId();
          setrowId(null);
        } else if (response?.statusCode === 400) {
          ErrorToast(response?.errors[0]?.msg);
        } else {
          ErrorToast("Unknown Error Occurred!");
        }
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, selected_admission?.id, getLineTubesByPatientAdmissionId]
  );
  const handleDeleteLineTubes = useCallback(
    async (id: string) => {
      try {
        if (id === initialRows[0]?.id)
          return ErrorToast("Record is not saved yet!");
        const response = await deletePatientAdmissionLineTubes(id);
 
        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          getLineTubesByPatientAdmissionId();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, getLineTubesByPatientAdmissionId]
  );
 
  useEffect(() => {
    const getLinetubeTypes = async () => {
      const { body } = await getLinetubeTypesList({});
      if (body) setLineTubeTypes(body?.data);
      else setLineTubeTypes([]);
    };
    getLinetubeTypes();
  }, []);
  const calculateDayCount = (params: any) => {
    const ejectionDate = params.row.date_time_ejection
      ? new Date(params.row.date_time_ejection)
      : new Date();
    const removalDate = params.row.date_time_removal
      ? new Date(params.row.date_time_removal)
      : new Date();
 
    if (!removalDate) {
      return "0";
    }
 
    // Compare only the date parts (year, month, day)
    const ejectionDateOnly = new Date(
      ejectionDate.getFullYear(),
      ejectionDate.getMonth(),
      ejectionDate.getDate()
    );
    const removalDateOnly = new Date(
      removalDate.getFullYear(),
      removalDate.getMonth(),
      removalDate.getDate()
    );
 
    if (ejectionDateOnly.getTime() === removalDateOnly.getTime()) {
      return "0";
    }
 
    const diffTime = Math.abs(removalDateOnly.getTime() - ejectionDateOnly.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return String(diffDays);
  };
 
  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };
 
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };
 
  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteLineTubes(currentDeleteId);
    }
    closeDeleteModal();
  };
  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: "line_tube_type",
        headerName: 'Type',
        type: "TextField",
        flex: 1,
        editable: !selected_admission?.discharged,
        filterable: true,
        sortable: true,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedLineTubeName: string) => {
            setrowId(params.id);
 
            const selectedLineTube = lineTubeTypes?.find(
              ({ name }) => name === selectedLineTubeName
            );
            if (!selectedLineTube) return  params.row.line_tube_type;
 
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;
 
            const isParamsIdOne = params.id === 1;
            const row = params.row || {};
 
            const updatedRow = {
              ...params.row,        
               line_tube_type: {
                id: selectedLineTube?.id,
                name: selectedLineTube?.name,
              },
 
              date_time_removal:
                rows[rowIndex]?.date_time_removal?.trim() !== "" &&
                  isParamsIdOne
                  ? rows[rowIndex]?.date_time_removal
                  : row?.date_time_removal,
              date_time_ejection:
                rows[rowIndex]?.date_time_ejection?.trim() !== "" &&
                  isParamsIdOne
                  ? rows[rowIndex]?.date_time_ejection
                  : row?.date_time_ejection,
            };
 
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() =>
                  params.value?.name.trim() === ""
                    ? "Select Line Tube Type"
                    : params?.value?.name
                }
                value={params?.value?.name ?? params?.row?.line_tube_type?.name}
                onChange={(event) => handleChange(event.target.value)}
                disabled={
                  !(
                    true ||
                    user_info?.hospital_property?.id ===
                    selected_admission?.hospital_property?.id
                  ) ||
                  !selected_admission?.admitted ||
                  selected_admission?.discharged
                }
              >
                <MenuItem value=" " disabled>
                  <em>Select Line Tube Type</em>
                </MenuItem>
                {lineTubeTypes && lineTubeTypes?.length > 0 ? (
                  lineTubeTypes?.map(({ name, id }: IDiseaseOptions) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Line Tube Types Found</MenuItem>
                )}
              </Select>
            </div>
          );
        },
      },
      {
        field: "date_time_ejection",
        headerName: "Insertion Date",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedDateTime: string) => {
            setrowId(params.id);
 
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;
 
            const updatedRow = {
              ...rows[rowIndex],
              date_time_ejection: selectedDateTime|| new Date().toISOString().split('T')[0],
            };
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
 
          return (
            <>
              {!(
                true ||
                user_info?.hospital_property?.id ===
                selected_admission?.hospital_property?.id
              ) ||
                !selected_admission?.admitted ||
                selected_admission?.discharged ? (
                <div>{params?.value}</div>
              ) : (
                <CustomMUIDatePicker
                  useDateTimePicker={false}
                  name="date_time_ejection"
                  value={params?.value}
                  type="formik"
                  setState={(name: string, value?: string | any) => {
                    handleChange(value)
                  }}
                />
              )}
            </>
          )
        },
      },
      {
        headerName: "Removal Date",
        field: "date_time_removal",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedDateTime: string) => {
            setrowId(params.id);
 
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;
 
            const updatedRow = {
              ...rows[rowIndex],
              date_time_removal: selectedDateTime || new Date().toISOString().split('T')[0],
            };
 
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
 
          return (
            <>
              {!(
                true ||
                user_info?.hospital_property?.id ===
                selected_admission?.hospital_property?.id
              ) ||
                !selected_admission?.admitted ||
                selected_admission?.discharged ? (
                <div>{params?.value}</div>
              ) : (
                <CustomMUIDatePicker
                  useDateTimePicker={false}
                  name="date_time_removal"
                  value={params?.value}
                  type="formik"
                  setState={(name: string, value?: string | any) => {
                    handleChange(value);
                  }}
                />
              )}
            </>
          );
        },
      },
      {
        field: "day_count",
        headerName: "Day Count",
        type: "TextField",
        flex: 1,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
        valueGetter: (params: any) => {
          const diffDays = calculateDayCount(params);
          return diffDays;
        },
      },
      {
        field: "remarks",
        headerName: "Remark",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: !selected_admission?.discharged,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setrowId(params.id);
 
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;
 
            const updatedRow = {
              ...rows[rowIndex],
              remarks: event.target.value,
            };
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <>
              {!(
                true ||
                user_info?.hospital_property?.id ===
                selected_admission?.hospital_property?.id
              ) ||
                !selected_admission?.admitted ||
                selected_admission?.discharged ? (
                <div>{params?.value}</div>
              ) : (
                <Input
                  name="remarks"
                  style={{ border: 0 }}
                  type="text"
                  value={params?.value}
                  onChange={handleChange}
                />
              )}
            </>
          )
        },
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => {
          const shouldShowActions =
            (true ||
              user_info?.hospital_property?.id ===
              selected_admission?.hospital_property?.id) &&
            selected_admission?.admitted &&
            !selected_admission?.discharged;
          return shouldShowActions
            ? [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                color={"primary"}
                disabled={params?.id !== rowId}
                onClick={() => {
                  handleAdmissionLineTubes({
                    id: params.row.id.toString(),
                    patient_admission_id: params.row.patient_admission_id,
                    line_tube_type: params.row.line_tube_type,
                    date_time_ejection: params.row.date_time_ejection,
                    date_time_removal: params.row.date_time_removal,
                    day_count: calculateDayCount(params),
                    remarks: params.row.remarks,
                  });
                }}
              />,
 
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                disabled={params?.id === initialRows[0]?.id}
                onClick={() => openDeleteModal(params.row.id.toString())}
              />,
            ]
            : []
        },
      },
    ],
    [
      handleAdmissionLineTubes,
      openDeleteModal,
      initialRows,
      lineTubeTypes,
      rowId,
      rows,
    ]
  );
 
  useEffect(() => {
    getLineTubesByPatientAdmissionId();
  }, [getLineTubesByPatientAdmissionId]);
 
 
 
  return (
    <>
      <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => addRow()}
            >
              Add Row
            </button>
          )}
      </div>
 
      <div className="structure-box">
        <div className="inner-con-box">
          <DataGrid
            rows={rows}
            rowHeight={45}
            columnHeaderHeight={45}
            columns={columns}
            autoHeight={true}
            editMode="cell"
            disableRowSelectionOnClick={true}
            pagination={true}
            processRowUpdate={processRowUpdate}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            slots={{
              noRowsOverlay: NoRowsOverlay,
              loadingOverlay: LinearProgress,
            }}
            paginationMode="client"
            sx={grid_styles}
            onCellEditStart={onCellEditCommit}
            onProcessRowUpdateError={handleRowUpdateError}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission lines/tubes"
      />
    </>
  );
};
 
export default LineTubes;