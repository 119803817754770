import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import maleAvatar from "../../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../../assets/images/users/female_avatar.png";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import moment from "moment";
import { getCountryList } from "../../../redux/actions/countryAction";
import { Country } from "../../../redux/types/countryTypes";
import { getCityByStateList } from "../../../redux/actions/cityAction";
import { getStatesByCountry } from "../../../redux/actions/stateActions";
import { IStateOptions } from "../../../redux/types/stateTypes";
import { ICitiesOptions } from "../../../redux/types/cityTypes";
import { updatePatient } from "../../../redux/actions/patientAction";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import { calculateAge } from "../../../helpers/helperFunctions";
import SvgFilesIcon from "../../../components/SvgFiles/SvgFiles";

const PatientDetailBasicInfo: React.FC<any> = ({
  patient,
  activeTab,
  fetchPatientData,
  basicInfoTab,
  setIsBasicInfoEditable,
  isBasicInfoEditable,
}) => {
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [stateOptions, setStateOptions] = useState<IStateOptions[]>([]);
  const [cityOptions, setCityOptions] = useState<ICitiesOptions[]>([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const [age, setAge] = useState("");

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Please enter valid First Name")
      .required("First Name is required"),
    middle_name: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid Middle Name")
      .optional(),
    last_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Please enter valid Last Name")
      .required("Last Name is required"),
    blood_group: Yup.string()
      .matches(/^(A|B|AB|O)[+-]?$/, "Invalid blood group")
      .optional(),
    birth_date: Yup.string().optional(),
    age: Yup.string().optional(),
    weight: Yup.number()
      .min(0, "Weight must be a positive number")
      .max(250, "Weight must be less than or equal to 250 kg")
      .optional(),
    height: Yup.number().min(0, 'Height must be a positive number').max(274, 'Height must be less than or equal to 274 cm').optional(),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email"
      )
      .optional(),
    phone_number: Yup.string()
      .matches(
        /^[56789][0-9]{9}$/,
        "Please enter valid Phone Number (10 digits)"
      )
      .optional(),
    // aadhar_card_no: Yup.string().required("Aadhar Card No is required"),
    aadhar_card_no: Yup.string()
      .matches(/^\d{12}$/, "Please enter valid Aadhar card number (12 digits)")
      .optional(),
    ayushman_card_no: Yup.string()
      .matches(
        /^[0-9]{12,16}$/,
        "Please enter valid Ayushman card (12 to 16 digits)"
      )
      .optional(),
    sex: Yup.string().optional(),
    maritial_status: Yup.string().optional(),
    address: Yup.string().optional(),
    pincode: Yup.string()
      .matches(/^\d{6}$/, "Please enter valid Pincode (6 digits)")
      .optional(),
    emergency_contact_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Please enter valid Emergency ncontact name")
      .optional(),
    emergency_contact_relationship: Yup.string().optional(),
    emergency_contact_no: Yup.string()
      .matches(
        /^[56789][0-9]{9}$/,
        "Please enter valid Emergency contact number (10 digits)"
      )
      .optional(),
    country_id: Yup.string().nullable().optional(),
    state_id: Yup.string().nullable().optional(),
    city_id: Yup.string().nullable().optional(),
  });

  useEffect(() => {
    setInitialValues({
      first_name: patient?.first_name ? patient?.first_name : "",
      middle_name: patient?.middle_name ? patient?.middle_name : "",
      last_name: patient?.last_name ? patient?.last_name : "",
      blood_group: patient?.blood_group ? patient?.blood_group : "",
      age: patient?.age ? patient?.age : "",
      birth_date: patient?.birth_date ? patient?.birth_date : "",
      weight: patient?.weight ? patient?.weight : "",
      height: patient?.height ? patient?.height : "",
      phone_number: patient?.phone_number ? patient?.phone_number : "",
      email: patient?.email ? patient?.email : "",
      aadhar_card_no: patient?.aadhar_card_no ? patient?.aadhar_card_no : "",
      ayushman_card_no: patient?.ayushman_card_no
        ? patient?.ayushman_card_no
        : "",
      sex: patient?.sex ? patient?.sex : "",
      maritial_status: patient?.maritial_status ? patient?.maritial_status : "",
      address: patient?.address ? patient?.address : "",
      country_id: patient?.country?.id ? patient?.country?.id : null,
      state_id: patient?.state?.id ? patient?.state?.id : null,
      city_id: patient?.city?.id ? patient?.city?.id : null,
      pincode: patient?.pincode ? patient?.pincode : "",
      emergency_contact_name: patient?.emergency_contact_name
        ? patient?.emergency_contact_name
        : "",
      emergency_contact_relationship: patient?.emergency_contact_relationship
        ? patient?.emergency_contact_relationship
        : "",
      emergency_contact_no: patient?.emergency_contact_no
        ? patient?.emergency_contact_no
        : "",
    });
    setAge(patient?.age ? patient?.age : "");
  }, [patient, updateEnabled]);

  const fetch_states = useCallback(
    async (country_id: string, setFieldValue: any) => {
      if (country_id !== undefined) {
        const request = await getStatesByCountry({ country_id });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("state_id", "");
            setFieldValue("city_id", "");
            setFieldValue("pincode", "");
          }
          setStateOptions(
            request?.body?.data?.map((state: any) => {
              return {
                id: state?.id,
                state: state?.state,
              };
            })
          );
        } else {
          setStateOptions([]);
          setFieldValue("state_id", "");
          setFieldValue("city_id", "");
          setFieldValue("pincode", "");
        }
      }
    },
    []
  );

  const fetch_city = useCallback(
    async (state_id: string, setFieldValue: any) => {
      if (state_id !== undefined) {
        const request = await getCityByStateList({ state_id });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("city_id", "");
            setFieldValue("pincode", "");
          }
          setCityOptions(
            request?.body?.data?.map((city: any) => {
              return {
                id: city?.id,
                city: city?.city,
                zipcode: city?.zipcode
              };
            })
          );
        } else {
          setFieldValue("city_id", "");
          setFieldValue("pincode", "");
          setCityOptions([]);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (initialValues.birth_date) {
      const calculatedAge = calculateAge(initialValues.birth_date);
      setAge(calculatedAge);
    }
  }, [initialValues.birth_date]);

  useEffect(() => {
    /* FETCH ALL COUNTRIES */
    async function fetchCountryData() {
      const request = await getCountryList({});
      if (request?.statusCode === 200) {
        if (request?.body?.data.length === 0) {
          setCountryOptions([]);
        } else {
          const updatedCountryList: Country[] = request?.body?.data?.map(
            (e: any) => ({ country: e?.country, id: e?.id })
          );
          setCountryOptions(updatedCountryList);
        }
      }
    }

    /* FETCH ALL STATES */
    async function fetchStateData() {
      const request = await getStatesByCountry({
        country_id: patient?.country?.id,
      });
      if (request?.statusCode === 200) {
        if (request?.body?.data.length === 0) {
          setStateOptions([]);
        } else {
          const updatedStateList = request?.body?.data?.map(
            (singleState: any) => ({
              state: singleState?.state,
              id: singleState?.id,
            })
          );
          setStateOptions(updatedStateList);
        }
      }
    }

    /* FETCH ALL CITIES */
    async function fetchCityData() {
      const request = await getCityByStateList({
        state_id: patient?.state?.id,
      });
      if (request?.statusCode === 200) {
        if (request?.body?.data?.length === 0) {
          setCityOptions([]);
        } else {
          const updatedCityList = request?.body?.data?.map(
            (singleCity: any) => ({
              city: singleCity?.city,
              id: singleCity?.id,
              zipcode: singleCity?.zipcode
            })
          );
          setCityOptions(updatedCityList);
        }
      }
    }

    if (updateEnabled) {
      fetchCountryData();
      fetchStateData();
      fetchCityData();
    }
  }, [updateEnabled]);

  const handleUpdatePatient = async (values: any) => {
    try {
      const response = await updatePatient({ id: patient?.id, ...values });
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        fetchPatientData();
        setIsBasicInfoEditable(false);
        setUpdateEnabled(false);
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  const handleCancel = () => {
    setUpdateEnabled(false);
    setIsBasicInfoEditable(false);
  };

  useEffect(() => {
    if (basicInfoTab && isBasicInfoEditable === true) {
      setUpdateEnabled(true);
    } else {
      setUpdateEnabled(false);
    }
  }, [basicInfoTab, isBasicInfoEditable]);

  useEffect(() => {
    if (activeTab !== "3" || isBasicInfoEditable === false) {
      setUpdateEnabled(false);
    }
  }, [activeTab, basicInfoTab, isBasicInfoEditable]);

  const handleChangeCity = (setFieldValue: any, e: any) => {
    setFieldValue("city_id", e.target.value);
    const Zipcode = cityOptions.find((city) => city.id === e.target.value)?.zipcode || "";
    setFieldValue("pincode", Zipcode);
  }

  return (
    <Card className="overflow-hidden">
      <CardBody className="hospital-detail scroll-bar-patients-basic">
        {updateEnabled ? (
          <div className="m-4">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleUpdatePatient(values);
              }}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                handleBlur,
                setFieldValue,
              }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (errors) {
                      const firstError: any = Object.values(errors)[0];

                      if (Array.isArray(firstError)) {
                        const errorMessage = firstError.join(", ");
                        ErrorToast(errorMessage);
                      } else ErrorToast(firstError);
                    }
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <CardTitle className="h4 hospital-card-title text-primary">
                        Basic Info
                      </CardTitle>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                          value={values?.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Middle Name</Label>
                        <Input
                          name="middle_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Middle Name"
                          value={values?.middle_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="middle_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Last Name"
                          value={values?.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Aadhar Card No.</Label>
                        <Input
                          name="aadhar_card_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter Adhar"
                          value={values?.aadhar_card_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="aadhar_card_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">
                          Aayushman Card No. :{" "}
                        </Label>
                        <Input
                          name="ayushman_card_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                          value={values?.ayushman_card_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="ayushman_card_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Phone</Label>
                        <Input
                          name="phone_number"
                          className="form-control"
                          type="text"
                          placeholder="Enter Phone"
                          value={values?.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          type="text"
                          placeholder="Enter Email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Blood Group</Label>
                        <Input
                          name="blood_group"
                          className="form-control"
                          type="text"
                          placeholder="Enter Blood Group"
                          value={values?.blood_group}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="blood_group"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Birth Date</Label>
                        <CustomMUIDatePicker
                          maxDate={new Date}
                          name="birth_date"
                          value={values?.birth_date}
                          setAge={setAge}
                          setState={setFieldValue}
                          sx={{
                            ".MuiOutlinedInput-input": {
                              color: "#fff",
                              height: "4px",
                              fontWeight: 400,
                              fontSize: "0.8125rem",
                            },
                          }}
                          type="formik"
                        />
                        <ErrorMessage
                          name="birth_date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Age (Years)</Label>
                        <Input
                          name="age"
                          className="form-control"
                          type="text"
                          placeholder="Enter Age"
                          value={values?.age || ''}
                          onChange={(e) => {
                            const newValue = e.target.value.toUpperCase();
                            const validValue = newValue.replace(/[^0-9\.]/g, '');
                            const numericValue = parseFloat(validValue)
                            if (!isNaN(numericValue) && parseFloat(validValue) <= 125) {
                              handleChange({
                                target: {
                                  name: "age",
                                  value: validValue
                                }
                              });
                            } else {
                              handleChange({
                                target: {
                                  name: "age",
                                  value: ""
                                }
                              });
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="age"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Weight (kg)</Label>
                        <Input
                          name="weight"
                          className="form-control"
                          type="text"
                          placeholder="Enter Weight"
                          value={values?.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="weight"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Height (ft/cm)</Label>
                        <Input
                          name="height"
                          className="form-control"
                          type="text"
                          placeholder="Enter Height"
                          value={values?.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="height"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <Label className="form-label">Sex</Label>
                        <div className="d-block mt-2">
                          <div className="form-check d-inline-block mr-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="sex"
                              value="male"
                              id="sexRadios1"
                              checked={values?.sex === "male"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="sexRadios1"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check d-inline-block ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="sex"
                              id="sexRadios2"
                              value="female"
                              checked={values?.sex === "female"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="sexRadios2"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        name="sex"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="">
                        <Label className="form-label"> Marital status</Label>
                        <div className="d-block mt-2">
                          <div className="form-check d-inline-block mr-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="maritial_status"
                              id="maritial_statusRadio1"
                              value="single"
                              checked={values?.maritial_status === "single"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="maritial_statusRadio1"
                            >
                              Single
                            </label>
                          </div>
                          <div className="form-check d-inline-block ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="maritial_status"
                              id="maritial_statusRadio2"
                              value="married"
                              checked={values?.maritial_status === "married"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="maritial_statusRadio2"
                            >
                              Married
                            </label>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        name="maritial_status"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-12">
                      <CardTitle className="h4 hospital-card-title text-primary">
                        Address Details
                      </CardTitle>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Address</Label>
                        <Input
                          name="address"
                          className="form-control"
                          type="text"
                          placeholder="Enter Address"
                          value={values?.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <Label className="form-label">Country</Label>
                        <Field
                          as="select"
                          className="form-control"
                          id="country_id"
                          name="country_id"
                          value={values?.country_id || ''}
                          onChange={async (e: any) => {
                            handleChange(e);
                            if (e.target.value !== undefined)
                              await fetch_states(
                                e?.target?.value,
                                setFieldValue
                              );
                          }}
                          onBlur={handleBlur}
                        >
                          <option value={undefined}>Select Country</option>
                          {countryOptions?.map(({ country, id }) => (
                            <option key={id} value={id}>
                              {country}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <Label className="form-label">State</Label>
                        <select
                          className="form-control"
                          name="state_id"
                          id="state_id"
                          value={values?.state_id || ''}
                          onChange={async (e: any) => {
                            if (stateOptions?.length === 0)
                              handleChange(undefined);
                            handleChange(e);
                            if (e.target.value !== undefined)
                              await fetch_city(e?.target?.value, setFieldValue);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value={undefined}>Select a state</option>
                          {stateOptions?.map(({ state, id }) => (
                            <option key={id} value={id}>
                              {state}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="state_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <Label className="form-label">City</Label>
                        <Field
                          as="select"
                          className="form-control"
                          id="city_id"
                          value={values?.city_id || ''}
                          name="city_id"
                          onChange={(e: any) => handleChangeCity(setFieldValue, e)}
                          onBlur={handleBlur}
                        >
                          <option value="">Select a city</option>
                          {cityOptions?.map(({ city, id }) => (
                            <option key={id} value={id}>
                              {city}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <Label className="form-label">Pincode</Label>
                        <Input
                          name="pincode"
                          className="form-control"
                          type="text"
                          placeholder="Enter Pincode"
                          value={values?.pincode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="pincode"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-12">
                      <CardTitle className="h4 hospital-card-title text-primary">
                        Emergency Contact
                      </CardTitle>
                    </div>{" "}
                    <div className="col-4">
                      <div className="">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="emergency_contact_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Emergency Contact Name"
                          value={values?.emergency_contact_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="emergency_contact_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="">
                        <Label className="form-label">Relationship</Label>
                        <select
                          name="emergency_contact_relationship"
                          className="form-control"
                          value={values?.emergency_contact_relationship}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            Select Relationship
                          </option>
                          <option value="father">Father</option>
                          <option value="mother">Mother</option>
                          <option value="wife">Wife</option>
                          <option value="husband">Husband</option>
                          <option value="son">Son</option>
                          <option value="daughter">Daughter</option>
                          <option value="sibling">Sibling</option>
                        </select>
                        <ErrorMessage
                          name="emergency_contact_relationship"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="">
                        <Label className="form-label">Contact No</Label>
                        <Input
                          name="emergency_contact_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter Contact No"
                          value={values?.emergency_contact_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="emergency_contact_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="btn btn-secondary mr-2 text-white mt-20 align-self-center"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary mt-20 align-self-center"
                    >
                      {" "}
                      Save{" "}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-xl-4 left-side">
              <div className="bg-primary bg-soft patient-basicinfo left-inner p-3">
                <div className="brand-logo patient-title-box">
                  <div className="icon-box">
                    <SvgFilesIcon iconname={patient?.sex || "male"} />
                  </div>
                </div>
                <div className="brand-detail">
                  <div className="title">
                    <div className="d-flex align-items-center justify-content-between mb-2 hospital-card-box">
                      <div className="title">
                        <h4>
                          {patient?.first_name} {patient?.middle_name}{" "}
                          {patient?.last_name}
                        </h4>
                      </div>
                      <div className="user-setting-box">
                        <div className="edit-box" onClick={() => setUpdateEnabled(true)} style={{ cursor: 'pointer', marginRight: 10 }}>
                          <SvgFilesIcon iconname={"edit"} />
                        </div>
                      </div>
                    </div>
                    <div className="address-basicinfo">
                    <p className="mb-2">
                      {patient?.address ? patient?.address + "," : " "}
                      {patient?.city?.city ? patient?.city?.city + "," : " "}
                      {patient?.state?.state
                        ? patient?.state?.state + ","
                        : " "}
                      {patient?.country?.country
                        ? patient?.country?.country + "."
                        : " "}
                      {patient?.pincode ? patient?.pincode + "" : " "}
                    </p>
                    </div>
                    <span className="badge-soft-success me-1 badge bg-secondary mb-2">
                      Blood Group :{" "}
                      {patient?.blood_group ? patient?.blood_group : "-"}
                    </span>
                  </div>
                </div>
                <div className="address-line mb-2">
                  <div className="d1">
                    <label className="title">Weight :</label>
                    <p className="deatil mb-0">
                      {patient?.weight ? patient?.weight + " kg" : "-"}
                    </p>
                  </div>
                  <div className="d1">
                    <label className="title"> Height :</label>
                    <p className="deatil mb-0">
                      {patient?.height ? patient?.height + " ft/cm" : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-xl-8">
              <div className="right-side">
                <div className="row mb-2">
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title">Key Person : </label>
                      <p className="deatil mb-0">
                        {patient?.emergency_contact_name
                          ? patient?.emergency_contact_name
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-10">
                        <div className="d-flex">
                          <label className="title"> Mobile :</label>
                          <p className="deatil mb-0">
                            {" "}
                            {patient?.emergency_contact_no
                              ? patient?.emergency_contact_no
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title"> Aayushman Card No. : </label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.ayushman_card_no
                          ? patient?.ayushman_card_no
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title"> Adhar Card No :</label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.aadhar_card_no
                          ? patient?.aadhar_card_no
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title"> Email :</label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.email ? patient?.email : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title"> Gender : </label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.sex === "male"
                          ? "Male"
                          : patient?.sex === "female"
                            ? "Female"
                            : "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title">Emergency Contact No: </label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.emergency_contact_no
                          ? patient?.emergency_contact_no
                          : " -"}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <label className="title"> Created By :</label>
                      <p className="deatil mb-0">
                        {" "}
                        {patient?.created_by?.name
                          ? patient?.created_by?.name
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default PatientDetailBasicInfo;