import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Card, CardBody, CardTitle, Collapse, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Header from "../../components/Header/Header";
import { all_hospitals_without_pagination } from "../../redux/actions/hospitalAction";
import { deletePatientAdmissionInstructions, getPatientAdmissionInstructionsByHospitalPropertyId, putPatientAdmissionInstructions } from "../../redux/actions/patientAdmissionInstructions";
import { hospital_property_by_hospital } from "../../redux/actions/hospitalPropertyAction";
import { ISingleInstruction } from "../../redux/types/patientAdmissionInstructionsTypes";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { useSelector } from "react-redux";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import DropdownField from "../../components/CommonHospitalComponent/CommonDropdown";

const Instructions = () => {
  const [header_state, setheader_state]: any = useState(Boolean(localStorage.getItem("header")));
  const { user_info } = useSelector((state: any) => state.user);

  const initialFilters = {
    start_date: "",
    end_date: "",
    hospital_property_id: "",
    sort_order: "",
  };
  const [completedCollapsedItems, setCompletedCollapsedItems] = useState<
    boolean[]
  >([]);
  const [incompletedCollapsedItems, setIncompletedCollapsedItems] = useState<
    boolean[]
  >([]);
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  const [completedInstructions, setCompletedInstructions] = useState<ISingleInstruction[]>([]);
  const [incompletedInstructions, setIncompletedInstructions] = useState<ISingleInstruction[]>([]);
  const [filters, setFilters] = useState(initialFilters);
  const [all_hospitals, set_all_hospitals] = useState([]);
  const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(user_info?.hospital_property?.hospital?.id || "")
  const [notCompletedInstructionsLength, setNotCompletedInstructionsLength] = useState(0);
  const [currentActiveTab, setCurrentActiveTab] = useState("2");
  const isHospitalPropertyIdSelected = filters?.hospital_property_id.trim() !== "";

  // Toggle active state for Tab
  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  useEffect(() => {
    setCompletedCollapsedItems(
      new Array(completedInstructions?.length).fill(true)
    );
  }, [completedInstructions]);

  useEffect(() => {
    setIncompletedCollapsedItems(
      new Array(incompletedInstructions?.length).fill(true)
    );
  }, [incompletedInstructions]);

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  const toggleCompletedCollapse = (index: number) => {
    setCompletedCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };

  const toggleIncompleteCollapse = (index: number) => {
    setIncompletedCollapsedItems((prevState) =>
      prevState?.map((item, i) => (i === index ? !item : true))
    );
  };

  const fetch_hospitals = async () => {
    try {
      const request = await all_hospitals_without_pagination();
      if (request?.statusCode === 200) {
        set_all_hospitals(request?.body?.data);
      } else {
        set_all_hospitals([]);
      }
    } catch (error) {
      console.error("Error fetch_hospitals:", error);
      set_all_hospitals([]);
    }
  };

  const fetchHospitalProperties = useCallback(async (selectedHospital: string) => {
    try {
      if (selectedHospital.trim() !== "") {
        const request = await hospital_property_by_hospital(selectedHospital);
        if (request?.statusCode === 200) {
          set_all_hospitals_properties(request?.body?.data);
          setFilters({
            ...filters,
            hospital_property_id: request?.body?.data[0]?.id,
          });
        } else {
          set_all_hospitals_properties([]);
        }
      }
    } catch (error) {
      console.error("Error : fetchHospitalProperties", error);
    }
  },
    [filters]
  );

  useEffect(() => {
    fetchHospitalProperties(selectedHospital);
  }, [selectedHospital]);

  useEffect(() => {
    fetch_hospitals();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const completedInstructions = await getPatientAdmissionInstructionsByHospitalPropertyId({ hospital_property_id: user_info?.hospital_property?.id, is_completed: true });
      if (completedInstructions?.statusCode === 200) {
        setCompletedInstructions(completedInstructions?.body?.data);
      } else {
        setCompletedInstructions([]);
      }

      const incompletedInstructions = await getPatientAdmissionInstructionsByHospitalPropertyId({ hospital_property_id: user_info?.hospital_property?.id, is_completed: false });
      if (incompletedInstructions?.statusCode === 200) {
        const length = incompletedInstructions?.body?.data?.length;
        setNotCompletedInstructionsLength(length);
        setIncompletedInstructions(incompletedInstructions?.body?.data);
      } else {
        setNotCompletedInstructionsLength(0);
        setIncompletedInstructions([]);
      }
    };
    if (user_info?.is_super_admin === false) {
      fetchData();
    };
  }, []);



  useEffect(() => {
    if (user_info?.is_super_admin === false) {
      setFilters({ ...filters, hospital_property_id: user_info?.hospital_property?.id });
    }
  }, [user_info]);

  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };

  const search_instructions_by_hospital_id = useCallback(async () => {
    const { start_date, end_date } = filters;

    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      return;
    }

    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        return;
      }

      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        return;
      }
    }

    if ((selectedHospital !== "" && filters.hospital_property_id === "") || (selectedHospital === "" && filters.hospital_property_id === "")) {
      ErrorToast(ERROR_MESSAGES.noHospitalProperty);
      return;
    }

    if (user_info?.is_super_admin === false && filters.hospital_property_id !== "" && filters.sort_order === "" && (!start_date.trim() || !end_date.trim())) {
      ErrorToast(ERROR_MESSAGES.noFiltersSelected);
      return;
    }

    const updatedFilters: any = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== "") {
        updatedFilters[key] = value;
      }
    });

    if (Object.entries(updatedFilters).length === 0) {
      ErrorToast(ERROR_MESSAGES.noFiltersSelected);
      return;
    }

    const completedInstructions = await getPatientAdmissionInstructionsByHospitalPropertyId({ ...updatedFilters, is_completed: true });
    if (completedInstructions?.statusCode === 200) {
      setCompletedInstructions(completedInstructions?.body?.data);
    } else {
      setCompletedInstructions([]);
    };

    const incompletedInstructions = await getPatientAdmissionInstructionsByHospitalPropertyId({ ...updatedFilters, is_completed: false });
    if (incompletedInstructions?.statusCode === 200) {
      const length = incompletedInstructions?.body?.data?.length;
      setNotCompletedInstructionsLength(length);
      setIncompletedInstructions(incompletedInstructions?.body?.data);
    } else {
      setNotCompletedInstructionsLength(0);
      setIncompletedInstructions([]);
    }
    setfilterOpen(false)

  }, [filters, selectedHospital, user_info?.is_super_admin]);

  const CustomDropdownBox = () => (
    <div className="row">
      <div className="col-6 mb-3">
        <Label className="form-label">Start Date</Label>
        <CustomMUIDatePicker
          name="start_date"
          type="formik"
          value={filters?.start_date}
          setState={handleFilterChange}
        />
      </div>
      <div className="col-6 mb-3">
        <Label className="form-label">End Date</Label>
        <CustomMUIDatePicker
          name="end_date"
          type="formik"
          value={filters?.end_date}
          setState={handleFilterChange}
        />
      </div>
      <div className="row">
        {(user_info?.is_super_admin === true && all_hospitals?.length > 0 && (
          <div className="col-6 mb-3 instraction-dropdown">
            <DropdownField
              label="Hospital"
              options={all_hospitals}
              value={selectedHospital}
              onChange={(e) => setSelectedHospital(e.target.value as string)}
              name="hospitals"
            />
          </div>
        ))}
        <div className="col-6 mb-3 instraction-dropdown">
          <DropdownField
            label="Hospital Properties"
            options={all_hospitals_properties}
            value={filters.hospital_property_id}
            onChange={(e) => handleFilterChange("hospital_property_id", (e.target.value as string))}
            name="hospital_property_id"
          /></div>
      </div>
    </div>
  );
  const CustomButtonBox = () => (
    <div className="col-12 text-center">
      <button
        type="button"
        onClick={() => search_instructions_by_hospital_id()}
        className="btn btn-info medium-btn mr-2"
      >
        Apply
      </button>
      <button
        onClick={() => {
          setFilters(initialFilters)
          setSelectedHospital("");
          setfilterOpen(false)
        }} type="button"
        className="btn btn-secondary medium-btn"
      >
        Reset
      </button>
    </div>
  )

  const handleDeleteInstruction = useCallback(
    async (id: string) => {
      try {
        if (id?.trim() === "" || !id) return ErrorToast("Try again!");
        const response = await deletePatientAdmissionInstructions(id);

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          search_instructions_by_hospital_id();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [search_instructions_by_hospital_id]
  );

  const handleUpdateCompleteInstruction = async (id: string) => {
    try {
      const response = await putPatientAdmissionInstructions({
        id: id,
        is_completed: true,
      });
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        search_instructions_by_hospital_id();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast("Unknown Error Occured!");
    }
  };

  return (
    <>
      <Header HeaderName="Instructions" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div className="page-title-right d-flex">
                    <div className="mr-2">
                      <Label className="form-label mr-5">Sort </Label>
                      <select
                        className="form-control"
                        value={filters.sort_order}
                        name="sort_order"
                        onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                      >
                        <option style={{ color: "#000" }} hidden>
                          Sort by
                        </option>
                        <option style={{ color: "#000" }} value="ASC">
                          Ascending
                        </option>
                        <option style={{ color: "#000" }} value="DESC">
                          Descending
                        </option>
                      </select>
                    </div>
                    <div className="mr-2">
                      <CustomButtonBox />
                    </div>
                  </div>
                  <div className="page-title-right d-flex ">
                    <div className="mr-2">
                      <button
                        type="button"
                        className="btn btn-outline-light"
                        onClick={() => setfilterOpen((filterOpen) => !filterOpen)}
                      >
                        <i className="mdi mdi-filter-outline"></i> Filter
                        <i className="mdi mdi-chevron-down" />
                      </button>
                      {filterOpen && (
                        <div className="filter-box">
                          <button
                            className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                            onClick={() => setfilterOpen(false)}
                          ><i className="mdi mdi-close" />
                          </button>
                          <CustomDropdownBox />
                          <CustomButtonBox />
                        </div>
                      )}
                    </div>
                    <div className="page-title-right d-flex">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={currentActiveTab === "1" ? "active" : ""}
                            onClick={() => toggle("1")}
                          >
                            Current{" "}
                            <span className="badge bg-success rounded-pill">
                              {notCompletedInstructionsLength > 0
                                ? notCompletedInstructionsLength
                                : ""}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="all-complete-btn">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={currentActiveTab === "2" ? "active" : ""}
                            onClick={() => toggle("2")}
                          >
                            Completed
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div></div>
                </div>
              </div>
              <TabContent
                activeTab={currentActiveTab}
                className="text-muted"
              >
                <TabPane tabId="1">
                  <div className="col-12 col scroll-box">
                    <Card>
                      <CardBody>
                        <CardTitle></CardTitle>
                        <div className="row">
                          <div className="col-12">
                            <div className="accordion" id="accordion">
                              {incompletedInstructions &&
                                incompletedInstructions.length > 0 ?
                                incompletedInstructions.map(
                                  (
                                    instruction: ISingleInstruction,
                                    index: number
                                  ) => (
                                    <div className="accordion-item" key={index}>
                                      <h2
                                        className="accordion-header"
                                        id="headingThree"
                                      >
                                        <button
                                          className={`accordion-button fw-medium ${incompletedCollapsedItems[index]
                                            ? "collapsed"
                                            : ""
                                            }`}
                                          type="button"
                                          onClick={() =>
                                            toggleIncompleteCollapse(index)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          By Doctor - {instruction?.doctor?.name} ,
                                          Status - {!instruction?.is_completed ? 'Active' : "Complete"}
                                          <span className="right-date">
                                            <div className="gap-3">
                                              <a
                                                className="text-success mr-2"
                                                href="/#"
                                              >
                                                <i
                                                  className="mdi mdi-check"
                                                  style={{ fontSize: "20px" }}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleUpdateCompleteInstruction(instruction?.id);
                                                  }}
                                                ></i>
                                              </a>
                                              <a
                                                className="text-danger"
                                                href="/#"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleDeleteInstruction(
                                                    instruction?.id
                                                  );
                                                }}
                                              >
                                                <i className="mdi mdi-delete font-size-16"></i>
                                              </a>
                                            </div>
                                          </span>
                                        </button>
                                      </h2>
                                      <Collapse
                                        isOpen={!incompletedCollapsedItems[index]}
                                        className="accordion-collapse"
                                      >
                                        <div className="accordion-body">
                                          <div className="table-box">
                                            <table className="table">
                                              <thead className="table-light">
                                              <tr>
                                                  <th>Instruction Description :</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                              <tr>
                                                  <td>
                                                    {instruction?.instruction_description}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </Collapse>
                                    </div>
                                  )
                                ) : <div style={{ textAlign: "center" }}>{isHospitalPropertyIdSelected
                                  ? "No Rows Available"
                                  : "Please Select Hospital Property From Dropdown"}</div>}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="col-12 col scroll-box">
                    <Card>
                      <CardBody>
                        <CardTitle></CardTitle>
                        <div className="row">
                          <div className="col-12">
                            <div className="accordion" id="accordion">
                              {completedInstructions &&
                                completedInstructions?.length > 0 ?
                                completedInstructions.map(
                                  (
                                    instruction: ISingleInstruction,
                                    index: number
                                  ) => (
                                    <div className="accordion-item" key={index}>
                                      <h2
                                        className="accordion-header"
                                        id="headingThree"
                                      >
                                        <button
                                          className={`accordion-button fw-medium ${completedCollapsedItems[index]
                                            ? "collapsed"
                                            : ""
                                            }`}
                                          type="button"
                                          onClick={() =>
                                            toggleCompletedCollapse(index)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          By Doctor - {instruction?.doctor?.name} ,
                                          Status - {!instruction?.is_completed ? 'Active' : "Complete"}
                                          <span className="right-date">
                                            <div className="gap-3">
                                              <a
                                                className="text-danger"
                                                href="/#"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleDeleteInstruction(instruction?.id);
                                                }}
                                              >
                                                <i className="mdi mdi-delete font-size-16"></i>
                                              </a>
                                            </div>
                                          </span>
                                        </button>
                                      </h2>
                                      <Collapse
                                        isOpen={!completedCollapsedItems[index]}
                                        className="accordion-collapse"
                                      >
                                        <div className="accordion-body">
                                          <div className="table-box">
                                            <table className="table">
                                              <thead className="table-light">
                                                <tr>
                                                  <th>Instruction Description :</th>
                                                </tr>

                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    {instruction?.instruction_description}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </Collapse>
                                    </div>
                                  )
                                ) : <div style={{ textAlign: "center" }}>{isHospitalPropertyIdSelected
                                  ? "No Rows Available"
                                  : "Please Select Hospital Property From Dropdown"}</div>}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Instructions;
