import axiosApi from "../api/apiHelper";
import { hospital_wise_notifications_routes } from "./api_path";

 
export const getNotificationBasedonHospitalId = async (hospital_property_id:string,page_number?: number,
    per_page?: number,
) => {
    try {
        const { data } = await axiosApi.get(
            `${hospital_wise_notifications_routes.get_all_hospital_notificstions}?hospital_property_id=${hospital_property_id}&page_number=${page_number}&per_page=${per_page}`
        );
        return data;
    } catch (error: any) {
    return error?.response?.data;
    }
};
 
export const getMarkReadNotificationBasedonHospitalId = async (hospital_property_id: string) => {
    try {
        const { data } = await axiosApi.put(
         `${hospital_wise_notifications_routes.get_all_hospital_mark_Read_notifications}?hospital_property_id=${hospital_property_id}`
        );
        return data;
    } catch (error: any) {
        return error?.response?.data;
    }
};