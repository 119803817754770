import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/scss/theme.scss";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider} from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import RouteWrapper from "./router/RouteWrapper";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { NotificationsProvider } from "./components/Notification/notificationContainer";
import { FirebaseMessagingProvider } from "./components/Notification/firebasemessageshow";
const App = () => { 
  // let newTheme: any = localStorage.getItem("theme") ? localStorage.getItem("theme") : 'dark';
  // if (document.body) document.body.setAttribute("data-layout-mode", newTheme);
  return (
    <FirebaseMessagingProvider>
    <NotificationsProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastContainer />  
          {/* <Routing /> */}
          <RouteWrapper />
        </LocalizationProvider>
      </PersistGate>
    </Provider>
    </NotificationsProvider>
     </FirebaseMessagingProvider>
  );
};
 
export default App;

 
 