import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"; 
import "../../assets/scss/teleicu/reports.scss";
import Header from "../../components/Header/Header";
import Location from "../masters/location/Location";
import PatientChildhoodDiseases from "../masters/patient_form_masters/PatientChildhoodDiseases";
import PatientBehaviouralConditions from "../masters/patient_form_masters/PatientBehaviouralConditions";
import PatientBloodDiseases from "../masters/patient_form_masters/PatientBloodDiseases";
import PatientInjuries from "../masters/patient_form_masters/PatientInjuries";
import UserTypes from "../masters/user_type/UserTypes";
import Dietry from "../masters/dietry/Dietry";
import Discharge from "../masters/discharge/Discharge";
import Document from "../masters/document/Document";
import Investigation from "../masters/investigation/Investigation";
import LineTube from "../masters/linetube/LineTubeTypes";
import PastHistoryType from "../masters/past_history/PastHistoryTypes";
import InvestigationSubType from "../masters/investigation/InvestigationSub";
import PastHistorySubType from "../masters/past_history/PastHistorySubTypes";
import GeneralExaminationType from "../masters/generalExaminationType/GeneralExaminationType";
import SystemicExaminationType from "../masters/systemicExaminationType/SystemicExaminationType";
import HabitType from "../masters/habitType/HabitType";
import ProvisionalDiagnosisType from "../masters/provisionalDiagnosisType/ProvisionalDiagnosisType";
import MedicineType from "../masters/medicine/MedicineType";
import PatientOtherConditions from "../masters/patient_form_masters/PatientOtherConditions";
import { useSelector } from "react-redux";
import { master_pages_access } from "../../helpers/access_policies";
import NoAccess from "../../components/NoAccess";
import InfusionPumps from "../masters/infusionPumps/infusionPumps";
import Doctor from "../masters/doctor/doctor";
import DoctorSpeciality from "../masters/doctorSpeciality/DoctorSpeciality";
import ReportMeasurementUnitsType from "../masters/reportMeasurementUnitsType/ReportMeasurementUnitsType";
import PageName from "../../components/PageSubHeaderNameComponent/PageName";
import Medicine from "../masters/medicine/Medicine";
 
const Settings = () => {
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );
  const { user_info } = useSelector((state: any) => state.user);
  const [subTabThree, setsubTabThree] = useState("1");
  const [loader, setLoader] = useState(false);
 

  const subTabThreetoggle = (tab: string) => {
    if (subTabThree !== tab) {
      setLoader(true); 
      setTimeout(() => {
        setsubTabThree(tab);
        setLoader(false); 
      }, 200); 
    }
  };
 
  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }

  }, [header_state]);
  const [toggle_sidebar, settoggle_sidebar] = useState(true);
  return (
    <>
      {loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      <Header />
      <Sidebar />
 
      {user_info?.user_access?.includes(
        master_pages_access.view_all_masters
      ) ? (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="page-title-right d-flex">
                <PageName title={"Master Pages"}/>
                </div>
              </div>
              <Card>
                <CardBody
                  className={`${toggle_sidebar
                    ? "admission-card all-master-page"
                    : "admission-card close-left"
                    }`}
                  style={{ transition: "all .5s ease-in-out" }}
                >
                  {/* close-left */}
                  <div className="row">
                    <div className="setting-left-box left-box">
                      {toggle_sidebar && (
                        <div
                          className="tab-btn open-btn"
                          onClick={() => settoggle_sidebar(false)}
                        >
                          <i className="bx bx-chevrons-left"></i>
                        </div>
                      )}
                      {!toggle_sidebar && (
                        <div
                          className="tab-btn close-btn"
                          onClick={() => settoggle_sidebar(true)}
                        >
                          <i className="bx bx-chevrons-right"></i>
                        </div>
                      )}
 
                      <Nav
                        tabs
                        className="nav nav-pills"
                        style={{ overflowY: "scroll", height: "85vh" }}
                      >
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={subTabThree === "1" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("1");
                            }}
                          >
                            <span className="d-none d-sm-block hospital-name">
                              Location
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={subTabThree === "2" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("2");
                            }}
                          >
                            <span className="d-none d-sm-block hospital-name">
                              Patient Childhood Disease
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "3" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("3");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Patient Behavioural Conditions
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "4" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("4");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Patient Other Conditions
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "5" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("5");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Patient Blood Disease
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "6" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("6");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Patient Injuries
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "7" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("7");
                            }}
                          >
                            <span className="d-none d-sm-block">User Type</span>
                          </NavLink>
                        </NavItem>
 
                        <NavItem>
                          <NavLink
                            className={subTabThree === "9" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("9");
                            }}
                          >
                            <span className="d-none d-sm-block">Dietry Type</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "10" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("10");
                            }}
                          >
                            <span className="d-none d-sm-block">Discharge Type</span>
                          </NavLink>
                        </NavItem>
 
                        <NavItem>
                          <NavLink
                            className={subTabThree === "12" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("12");
                            }}
                          >
                            <span className="d-none d-sm-block">Document Type</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "13" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("13");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Investigation Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "16" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("16");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Investigation Sub Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "27" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("27");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Investigation Measurement Units
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "14" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("14");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Line Tube Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "15" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("15");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Past History type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "17" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("17");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Past History Sub Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "18" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("18");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              General Examination Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "19" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("19");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Systemic Examination Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "20" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("20");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Habit Type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "21" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("21");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Provisional Diagnosis type
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "22" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("22");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Medicines
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={subTabThree === "28" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("28");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Medicine Type
                            </span>
                          </NavLink>
                        </NavItem>
                        
                        {/* <NavItem>
                          <NavLink
                            className={subTabThree === "23" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("23");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Medicine Sub Type
                            </span>
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={subTabThree === "25" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("25");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Infusion Pumps
                            </span>
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={subTabThree === "24" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("24");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Doctor List
                            </span>
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={subTabThree === "26" ? "active" : ""}
                            onClick={() => {
                              subTabThreetoggle("26");
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Doctor Speciality List
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <div className="setting-right-box right-box">
                      <TabContent
                        activeTab={subTabThree}
                        className="text-muted"
                      >
                        {subTabThree === "1" && <TabPane tabId="1">
                          <Location />
                        </TabPane>
                        }
                        {subTabThree === "2" && <TabPane tabId="2">
                          <PatientChildhoodDiseases />
                        </TabPane>
                        }
                        {subTabThree === "3" && <TabPane tabId="3">
                          <PatientBehaviouralConditions />
                        </TabPane>
                        }
                        {subTabThree === "4" && <TabPane tabId="4">
                          <PatientOtherConditions />
                        </TabPane>
                        }
                        {subTabThree === "5" && <TabPane tabId="5">
                          <PatientBloodDiseases />
                        </TabPane>
                        }
                        {subTabThree === "6" && <TabPane tabId="6">
                          <PatientInjuries />
                        </TabPane>
                        }
                        {subTabThree === "7" && <TabPane tabId="7">
                          <UserTypes />
                        </TabPane>
                        }
                        {subTabThree === "9" && <TabPane tabId="9">
                          <Dietry />
                        </TabPane>
                        }
 
                        {subTabThree === "10" && <TabPane tabId="10">
                          <Discharge />
                        </TabPane>
                        }
 
                        {subTabThree === "12" && <TabPane tabId="12">
                          <Document />
                        </TabPane>
                        }
                        {subTabThree === "13" && <TabPane tabId="13">
                          <Investigation />
                        </TabPane>
                        }
                        {subTabThree === "14" && <TabPane tabId="14">
                          <LineTube />
                        </TabPane>
                        }
                        {subTabThree === "15" && <TabPane tabId="15">
                          <PastHistoryType />
                        </TabPane>
                        }
                        {subTabThree === "16" && <TabPane tabId="16">
                          <InvestigationSubType />
                        </TabPane>
                        }
                        {subTabThree === "17" && <TabPane tabId="17">
                          <PastHistorySubType />
                        </TabPane>
                        }
                        {subTabThree === "18" && <TabPane tabId="18">
                          <GeneralExaminationType />
                        </TabPane>
                        }
                        {subTabThree === "19" && <TabPane tabId="19">
                          <SystemicExaminationType />
                        </TabPane>
                        }
                        {subTabThree === "20" && <TabPane tabId="20">
                          <HabitType />
                        </TabPane>
                        }
                        {subTabThree === "21" && <TabPane tabId="21">
                          <ProvisionalDiagnosisType />
                        </TabPane>
                        }
                        {subTabThree === "22" && <TabPane tabId="22">
                          <Medicine />
                        </TabPane>
                        }
                        {/* <TabPane tabId="23">
                          <MedicineSubType />
                        </TabPane> */}
                        {/* {subTabThree === "24" && <TabPane tabId="24">
                          <Doctor />
                        </TabPane>
                        } */}
                        {subTabThree === "25" && <TabPane tabId="25">
                          <InfusionPumps />
                        </TabPane>
                        }
                        {subTabThree === "26" && <TabPane tabId="26">
                          <DoctorSpeciality />
                        </TabPane>
                        }
                        {subTabThree === "27" && <TabPane tabId="27">
                          <ReportMeasurementUnitsType />
                        </TabPane>
                        }
                        {subTabThree === "28" && <TabPane tabId="28">
                          <MedicineType />
                        </TabPane>
                        }
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <NoAccess text="Master pages access restricted" />
      )}
    </>
  );
};
 
export default Settings;