import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";
import { getPatientAdmissionClinicalnotesByDoctorId } from "../../redux/actions/patientAdmissionClinicalNotes";
import doctorMaleProfilePhoto from "../../assets/images/doctor.png";
import doctorFemaleProfilePhoto from "../../assets/images/female-doctor.png";
import { calculateExperienceYears, findTextInString } from "../../helpers/helperFunctions";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import { getDoctorSpecialityTypesList } from "../../redux/actions/doctorSpecialityAction";
import { getPatientAdmissionClinicalNotesByPatientAdmissionId } from "../../redux/actions/patientAdmissionClinicalNotes";
import { useLocation, useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import { getPatientAdmissionPrescriptionByPatientAdmissionId, getPatientAdmissionPrescriptonsByDoctorId } from "../../redux/actions/patientAdmissionPrescription"
import AccordionSection, { PatientTable1, TabFilter } from "../../components/DoctorProfileComponent/doctorprofilecom"
import PageName from "../../components/PageSubHeaderNameComponent/PageName";
export interface PatientData {
    patient_admission?: {
        patient?: {
            first_name?: string;
        };
        hospital_property?: HospitalProperty;
        provisionalDiagnoses?: { note: string }[];
        admission_by_doctor?: { mobile: string };
    };
    date?: string;
    assign_date?: string;
    is_read_by_refer_doctor?: boolean;
}

interface HospitalProperty {
    id?: string;
    landmark?: string | null;
}

interface ApiResponse {
    statusCode: number;
    body: {
        data: PatientData[];
    };
}

function getStartOfDayISO(date: string | number | Date) {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    const ISTOffset = 5.5 * 60 * 60 * 1000;
    const startOfDayInIST = new Date(startOfDay.getTime() + ISTOffset);
    return startOfDayInIST.toISOString();
}

interface DoctorProfilesProps {
    doctor?: any;
}

const DoctorProfiles: React.FC<DoctorProfilesProps> = ({ doctor }) => {
    const location = useLocation();
    const [subTabSecond, setsubTabSecond] = useState("1");
    const [patientsData, setPatientsData] = useState<PatientData[]>([]);
    const [search, set_search] = useState("");
    const [hospitalProperties, setHospitalProperties] = useState<HospitalProperty[]>([]);
    const [allSpeciality, setAllSpeciality] = useState<any[]>([]);
    const experienceYears = calculateExperienceYears(doctor?.service_start_date);
    const [isDoctorClinicalNoteOpen, setIsDoctorClinicalNoteOpen] = useState<boolean>(false);
    const [selectedHospitalPropertyId, setSelectedHospitalPropertyId] = useState<string>("");
    const [selected_date, setSelectedDate] = useState<string>(getStartOfDayISO(new Date()));
    const [allClinicalNoteData, setAllClinicalNoteData] = useState([]);
    const [patientId, setPatientId] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allPrescriptionData, setAllPrescriptionData] = useState([]);
    const [prescriptionDataByDoctor, setPrescriptionDataByDoctor] = useState<PatientData[]>([]);
    const [isDoctorPrescriptionNoteOpen, setIsDoctorPrescriptionNoteOpen] = useState<boolean>(false);
    const { patientTabs, currentPatientActiveTab } = useSelector((state: any) => state?.patientTab);
    const { user_info } = useSelector((state: any) => state.user);
    const [readFilter, setReadFilter] = useState<string>("");
    const [filterOption, setFilterOption] = useState<string>("all");
    const [collapsedItems, setCollapsedItems] = useState(
        allClinicalNoteData.map(() => true)
    );
    const [readNotesCount, setReadNotesCount] = useState(0);
    const [notReadNotesCount, setNotReadNotesCount] = useState(0);
    const [allNotesCount, setAllNotesCount] = useState(0);
    const [readPrescriptionsCount, setReadPrescriptionsCount] = useState(0);
    const [notReadPrescriptionsCount, setNotReadPrescriptionsCount] = useState(0);
    const [allPrescriptionsCount, setAllPrescriptionsCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const speciality = await getDoctorSpecialityTypesList({});
                if (speciality?.statusCode === 200) {
                    setAllSpeciality(speciality?.body?.data || []);
                } else {
                    setAllSpeciality([]);
                }
                const request: ApiResponse = await getPatientAdmissionClinicalnotesByDoctorId(doctor?.id);
                if (request?.statusCode === 200) {
                    const filteredData = request?.body?.data.filter((note: any) => note?.is_deleted !== true);
                    setPatientsData(filteredData);
                    const isViewingOwnProfile = user_info?.id === doctor?.id;
                    const userId = isViewingOwnProfile ? user_info?.id : doctor?.id;
                    const referNotes = filteredData.filter((note: any) => note.refer_doctor?.id === userId);
                    setReadNotesCount(referNotes.filter((note: any) => note.is_read_by_refer_doctor === true).length);
                    setNotReadNotesCount(referNotes.filter((note: any) => note.is_read_by_refer_doctor === false).length);
                    setAllNotesCount(referNotes.length);
                } else {
                    setPatientsData([]);
                    setReadNotesCount(0);
                    setNotReadNotesCount(0);
                    setAllNotesCount(0);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [doctor?.id]);
    useEffect(() => {
        const handlePatientSelectionPrescriptionByDoctor = async () => {
            try {
                const request = await getPatientAdmissionPrescriptonsByDoctorId(doctor?.id);
                if (request?.statusCode === 200) {
                    const filteredData = request?.body?.data.filter((note: any) => note?.is_deleted !== true)
                    setPrescriptionDataByDoctor(filteredData);
                    const isViewingOwnProfile = user_info?.id === doctor?.id;
                    const userId = isViewingOwnProfile ? user_info?.id : doctor?.id;
                    const referNotes = filteredData.filter((note: any) => note.refer_doctor?.id === userId);
                    setReadPrescriptionsCount(referNotes.filter((note: any) => note.is_read_by_refer_doctor === true).length);
                    setNotReadPrescriptionsCount(referNotes.filter((note: any) => note.is_read_by_refer_doctor === false).length);
                    setAllPrescriptionsCount(referNotes.length);
                } else {
                    setPrescriptionDataByDoctor([]);
                    setReadPrescriptionsCount(0);
                    setNotReadPrescriptionsCount(0);
                    setAllPrescriptionsCount(0);
                }
            } catch (error) {
                console.error('Error fetching prescription notes:', error);
            }
        };
        handlePatientSelectionPrescriptionByDoctor()
    }, [doctor?.id])

    const toggle = useCallback(
        (tab: string) => {
            if (currentPatientActiveTab !== tab) {
                if (location?.pathname !== "/patients") {
                    navigate("/patients");
                }
                dispatch({
                    type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
                    payload: { id: tab },
                });
            }
        },
        [currentPatientActiveTab, dispatch, location?.pathname, navigate]
    );
    const applyDateFilter = (data: any[]) => {
        if (!selected_date) return data;

        const selectedDateString = selected_date.split('T')[0];

        return data.filter((item: any) => {
            const assignDate = new Date(item?.assign_date || "");
            const isValidAssignDate = !isNaN(assignDate.getTime());
            const assignDateString = isValidAssignDate ? assignDate.toISOString().split('T')[0] : "";
            return assignDateString === selectedDateString;
        });
    };

    const applyReadFilter = (data: any[]) => {
        if (readFilter === "read") {
            return data.filter((note: any) => note?.is_read_by_refer_doctor === true);
        } else if (readFilter === "not_read") {
            return data.filter((note: any) => note?.is_read_by_refer_doctor === false);
        }
        else if (readFilter === "All") {
            return data;
        }
        return data;
    };


    const applyFilterOptions = (data: any[]) => {
        // Determine if the logged-in doctor is viewing their own profile or another doctor's profile
        const isViewingOwnProfile = user_info?.id === doctor?.id;
        const userId = isViewingOwnProfile ? user_info?.id : doctor?.id;

        if (filterOption === "my_notes") {
            if (subTabSecond === "1") {
                return data.filter((note: any) => note.doctor?.id === userId);
            } else if (subTabSecond === "2") {
                return data.filter((prescription: any) => prescription.prescribed_by?.id === userId);
            }
        } else if (filterOption === "refer_notes") {
            return data.filter((note: any) => note.refer_doctor?.id === userId);
        }
        return data;
    };


    const filtered_patients_clinicalnote = useMemo(() => {
        let filteredData = patientsData;

        // Apply filters based on option and read status
        filteredData = applyFilterOptions(filteredData);
        filteredData = applyReadFilter(filteredData);

        // Apply date filter only for specific options
        if (filterOption !== "all") {
            filteredData = applyDateFilter(filteredData);
        }
        // Final filtering based on search and hospital property
        return filteredData.filter((patient) => {
            return (
                findTextInString(patient?.patient_admission?.patient?.first_name || '', search) &&
                (selectedHospitalPropertyId === "" || patient?.patient_admission?.hospital_property?.id === selectedHospitalPropertyId) &&
                (filterOption === "all" || selected_date ? patient?.assign_date?.split('T')[0] === selected_date.split('T')[0] : true)
            );
        });
    }, [search, patientsData, selectedHospitalPropertyId, selected_date, filterOption, user_info?.id, readFilter]);
    useEffect(() => {
        if (filterOption === "refer_notes") {
            setSelectedDate("");
        }
        else if (filterOption === "my_notes" || filterOption === "all") {
            setSelectedDate(getStartOfDayISO(new Date()))
        }
        if (filterOption === "refer_notes") {
            setReadFilter("All");
        }
    }, [filterOption]);


    const filteredPrescriptions = useMemo(() => {
        let filteredData = prescriptionDataByDoctor

        // Apply filters based on option and read status
        filteredData = applyFilterOptions(filteredData);
        filteredData = applyReadFilter(filteredData);

        // Apply date filter only for specific options
        if (filterOption !== "all") {
            filteredData = applyDateFilter(filteredData);
        }
        // Final filtering based on search and hospital property
        return filteredData.filter((patient) => {
            return (
                findTextInString(patient?.patient_admission?.patient?.first_name || '', search) &&
                (selectedHospitalPropertyId === "" || patient?.patient_admission?.hospital_property?.id === selectedHospitalPropertyId) &&
                (filterOption === "all" || selected_date ? patient?.assign_date?.split('T')[0] === selected_date.split('T')[0] : true)
            );
        });
    }, [search, prescriptionDataByDoctor, selectedHospitalPropertyId, selected_date, filterOption, user_info?.id, readFilter]);

    useEffect(() => {
        const allData = subTabSecond === "1" ? patientsData : prescriptionDataByDoctor;
        const extractedHospitalProperties = allData
            .reduce((uniqueProperties: HospitalProperty[], item) => {
                const hospitalProperty = item?.patient_admission?.hospital_property;
                if (hospitalProperty && !uniqueProperties.some(prop => prop?.id === hospitalProperty?.id)) {
                    uniqueProperties.push(hospitalProperty);
                }
                return uniqueProperties;
            }, []);
        setHospitalProperties(extractedHospitalProperties);
    }, [subTabSecond, patientsData, prescriptionDataByDoctor]);
    const toggleCollapse = (index: any) => {
        setCollapsedItems((prev) => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const userSepeciality = () => {
        let string = "";
        if (doctor && doctor?.specialist?.length > 0) {
            doctor?.specialist?.map((specialist: string, index: number) => {
                const speciality: any = allSpeciality?.find((item: any) => item?.id === specialist)
                if (speciality) {
                    if (index > 0) {
                        string += ", "
                    }
                    string += speciality?.name;
                }
            });
        }
        return string;
    }

    const handleAdmissionDateChange = (selectedDate: string) => {
        setSelectedDate(getStartOfDayISO(selectedDate));
    };

    const add_patient_tab = useCallback((tab: { id: string, name: string }) => {
        const isTabOpen = patientTabs.some((patientTab: { id: string }) => patientTab.id === tab.id);

        if (!isTabOpen) {
            dispatch({ type: ActionTypes.ADD_Patient_TAB, payload: tab });
        }

        dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab });
    }, [dispatch, patientTabs]);

    const handleNavigatePatient = (patient_admission: any) => {
        navigate("/patients");
        toggle(patient_admission?.patient?.id);
        const tab_info = {
            id: patient_admission?.patient?.id,
            name: `${patient_admission?.patient?.first_name} ${patient_admission?.patient?.middle_name} ${patient_admission?.patient?.last_name}`,
        };
        add_patient_tab(tab_info);
        dispatch({
            type: ActionTypes.SET_Patient_TAB,
            payload: tab_info,
        });
        dispatch({
            type: ActionTypes.SET_Main_Patient_TAB,
            payload: {
                patientMainTab: "1",
            },
        });
    }

    const subTabSecondtoggle = (tab: string) => {
        if (subTabSecond !== tab) setsubTabSecond(tab);
    };

    const handlePatientSelection = async (patientAdmissionId: string) => {
        setPatientId(patientAdmissionId);
        setIsDoctorClinicalNoteOpen(true);

        try {
            const request = await getPatientAdmissionClinicalNotesByPatientAdmissionId(patientAdmissionId);
            if (request.statusCode === 200) {
                setAllClinicalNoteData(request?.body?.data);
            } else {
                setAllClinicalNoteData([]);
            }
        } catch (error) {
            console.error('Error fetching clinical notes:', error);
            setAllClinicalNoteData([]);
        }
    };
    const handlePatientSelectionPrescription = async (patientAdmissionId: string) => {
        setPatientId(patientAdmissionId);
        setIsDoctorPrescriptionNoteOpen(true);

        try {
            const request = await getPatientAdmissionPrescriptionByPatientAdmissionId(patientAdmissionId);
            if (request.statusCode === 200) {
                setAllPrescriptionData(request?.body?.data);
            } else {
                setAllPrescriptionData([]);
            }
        } catch (error) {
            console.error('Error fetching prescription notes:', error);
            setAllPrescriptionData([]);
        }
    };
    return (
        <>
            <Header HeaderName="Doctor Profile" />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <PageName title="Doctor Profile" />
                        <div className="row">
                            <div className="doctor-profile-details">
                                <div className="doctor-img-box">
                                    <img src={doctor?.gender === "male" ? doctorMaleProfilePhoto : doctorFemaleProfilePhoto} alt="" />
                                </div>
                                <div className="doctor-main-details-box">
                                    <div className="name-box">
                                        <h4>{doctor?.name || " - "}</h4>
                                        {doctor?.specialist?.length > 0 && <span>{userSepeciality()}</span>}
                                    </div>
                                    <div className="specialities-box">
                                    </div>
                                    <div className="details-box">
                                        <div className="common-box">
                                            <span>Reg No.</span>
                                            <p>{doctor?.reg_number || " - "}</p>
                                        </div>
                                        <div className="common-box">
                                            <span>Experience</span>
                                            <p>{experienceYears !== null ? `${experienceYears} Year${experienceYears !== 1 ? 's' : ''}` : 'Select a date'}</p>
                                        </div>
                                        <div className="common-box">
                                            <span>Degree</span>
                                            <p>{doctor?.degree || " - "} </p>
                                        </div>
                                        <div className="common-box">
                                            <span>Mobile Number</span>
                                            <p>{doctor?.mobile || " - "} </p>
                                        </div>
                                        <div className="common-box">
                                            <span>Email ID</span>
                                            <p>{doctor?.email || " - "} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-title-box d-sm-flex doctorlist-filters align-items-center justify-content-between">
                            <div className="page-title-right d-flex">
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            className={subTabSecond === "1" ? "active" : ""}
                                            onClick={() => {
                                                subTabSecondtoggle("1");
                                            }}
                                        >
                                            <span className="d-none d-sm-block">Clinical Note</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={subTabSecond === "2" ? "active" : ""}
                                            onClick={() => {
                                                subTabSecondtoggle("2");
                                            }}
                                        >
                                            <span className="d-none d-sm-block">Prescription</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div className="filter-btn-group mb-2">
                                <select
                                    className="form-control"
                                    value={filterOption}
                                    onChange={(e) => setFilterOption(e.target.value)}
                                >
                                    <option value="all">All notes</option>
                                    <option value="my_notes">My notes</option>
                                    <option value="refer_notes">Refer notes</option>
                                </select>
                            </div>
                            <>
                                {subTabSecond === "1" && filterOption === "refer_notes" && (
                                    <TabFilter readFilter={readFilter} setReadFilter={setReadFilter} allCount={allNotesCount}
                                        readCount={readNotesCount}
                                        notReadCount={notReadNotesCount} />
                                )}
                                {subTabSecond === "2" && filterOption === "refer_notes" && (
                                    <TabFilter readFilter={readFilter} setReadFilter={setReadFilter} allCount={allPrescriptionsCount}
                                        readCount={readPrescriptionsCount}
                                        notReadCount={notReadPrescriptionsCount} />
                                )}
                            </>
                            <div className="doctor-date">
                                <CustomMUIDatePicker
                                    name="selected_date"
                                    type="simple"
                                    setState={handleAdmissionDateChange}
                                    value={selected_date}
                                />
                            </div>
                        </div>
                        <div className="row doctor-filter-row">
                            <div className="page-title-box align-items-center">
                                <label>Select Hospital Property</label>
                                <select
                                    className="form-control"
                                    onChange={(e) => setSelectedHospitalPropertyId(e.target.value)}
                                >
                                    <option value="">All</option>
                                    {hospitalProperties?.map((hospital, index) => (
                                        <option key={index} value={hospital?.id}>
                                            {hospital?.landmark}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="page-title-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Patient"
                                    onChange={(e) => set_search(e?.target?.value)}
                                />
                            </div>
                        </div>
                        {subTabSecond === "1" && (
                            <PatientTable1
                                data={filtered_patients_clinicalnote}
                                handlePatientSelection={handlePatientSelection}
                                handleNavigatePatient={handleNavigatePatient}
                                type="clinical_note"
                            />
                        )}

                        {subTabSecond === "2" && (
                            <PatientTable1
                                data={filteredPrescriptions}
                                handlePatientSelection={handlePatientSelectionPrescription}
                                handleNavigatePatient={handleNavigatePatient}
                                type="prescription"
                            />
                        )}
                        <>
                            <AccordionSection
                                isOpen={isDoctorPrescriptionNoteOpen}
                                setIsOpen={setIsDoctorPrescriptionNoteOpen}
                                data={allPrescriptionData || []}
                                toggleCollapse={toggleCollapse}
                                collapsedItems={collapsedItems}
                                type="prescription"
                            />

                            <AccordionSection
                                isOpen={isDoctorClinicalNoteOpen}
                                setIsOpen={setIsDoctorClinicalNoteOpen}
                                data={allClinicalNoteData || []}
                                toggleCollapse={toggleCollapse}
                                collapsedItems={collapsedItems}
                                type="clinical_note"
                            />
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DoctorProfiles;
