import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";

interface ConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  pageName: string;
  onConfirm?: () => void;
  message: string;
  children?: React.ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  toggle,
  pageName,
  onConfirm,
  message,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false} centered>
      <ModalHeader toggle={toggle}>{pageName}</ModalHeader>
      <ModalBody>
        <p>{message}</p>
        {children && <div>{children}</div>}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Row>
          {onConfirm && (
            <><Col className="text-center">
              <Button className="medium-btn" color="danger" onClick={onConfirm}>
                Yes
              </Button>
            </Col>
              <Col className="text-center">
                <Button className="medium-btn" color="secondary" onClick={toggle}>
                  No
                </Button>
              </Col>
            </>
          )}
          {!onConfirm && (
            <Col className="text-center">
              <Button className="medium-btn" color="secondary" onClick={toggle}>
                Close
              </Button>
            </Col>
          )}
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
