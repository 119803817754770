import React, { useState, useEffect, useCallback } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import {useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/hospitalTabsTypes";

const HospitalSubHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );
  const dispatch: any = useDispatch();
  const { HospitalTabs, currentActiveTab } = useSelector(
    (state: any) => state.hospitalTab
  );

  const toggle = useCallback(
    (tab: string) => {
      if (currentActiveTab !== tab) {
        if (location?.pathname !== "/hospitals") {
          navigate("/hospitals");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_ACTIVE_TAB,
          payload: {
            id: tab,
          },
        });
      }
    },
    [currentActiveTab, dispatch, location?.pathname, navigate]
  );

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  const remove_hospital_tab = useCallback(
    (tab: any) => {
      dispatch({
        type: ActionTypes.REMOVE_HOSPITAL_TAB,
        payload: tab,
      });
      if (tab?.id === currentActiveTab) {
        toggle("1");
      }
    },
    [toggle, dispatch, currentActiveTab]
  );

  return (
    <>
      <div className="patient-open-tab">
        <Nav tabs className="secondary-tab">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={currentActiveTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            <span className="d-none d-sm-block hospital-name">
              All Hospitals
            </span>
          </NavLink>
        </NavItem>
          {HospitalTabs?.length > 0 &&
            HospitalTabs?.map((tab: any) => {
              return (
                <NavItem key={tab?.id}>
                  <NavLink
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    className={currentActiveTab === tab?.id ? "active" : ""}
                  >
                    <span
                      className="d-none d-sm-block hospital-name"
                      style={{
                        color: currentActiveTab === tab?.id ? "#3D79F7" : "#fff",
                      }}
                      onClick={() => {
                        dispatch({
                          type: ActionTypes.SET_HOSPITAL_TAB,
                          payload: tab,
                        });
                        toggle(tab?.id);
                      }}
                    >
                      {tab.name}{" "}
                    </span>{" "}
                    <i
                      style={{
                        color: currentActiveTab === tab?.id ? "#3D79F7" : "#fff",
                      }}
                      className="mdi mdi-close"
                      onClick={() => remove_hospital_tab(tab)}
                    ></i>
                  </NavLink>
                </NavItem>
              );
            })}
        </Nav>
      </div>
    </>
  );
};

export default HospitalSubHeader;
