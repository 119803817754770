import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Tooltip,
} from '@mui/material';
import Header from '../Header/Header';
import { useSelector } from 'react-redux';
import { useNotifications } from './notificationContainer';
import SvgFilesIcon from '../SvgFiles/SvgFiles';

const NotificationsList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { user_info } = useSelector((state: any) => state.user);
  const { notifications, totalCount, fetchNotifications, markAllAsRead } = useNotifications();

  useEffect(() => {
    fetchNotifications(page + 1, rowsPerPage);
  }, [user_info?.hospital_property?.id, page, rowsPerPage]);

  const handleMarkAllAsRead = async () => {
    await markAllAsRead();
  };

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const hasUnreadNotifications = useMemo(() => {
    return notifications?.some(notification => !notification?.is_read);
  }, [notifications]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || "";
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
  };

  const getNotificationString = (notification: any) => {
    const { date: createdDate, time: createdTime } = formatDateTime(notification.created_on);
    let message;

    if (notification.notification_type === 'Update_Clinical_Note') {
      const { note, doctor_refer_note, notification_date } = notification.clinical_note || {};
      const { date: noteDate, time: noteTime } = formatDateTime(notification_date);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had clinical notes updated: {stripHtmlTags(note)} on <b>{noteDate}</b> at <b>{noteTime}</b>.
          {doctor_refer_note && (
            <span> After that, the refer doctor modified the doctor refer note: {stripHtmlTags(doctor_refer_note)}.</span>
          )}
        </p>
      );
    } else if (notification.notification_type === 'Created_Clinical_Notes') {
      const { note, notification_date } = notification.clinical_note || {};
      const { date: noteDate, time: noteTime } = formatDateTime(notification_date);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had clinical notes: {stripHtmlTags(note)}  on  <b>{noteDate}</b> at <b>{noteTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Prescriptions') {
      const { notification_text, priscription_note } = notification;
      const contentName = priscription_note?.medicine_content?.content_name || "Unknown medicine";

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had a prescription: {notification_text} for {contentName} on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Update_Instructions') {
      const { date: instructionDate, time: instructionTime } = formatDateTime(notification.instruction?.notification_date);
      const instructionDescription = stripHtmlTags(notification.instruction?.instruction_description);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had an instruction update: {instructionDescription} on <b>{instructionDate}</b> at <b>{instructionTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Create_Instructions') {
      const { date: instructionDate, time: instructionTime } = formatDateTime(notification.instruction?.notification_date);
      const instructionDescription = stripHtmlTags(notification.instruction?.instruction_description);

      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has received an instruction: {instructionDescription}on <b>{instructionDate}</b> at <b>{instructionTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Update_Critical') {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b>  has had a patient admission details: The condition is critical, updated on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else if (notification.notification_type === 'Create_Critical') {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had a patient admission with details: The condition is critical as of <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    } else {
      message = (
        <p>
          <b>{notification?.patient_admission?.patient?.first_name} {notification?.patient_admission?.patient?.last_name}</b> has had a {notification?.notification_text?.toLowerCase()} on <b>{createdDate}</b> at <b>{createdTime}</b>.
        </p>
      );
    }

    return (
      <Typography variant="body1" component="span">
        <div className="d-sm-flex align-items-center justify-content-between">
          {message}
          {!notification.is_read && (
            <Tooltip title="Unread message" arrow>
              <i className="mdi mdi-email-lock"></i>
            </Tooltip>
          )}
        </div>
      </Typography>
    );
  };
  return (
    <><Header HeaderName="Notifications" /><div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className='notificationbar-size'>
            <div className='d-sm-flex justify-content-between'>
              <Typography variant="h5" gutterBottom>
                Notifications List
              </Typography>
              <Button variant="contained" color="primary" onClick={handleMarkAllAsRead} disabled={!hasUnreadNotifications}>
                Mark All as Read
              </Button>
            </div>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableBody>
                  {notifications?.length > 0 ? (
                    notifications.map((notification) => (
                      <TableRow key={notification.id}>
                        <TableCell>
                          <Card variant="outlined">
                            <CardContent>
                              {getNotificationString(notification)}
                            </CardContent>
                          </Card>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={1} align="center">
                      <SvgFilesIcon iconname={"nodatafound"} />                     
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {notifications?.length > 0 && ( 
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
          </div>
        </div>
      </div>
    </div></>
  );
};

export default NotificationsList;