import ReactApexChart from "react-apexcharts"
import { getChartColorsArray } from "../../../helpers/helperFunctions";

const DashedLinenew = ({ dataColors, ageDistribution }) => {
  const dashedLineChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Session Duration",
      // data: [97, 98, 101, 97, 98],
      data: ageDistribution,
    }
  ];

  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: dashedLineChartColors,
    dataLabels: { enabled: !1 },
    stroke: { width: [3, 5, 3], curve: "straight", dashArray: [5, 2, 3] },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: { categories: ["01", "02", "03", "04", "05"] },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e + " (mins)"
            },
          },
        },
      ],
    },
    grid: { borderColor: "#f1f1f1" },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="550"
      className="apex-charts"
    />
  )
}

export default DashedLinenew;