import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { userReducer } from "./reducers/userReducer";
import { countryReducer } from "./reducers/countryReducer";
import { hospitalTabReducer } from "./reducers/hospitalTabsReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { patientTabReducer } from "./reducers/patientTabReducer";
import reportHeaderReducer from "./reducers/reportTabsReducer";

const rootReducers = combineReducers({
  reportHeader: reportHeaderReducer,
  user: userReducer,
  country: countryReducer,
  hospitalTab: hospitalTabReducer,
  patientTab: patientTabReducer
});
 
const persistConfig = {
  key: "root",
  storage,
};
 
const persistedReducer = persistReducer(persistConfig, rootReducers);
 
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
 
export const persistor = persistStore(store);
 
export type RootState = ReturnType<typeof rootReducers>;
 
export const useTypeSelector: TypedUseSelectorHook<RootState> = useSelector;
 
 