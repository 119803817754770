import React, { useCallback, useEffect, useState } from "react";
import { CardTitle, Card, CardBody, Label, Input, Form } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { getAdmissionById, putPatientAdmission, updatePatient } from "../../../redux/actions/patientAction";
import { getDischargeTypesList } from "../../../redux/actions/dischargeAction";
import { formatDateToLocaleDateString } from "../../../helpers/helperFunctions";
import { getDoctorListByRole } from "../../../redux/actions/doctorAction";
import { IDoctorList } from "../../../redux/types/patientAdmissionInstructionsTypes";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import { useSelector } from "react-redux";
 
const validationSchema = (typeOfDischarge: string) => {
    if (typeOfDischarge === "Other") {
        return Yup.object().shape({
            type_of_discharge: Yup.string().required("Type of Discharge is required"),
            date: Yup.date()
            .required("Date is required")
            .nullable(),
            discharged_by_doctor: Yup.string().required("Doctor Name is required"),
            advise_on_discharge: Yup.string().required("Advise on Discharge is required"),
            other_discharge_reason: Yup.string().required("Discharge Reason is required"),
        });
    } else {
        return Yup.object().shape({
            type_of_discharge: Yup.string().required("Type of Discharge is required"),
            date: Yup.date()
            .required("Date is required")
            .nullable(),
            discharged_by_doctor: Yup.string().required("Doctor Name is required"),
            advise_on_discharge: Yup.string().required("Advise on Discharge is required"),
            other_discharge_reason: Yup.string(),
        });
    }
};
 
const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const localTime = new Date(now.getTime() - offset).toISOString().slice(0, 16);
    return localTime;
};
 
 const PatientsDischargeInfo: React.FC<any> = ({ patient, selected_admission, setselected_admission, fetchPatientData,isReadonly}) => {
    const [page_number, setPage_number] = useState(1);
    const [per_page, setPer_page] = useState(100);
    const [update_enabled, setupdate_enabled] = useState(false);
    const [allDischargeTypes, setallDischargeTypes] = useState<any[]>([]);
    const [doctorTypes, setDoctorTypes] = useState<IDoctorList[]>([]);
    const admissionDate = selected_admission?.admission_date;
    const { user_info } = useSelector((state: any) => state?.user);
    const formik = useFormik({
        initialValues: {
            type_of_discharge: "",
            date: getCurrentDateTime(),
            discharged_by_doctor: "",
            advise_on_discharge: "",
            other_discharge_reason: "",
        },
        validationSchema: () => validationSchema(formik.values.type_of_discharge),
        onSubmit: async (values, { resetForm }) => {
            if (selected_admission?.id) {
                await putPatientAdmission({
                    id: selected_admission?.id,
                    discharge_date_entry: values.date ? values.date : new Date().toJSON(),
                    discharged_by_doctor: values?.discharged_by_doctor,
                    admitted: false,
                    typeof_discharge: values?.type_of_discharge,
                    advise_on_discharge: values?.advise_on_discharge,
                    other_discharge_reason: values?.other_discharge_reason,
                    discharged: true
                });
 
                await updatePatient({
                    id: patient?.id,
                    admitted: false,
                    discharge: true,
                    discharge_date: values.date ? values.date : new Date().toJSON(),
                });
                SuccessToast("Patient Discharge Successfully");
                setupdate_enabled(false);
                fetchPatientData();
                resetForm();
                await refresh_selected_admission();
            } else {
                ErrorToast("Patient is already Discharge")
            }
            setupdate_enabled(false)
        },
    });
 
    const refresh_selected_admission = useCallback(async () => {
        let request = await getAdmissionById(selected_admission?.id);
        if (request?.statusCode === 200) {
            setselected_admission(request?.body);
        } else {
            setselected_admission(selected_admission);
        }
    }, [selected_admission, setselected_admission]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { body } = await getDoctorListByRole({role : "doctor",hospital_id:user_info?.hospital_property?.hospital?.id});
                if (body) setDoctorTypes(body?.data);
                else setDoctorTypes([]);
                const dischargeType = await getDischargeTypesList({
                    per_page: per_page,
                    page_number: page_number,
                });
                if (dischargeType?.statusCode === 200 && Array.isArray(dischargeType?.body?.data)) {
                    setallDischargeTypes(dischargeType.body.data);
                } else {
                    setallDischargeTypes([]);
                }
            } catch (error) {
                console.error("Error fetching doctor types:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <div style={{ height: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
            <Form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
            }}><Card>
                    <CardBody>
                        {(!update_enabled && !patient?.discharge ) && (
                            <><CardTitle className="h4 hospital-card-title text-primary">
                                Discharge Info
                            </CardTitle>
                                {selected_admission?.discharged === false && (
                                    <div className="add-row-btn mt-2 ">
                                        <button
                                            className="btn btn-primary mb-2"
                                            onClick={() => setupdate_enabled(true)}
                                            type="button"
                                            // disabled={isReadonly}
                                        >
                                            Discharge
                                        </button>
                                    </div>)}
                            </>
                        )}
                        {(!update_enabled && !selected_admission?.discharged) && (
                            <div className="d-flex justify-content-center">
                                <h4 className="mt-20 b-5">No Data Found</h4>
                            </div>
                        )}
 
                        {!update_enabled && selected_admission?.discharged && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="discharge-title">
                                        <div className="inline-box">
                                            <label>Discharge Type : {selected_admission?.typeof_discharge?.name}</label>
                                        </div>
                                        <div className="inline-box">
                                            <label>Date : {formatDateToLocaleDateString(selected_admission?.discharge_date_entry)}</label>
                                        </div>
                                        <div className="inline-box">
                                            <label>Doctor : {selected_admission?.discharged_by_doctor?.name}</label>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label>Advise on Discharge : </label>
                                        {/* <p>{selected_admission?.advise_on_discharge}</p> */}
                                        <p className="deatil richtext-p-tag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selected_admission?.advise_on_discharge) }}></p>
                                    </div>
                                </div>
                            </div>
                        )}
 
                        {(update_enabled) && (
                            <div>
                                <CardTitle className="h4">Discharge Info</CardTitle>
                                <div className="right-panel">
                                    <h4>Discharge Info</h4>
                                    <button className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light " onClick={() => {
                                        formik.handleReset(null);
                                        setupdate_enabled(false);
                                    }}>
                                        <i className="mdi mdi-close"></i>
                                    </button>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <Label className="form-label">Type of Discharge</Label>
                                                <Input
                                                    type="select"
                                                    name="type_of_discharge"
                                                    value={selected_admission?.selected_admission || formik.values.type_of_discharge}
                                                    defaultValue={selected_admission?.selected_admission}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option value="">Select Option</option>
                                                    {allDischargeTypes.map((discharge: any) => {
                                                        return <option value={discharge.id}>{discharge.name}</option>
                                                    })}
                                                    <option value="Other">Other</option>
                                                </Input>
                                                <div className="text-danger">
                                                    {formik.touched.type_of_discharge && formik.errors.type_of_discharge}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Render other discharge reason only when "Other" option is selected */}
                                        {formik.values.type_of_discharge === "Other" && (
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <Label className="form-label">Other Discharge Reason</Label>
                                                    <Input
                                                        type="text"
                                                        name="other_discharge_reason" // Changed the name
                                                        placeholder="Enter Other Discharge Reason"
                                                        value={formik.values.other_discharge_reason} // Updated value and onchange
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur} />
                                                    <div className="text-danger">
                                                        {formik.touched.other_discharge_reason && formik.errors.other_discharge_reason}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
 
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <Label className="form-label">Discharge Date</Label>
                                                <CustomMUIDatePicker
                                                    name="date"
                                                    value={formik.values?.date}
                                                    minDate={new Date(admissionDate)}
                                                    setState={formik.setFieldValue}
                                                    sx={{
                                                        ".MuiOutlinedInput-input": {
                                                            color: "#fff",
                                                            height: "4px",
                                                            fontWeight: 400,
                                                            fontSize: "0.8125rem"
                                                        }
                                                    }}
                                                    useDateTimePicker
                                                />
                                                {formik.touched.date && formik.errors.date && (
                                                    <div className="text-danger">{formik.errors.date}</div>
                                                )}                                           
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Label for="doctor">Doctor Name</Label>
                                            <Input
                                                type="select"
                                                name="discharged_by_doctor"
                                                id="discharged_by_doctor"
                                                value={selected_admission?.discharged_by_doctor || formik.values.discharged_by_doctor}
                                                onChange={formik.handleChange}
                                            >
                                                <option value="">Select a Doctor Name</option>
                                                {doctorTypes.map(doctor => (
                                                    <option key={doctor.id} value={doctor.id}>{doctor.name}</option>
                                                ))}
                                            </Input>
                                            <div className="text-danger">
                                                {formik.touched.discharged_by_doctor && formik.errors.discharged_by_doctor}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <Label className="form-label">Advise on Discharge</Label>
                                                <ReactQuill
                                                    id="advise_on_discharge"
                                                    value={selected_admission?.advise_on_discharge || formik.values.advise_on_discharge}
                                                    onChange={(content, delta, source, editor) => {
                                                        // Update Formik field value on change
                                                        formik.handleChange("advise_on_discharge")(content);
                                                    }}
                                                />
 
                                                <div className="text-danger">
                                                    {formik.touched.advise_on_discharge && formik.errors.advise_on_discharge}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary btn-block mr-2">
                                                Save
                                            </button>
                                            {formik.values.type_of_discharge.length > 0 ? (
                                                <button className="btn btn-secondary mr-2" type="button" onClick={formik.handleReset}>
                                                    Reset
                                                </button>
                                            ) : (
                                                <button className="btn btn-secondary mr-2" type="button" onClick={() => {
                                                    formik.handleReset(null);
                                                    setupdate_enabled(false);
                                                }}>
                                                    Cancel
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Form>
        </div>
    );
};
 
export default PatientsDischargeInfo;
 