import axiosApi from "../../api/apiHelper";
import { SERVER_URL, user_routes } from "../api_path";
import { ActionTypes } from "../types/hospitalTabsTypes";
import { ActionTypes as PatientActionTypes } from "../types/patientTabTypes";
import { IGetUsersParams, IRegister } from "../types/userTypes";
 
export interface LoginUserParams {
  email: string;
  password: string;
}
 
export const login_user_by_email_service = async (
  login_user_params: LoginUserParams
) => {
  try {
    let { data } = await axiosApi.post(`${SERVER_URL}${user_routes.login_user_by_email}`,login_user_params);
 
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const send_verification_email = async (email: string) => {
  try {
    const { data } = await axiosApi.put(`${SERVER_URL}${user_routes.send_verification_email}`, { email });
    return data;
  } catch (error: any) {
    return error.response?.data;
  }
};
 
export const verify_otp = async (email: string, otp: string) => {
  try {
    const { data } = await axiosApi.put(`${SERVER_URL}${user_routes.verify_otp}`, { email, otp });
    return data;
  } catch (error: any) {
    // If the OTP is invalid, throw an error with the response message
    if (error.response?.status === 400) {
      throw new Error(error.response.data.message || "Invalid OTP");
    }
    throw new Error("An unexpected error occurred");
  }
};
 
export const login_user_by_phone_number = async (
  mobile_number: string,
  mobile_login_otp: string
) => {
  try {
    let { data } = await axiosApi.post(
      `${SERVER_URL}${user_routes.login_user_by_phone_number}`,
      { mobile_number, mobile_login_otp }
    );
 
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
 
 
export const register_user = async (values: IRegister) => {
  try {
    const { data } = await axiosApi.post(user_routes.register, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const update_user = async (values: IRegister) => {
  try {
    const { data } = await axiosApi.put(user_routes.update_user, values);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const login_log = async ({
  device,
  country,
  city,
  ip_address,
  user_id,
}: {
  device: string;
  country: string;
  city: string;
  ip_address: string;
  user_id: string;
}) => {
  try {
    let { data } = await axiosApi.post(
      `${SERVER_URL}${user_routes.create_login_log}`,
      { device, country, city, ip_address, user_id }
    );
 
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const update_user_web_token = async ({
  web_token,
}: {
  web_token: any;
}) => {
  try {
    let { data } = await axiosApi.put(
      `${SERVER_URL}${user_routes.update_user_web_token}`,
      { web_token }
    );
 
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const save_token = (user: any, token: string) => (dispatch: any) => {
  dispatch({
    type: ActionTypes.LOGIN_SUCCESS,
    payload: {
      user,
      token,
    },
  });
};
 
export const fetch_user_ip_address = async () => {
  try {
    let { data } = await axiosApi.get("http://ip-api.com/json/?fields=61439");
 
    return data;
  } catch (error: any) {
    return error;
  }
};
 
export const fetch_all_users = async ({
  page_number,
  per_page,
  start_date,
  end_date,
  sort_order,
}: IGetUsersParams) => {
  try {
    const params: { [key: string]: number | string } = {};
 
    if (page_number !== undefined) params["page_number"] = page_number;
    if (per_page !== undefined) params["per_page"] = per_page;
    if (start_date !== undefined && start_date.trim() !== "")
      params["start_date"] = start_date;
    if (end_date !== undefined && end_date.trim() !== "")
      params["end_date"] = end_date;
    if (sort_order !== undefined && sort_order.trim() !== "")
      params["sort_order"] = sort_order;
 
    const { data } = await axiosApi.get(user_routes.all_users, { params });
    return data;
  } catch (error: any) {
    return error?.response?.data?.errors;
  }
};
 
export const delete_user = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(`${user_routes.delete_user}/${id}`);
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const forgot_password_email = async (email: string) => {
  try {
    const { data } = await axiosApi.put(
      `${user_routes.forgot_password_email}`,
      { email }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const login_mobile_otp = async (mobile_number: string) => {
  try {
    const { data } = await axiosApi.put(`${user_routes.login_mobile_otp}`, {
      mobile_number,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const forgot_password_code_check = async (
  email: string,
  forgot_password_code: string
) => {
  try {
    const { data } = await axiosApi.put(
      `${user_routes.forgot_password_code_check}`,
      { email, forgot_password_code }
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
 
export const reset_password = async (
  email: string,
  forgot_password_code: string,
  password: string,
  confirm_password: string
) => {
  try {
    const { data } = await axiosApi.put(`${user_routes.reset_password}`, {
      email,
      forgot_password_code,
      password,
      confirm_password,
    });
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
export const logoutUser = () => (dispatch: any) => {
  dispatch({ type: ActionTypes.LOGOUT_SUCCESS });
  dispatch({ type: ActionTypes.NULL_HOSPITAL_TABS });
  dispatch({ type: PatientActionTypes.NULL_PATIENT_TABS });
}
 