import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { GridActionsCellItem, GridColDef, GridRowModel, GridSearchIcon } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import SaveIcon from "@mui/icons-material/Save";
import { deleteState, getStateList, postState, updateState } from "../../../redux/actions/stateActions";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { getCountryList } from "../../../redux/actions/countryAction";
import { InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { findTextInString } from "../../../helpers/helperFunctions";
import { Country } from "../../../redux/types/countryTypes";
import { IDeleteStateParams, IStatesProps, IUpdateStateParams } from "../../../redux/types/stateTypes";
import DeleteConfirmationModal from "../../DeletePopupComponent/DeletePopup";

const States = ({ isActiveTab }: IStatesProps) => {
  const initialRows = useMemo(() => [{ id: "1", country: { id: "", country: "" }, state: "" }], []);
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<any[]>(initialRows);
  const [page_number, setPage_number] = useState(1);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const [per_page, setPer_page] = useState(0);
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [searchText, setSearchText] = useState("");
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [{ id: "1", country: { id: "", country: " " }, state: "" }, ...prevRows]);
  };

  useEffect(() => {
    async function fetchCountryData() {
      const { body } = await getCountryList({});
      const updatedCountryList: Country[] = body?.data?.map(
        (e: any) => ({ country: e?.country, id: e?.id })
      );
      setCountryOptions(updatedCountryList);
    }
    async function fetchStateData() {
      const { body } = await getStateList({ per_page: per_page, page_number: page_number });
      if (body) setRows(body?.data);
      else setRows([]);
    }
    if (isActiveTab) {
      fetchCountryData();
      fetchStateData();
    }
  }, [isActiveTab, page_number, per_page]);

  // const duplicateUser = useCallback(
  //   (id: GridRowId) => () => {
  //     setRows((prevRows) => {
  //       const rowToDuplicate = prevRows.find((row) => row.id === id)!;
  //       return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
  //     });
  //   },
  //   []
  // );

  const handleUpdateState = useCallback(
    async ({ id, state, country_id }: IUpdateStateParams) => {
      try {
        if (!country_id) return ErrorToast("Country is required field!");
        if (state.trim() === "") return ErrorToast("State is required field!");
        let response;
        if (id === initialRows[0]?.id) response = await postState({ country_id, state });
        else response = await updateState({ id, state, country_id });

        if (response?.statusCode === 200) {
          SuccessToast(response?.message);
          const { body } = await getStateList({ per_page, page_number });
          if (body) setRows(body?.data);
          else setRows([]);
          setrowId(null);
        } else if (response?.statusCode === 400) {
          ErrorToast(response?.errors[0]?.msg);
        } else {
          ErrorToast("Unknown Error Occurred!");
        }
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [initialRows, per_page, page_number]
  );

  const handleDeleteState = useCallback(async ({ id }: IDeleteStateParams) => {
    try {
      if (id === initialRows[0]?.id) return ErrorToast("Record is not saved yet!");
      const response = await deleteState({ id });

      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        const { body } = await getStateList({});
        if (body) setRows(body?.data);
        else setRows([]);
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error OCcureed!");
    } catch (error: any) {
      ErrorToast(error?.response?.data?.errors[0]?.msg);
    }
  }, [initialRows]
  );

  const displayedOptions = useMemo(() => {
    if (!Array.isArray(rows)) return [];
   return  countryOptions?.filter(({ country }) => findTextInString(country, searchText))}, 
   [countryOptions, searchText]);

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeleteState({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeleteState]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "country",
        headerName: "Country",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {

          const handleChange = (selectedCountryId: string) => {
            setrowId(params.id);

            const selectedCountry = countryOptions?.find((option) => option.id === selectedCountryId);
            if (!selectedCountry) return;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const isParamsIdOne = params.id === 1;
            const row = params.row || {};

            const updatedRow = {
              ...rows[rowIndex],
              country: selectedCountry,
              state: rows[rowIndex]?.state?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.state : row?.state,
            };


            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: "0 !important",
                  },
                  '& :focus': {
                    outline: 'none !important',
                    border: "0 !important",
                    boxShadow: 'none !important',
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() => params.value?.country.trim() === "" ? "Select  Country" : params.value?.country}
                onClose={() => setSearchText("")}
                value={params?.value?.country}
                onChange={(event) => handleChange(event.target.value)}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <GridSearchIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem value=" " disabled>
                  <em>Select  Country</em>
                </MenuItem>
                {displayedOptions && displayedOptions?.length > 0 ?
                  displayedOptions?.map(({ country, id }: Country) => (
                    <MenuItem key={id} value={id}>
                      {country}
                    </MenuItem>
                  )) :
                  (
                    <MenuItem disabled>
                      No Country Found
                    </MenuItem>
                  )}

              </Select>
            </div>
          );
        },
        valueOptions: countryOptions,
      },
      {
        field: "state",
        headerName: "State",
        type: "TextField",
        flex: 1,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        headerName: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color="primary"
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdateState({
                id: params?.row?.id?.toString(),
                country_id: params?.row?.country?.id.toString(),
                state: params?.row?.state,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params?.id === initialRows[0]?.id}
            color="error"
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}

          />,

          // <GridActionsCellItem
          //   icon={<FileCopyIcon />}
          //   label="Duplicate User"
          //   color="info"
          //   onClick={duplicateUser(params.id)}
          //   showInMenu
          // />,
        ],
      },
    ],
    [countryOptions, displayedOptions, toggleDeleteModal, handleUpdateState, initialRows, rowId, rows]
  );

  const handlePageChange = (params: any) => {
    setPage_number(params.page);
  };

  const handlePageSizeChange = (params: any) => {
    setPer_page(params.pageSize);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    return newRow;
  };

  const onCellEditCommit = (params: any) => {
    setrowId(params.id);
  };

  const filteredRows = useMemo(() => (rows || [])?.filter(({ state, country }) => findTextInString(state, globalSearch) || findTextInString(country?.country, globalSearch)), [rows, globalSearch]);

  return (
    <><DeleteConfirmationModal
      isOpen={isDeleteModalOpen}
      toggle={toggleDeleteModal}
      pageName="country"
      onConfirm={handleDeleteConfirmation}
    />
      <DataGridTable
        columns={columns}
        rows={filteredRows}
        processRowUpdate={processRowUpdate}
        pageSizeChange={handlePageSizeChange}
        pageChange={handlePageChange}
        addRow={addRow}
        onCellEditCommit={onCellEditCommit}
        setGlobalSearch={setGlobalSearch}
      />
    </>
  );
};

export default States;
