import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import { Card, CardBody, TabContent, TabPane, Label } from "reactstrap";
import {
  delete_user,
  fetch_all_users,
  update_user,
} from "../../../redux/actions/userAction";
import UserForm from "./UserForm";
import { IRegister } from "../../../redux/types/userTypes";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { findTextInString } from "../../../helpers/helperFunctions";
import { ERROR_MESSAGES } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import nousers from "../../../assets/images/nodata-found/users.png";
import norole from "../../../assets/images/nodata-found/user-role.png";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";
import SvgFilesIcon from "../../../components/SvgFiles/SvgFiles";
import PageName from "../../../components/PageSubHeaderNameComponent/PageName";
import ConfirmationModal from "../../../components/PopupModelComponent/PopupModel";
import { FormControl, FormControlLabel, Radio, RadioGroup, Switch, Tooltip } from "@mui/material";
const UsersListing: React.FC = () => {
  const [users, setusers] = useState([]);
  const [mode, setmode] = useState("add");
  const [loading, setloading] = useState(false);
  const [show_form, set_show_form] = useState(false);
  const [search, set_search] = useState("");
  const [filters, set_filters] = useState({
    start_date: "",
    end_date: "",
    sort_order: " ",
  });
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  const [update_users, set_update_users]: any = useState<IRegister>();
  const [subTab, setsubTab] = useState<string>(localStorage.getItem('userviewType') || '1');
  const { user_info } = useSelector((state: any) => state.user);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<IRegister | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [deactivationDate, setDeactivationDate] = useState<string>(today);
  const [activationDate, setActivationDate] = useState<string>(today);
 
  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
 
  const fetch_users = async () => {
    setloading(true);
    let response = await fetch_all_users({});
    if (response?.statusCode === 200) {
      setusers(response?.body?.data);
      setloading(false);
    } else {
      setloading(false);
      setusers([]);
    }
  };
 
  const subTabtoggle = (tab: string) => {
    if (subTab !== tab) {
      setsubTab(tab);
      localStorage.setItem('userviewType', tab);
    }
  };
 
  useEffect(() => {
    fetch_users();
  }, []);
 
  const handle_edit_user = useCallback(
    (user: IRegister) => {
      set_update_users(user);
      setmode("edit");
      set_show_form(true);
    },
    []
  );
 
  const handleDeleteUser = async () => {
    if (!userToDelete) {
      console.error("User to delete is null");
      return;
    }
 
    const response = await delete_user(userToDelete.id);
    if (response?.statusCode === 200) {
      SuccessToast("Delete Successful");
      fetch_users();
    } else {
      ErrorToast("Server Error, Try Again");
    }
    setDeleteModalOpen(false);
  };
 
  const filtered_users = useMemo(
    () => users.filter(({ name }) => findTextInString(name, search)),
    [search, users]
  );
 
  const handle_filter_open_close = () => {
    set_show_form(false);
    set_update_users(null);
    setmode("add");
    setfilterOpen((filterOpen) => !filterOpen);
  };
 
  const handle_user_role_form = () => {
    setfilterOpen(false);
    setmode("add");
    set_update_users(null);
    set_show_form((show_form) => !show_form);
  };
 
  const handleFilterChange = (name: string, value?: string) => {
    set_filters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };
 
  const handle_apply_filters = async () => {
    setloading(true);
    const { start_date, end_date, sort_order } = filters;
 
    if (
      start_date.trim() === "" &&
      end_date.trim() === "" &&
      sort_order.trim() === ""
    ) {
      ErrorToast(ERROR_MESSAGES.noFiltersSelected);
      setloading(false);
      return;
    }
 
    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      setloading(false);
      return;
    }
 
    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        setloading(false);
        return;
      }
 
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);
 
      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        setloading(false);
        return;
      }
    }
 
    const request = await fetch_all_users({ start_date, end_date, sort_order });
    if (request?.statusCode === 200) {
      setusers(request?.body?.data);
      setloading(false);
    } else {
      setusers([]);
      setloading(false);
    }
  };
 
  const handle_reset_filters = () => {
    set_filters({ start_date: "", end_date: "", sort_order: " " });
    fetch_users();
    setmode("add");
    set_update_users(null);
    setfilterOpen(false);
  };
 
  const openModal = (user: any) => {
    setSelectedUser(user);
    if (user.is_active) {
      setDeactivationDate(user.deactive_date || today);
    } else {
      setActivationDate(user.active_date || today);
    }
    setIsOpen(true);
  };
 
  const closeModal = () => {
    setIsOpen(false);
    setDeactivationDate("");
    setActivationDate("");
  };
 
  const handleConfirmAction = async () => {
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }
    if (selectedUser?.is_active && !deactivationDate) {
      ErrorToast("Please select a deactivation date");
      return;
    }
 
    if (!selectedUser?.is_active && !activationDate) {
      ErrorToast("Please select an activation date");
      return;
    }
 
    let payload: IRegister = { id: selectedUser?.id, ...selectedUser };
 
    if (selectedUser?.is_active) {
      if (deactivationDate === today) {
        payload = {
          ...payload,
          is_active: false,
          deactive_date: new Date().toISOString().split('T')[0],
          active_date: "",
        };
      } else if (deactivationDate >= today) {
        payload = {
          ...payload,
          deactive_date: deactivationDate,
          active_date: "",
        };
      }
    } else {
      if (activationDate === today) {
        payload = {
          ...payload,
          is_active: true,
          active_date: activationDate || new Date().toISOString().split('T')[0],
          deactive_date: "",
        }
      } else {
        payload = {
          ...payload,
          active_date: activationDate || new Date().toISOString().split('T')[0],
          deactive_date: "",
        }
      }
    }
 
    await updateUser(payload);
    closeModal();
  };
 
 
  const updateUser = async (payload: IRegister) => {
    try {
      const response = await update_user(payload);
      if (response?.statusCode === 200) {
        SuccessToast("User  updated successfully");
        fetch_users();
      } else {
        ErrorToast(response?.message || "Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      ErrorToast("Error updating user");
    }
  };
 
  const handleDateChange = (name: string, newDate: React.SetStateAction<string>) => {
    if (name === "deactivation_date") {
      setDeactivationDate(newDate);
    } else if (name === "activation_date") {
      setActivationDate(newDate);
    }
  };
 
  return (
    <>
      {loading && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
 
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={handleConfirmAction}
        toggle={closeModal}
        pageName={"Confirm"}
        message={""}
      >
 
        <div>
          {selectedUser?.is_active ? (
            <FormControl>
              <p>Are you sure want to deactivate user <strong>{selectedUser?.name}</strong> from below date ?
              </p>
              <div className="mt-2">
                <CustomMUIDatePicker
                  name="deactivation_date"
                  type="simple"
                  setState={(date) => handleDateChange("deactivation_date", date)}
                  value={deactivationDate}
                  minDate={new Date()}
                  required
                />
                {selectedUser?.deactive_date && (
                  <div className="text-muted small mt-1">
                    Previous schedule: {formatDate(selectedUser?.deactive_date)}
                  </div>
                )}
              </div>
            </FormControl>
          ) : (
            <div className="toggle-container">
              <p>Are you sure want to activate user <strong>{selectedUser?.name}</strong> from below date?</p>
              <div className="mt-2">
                <CustomMUIDatePicker
                  name="activation_date"
                  type="simple"
                  setState={(date) => handleDateChange("activation_date", date)}
                  value={activationDate}
                  minDate={new Date()}
                  required
                />
                {selectedUser?.active_date && (
                  <div className="text-muted small mt-1">
                    Previous schedule: {formatDate(selectedUser?.active_date)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </ConfirmationModal >
 
      <div className="col-12 col">
        <div className="col-12 col">
          <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between">
            <PageName title={"Users"} />
            <div className="page-title-right d-flex">
              <button
                type="button"
                className="btn btn-primary medium-btn"
                onClick={() => handle_user_role_form()}
              >
                Add
              </button>
              <div className="mr-2 patient-search-box">
                <div className="search-box-wrapper">
                  <SvgFilesIcon iconname={"search"} />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search User"
                    onChange={(e: any) => set_search(e.target.value)}
                  />
                </div>
              </div>
              <div className="mr-2">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={() => handle_filter_open_close()}
                >
                  <i className="mdi mdi-filter-outline"></i> Filter
                  <i className="mdi mdi-chevron-down" />
                </button>
 
                {filterOpen && (
                  <div className="filter-box">
                    <button
                      className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                      onClick={() => handle_filter_open_close()}
                    ><i className="mdi mdi-close" />
                    </button>
                    <div className="row">
                      <div className="col-6 mb-3">
                        <Label className="form-label">From Date</Label>
                        <CustomMUIDatePicker
                          name="start_date"
                          type="formik"
                          value={filters?.start_date}
                          setState={handleFilterChange}
                        />
 
                      </div>
                      <div className="col-6 mb-3">
                        <Label className="form-label">To Date</Label>
                        <CustomMUIDatePicker
                          name="end_date"
                          type="formik"
                          value={filters?.end_date}
                          setState={handleFilterChange}
                        />
 
                      </div>
                      <div className="col-12 mb-3">
                        <Label className="form-label">Filter By Order</Label>
                        <select
                          onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                          className="form-control"
                          name="sort_order"
                          value={filters.sort_order}
                        >
                          <option disabled value=" ">
                            Select Order
                          </option>
                          <option style={{ color: "black" }} value="ASC">
                            Ascending
                          </option>
                          <option style={{ color: "black" }} value="DESC">
                            Decending
                          </option>
                        </select>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          type="button"
                          onClick={() => handle_apply_filters()}
                          className="btn btn-info medium-btn mr-2"
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => handle_reset_filters()}
                          type="button"
                          className="btn btn-secondary medium-btn"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ul className="nav-tabs-custom list-view-tab nav-justified nav nav-tabs">
                <li className="nav-item" onClick={() => subTabtoggle("1")}>
                  <a className={`nav-link ${subTab === "1" ? "active" : ""}`}>
                    <span className="d-none d-sm-block">
                      <i className="mdi mdi-grid"></i>
                    </span>
                  </a>
                </li>
                <li className="nav-item" onClick={() => subTabtoggle("2")}>
                  <a className={`nav-link ${subTab === "2" ? "active" : ""}`}>
                    <span className="d-none d-sm-block">
                      <i className="mdi mdi-format-list-bulleted" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <UserForm
          setIsFormOpen={set_show_form}
          isFormOpen={show_form}
          user={update_users}
          mode={mode}
          fetch_users={fetch_users}
        />
        <TabContent activeTab={subTab} className="text-muted user-role-list-content common-top-con">
          <TabPane tabId="1">
            <div className="user-card-box">
              {filtered_users?.length > 0 ? (
                filtered_users?.map((user: any) => (
                  <div className="user-box" key={user?.id} onClick={() => { setSelectedUser(user); }} >
                    <div>
                      <div className="user-title-box">
                        <div className="icon-box">
                          <h3>{user?.name?.split("")[0]}</h3>
                          <h4>{user?.name}</h4>
                        </div>
                        <div className="user-setting-box">
                          <div className="edit-box" onClick={() => handle_edit_user(user)}>
                            <SvgFilesIcon iconname={"useredit"} />
                          </div>
                          {user_info?.id !== user?.id &&
                            !user?.is_super_admin && (
                              <div className="delete-box" onClick={() => {
                                setUserToDelete(user);
                                setDeleteModalOpen(true);
                              }}>
                                <SvgFilesIcon iconname={"delete"} />
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="common-box">
                          <span>Email Address</span>
                          <p className="email-user">{user?.email}</p>
                        </div>
                        <div className="common-box">
                          <span>Contact Number</span>
                          <p>{user?.mobile}</p>
                        </div>
                      </div>
                      <div className="common-box">
                        <span>User Type</span>
                        <p>{user?.user_type?.user_type}</p>
                      </div>
                      <div className="hospital-box">
                        <div className="hospital-name">
                          <SvgFilesIcon iconname={"hospital"} />
                          <h5>{user?.hospital_property?.hospital?.name}</h5>
                        </div>
                        <div className="user-active-box">
                          {user?.is_active &&
                            <Tooltip title="Active User" arrow>
                              <div className="user-active" onClick={() => openModal(user)}>
                                <SvgFilesIcon iconname={"user-active"} />
                              </div>
                            </Tooltip>
                          }
                          {!user?.is_active &&
                            <Tooltip title="Deactive User" arrow>
                              <div className="user-deactive" onClick={() => openModal(user)}>
                                <SvgFilesIcon iconname={"user-deactive"} />
                              </div>
                            </Tooltip>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
 
              ) : (
                <div className="no-data-found">
 
                  <img src={nousers} width={80}></img>
                  <h4 className="mt-2">No Users Found</h4>
                </div>
              )}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row user-list-view-section">
              <div className="col-12">
                {users?.length > 0 ? (
                  <Card>
                    <CardBody className="">
                      <div className="table-responsive">
                        <table className="table table-bordered  table-nowrap mb-0 table-list">
                          <thead className="table-light">
                            <tr>
                              <th>User</th>
                              <th>User Type</th>
                              <th>Email</th>
                              <th>User Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filtered_users?.map((user: any) => (
                              <tr key={user?.id}>
                                <td>
                                  <div className="icon-box">
                                    <h3>{user?.name?.split("")[0]}</h3>
                                    <h4>{user?.name} {user_info?.id === user?.id && "(You)"} {user?.is_super_admin && "(Super Admin)"}</h4>
                                  </div>
                                </td>
                                <td>{user?.user_type?.user_type}</td>
                                <td>{user?.email}</td>
                                <td>
                                  <div className="hospital-box">
                                    <div className="user-active-box">
                                      {user?.is_active &&
                                        <Tooltip title="Active User" arrow>
                                          <div className="user-active" onClick={() => openModal(user)}>
                                            <SvgFilesIcon iconname={"user-active"} />
                                          </div>
                                        </Tooltip>
                                      }
                                      {!user?.is_active &&
                                        <Tooltip title="Deactive User" arrow>
                                          <div className="user-deactive" onClick={() => openModal(user)}>
                                            <SvgFilesIcon iconname={"user-deactive"} />
                                          </div>
                                        </Tooltip>
                                      }
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="user-setting-box">
                                    <div className="edit-box" onClick={() => handle_edit_user(user)}>
                                      <SvgFilesIcon iconname={"useredit"} />
                                    </div>
                                    {user_info?.id !== user?.id &&
                                      !user?.is_super_admin && (
                                        <div className="delete-box" onClick={() => {
                                          setUserToDelete(user);
                                          setDeleteModalOpen(true);
                                        }}>
                                          <SvgFilesIcon iconname={"delete"} />
                                        </div>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <div className="no-data-found">
                    <img src={norole} width={80}></img>
                    <h4 className="mt-2">No User Roles Found</h4>
                  </div>
                )}
              </div>
            </div>
          </TabPane>
        </TabContent>
 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          toggle={() => setDeleteModalOpen(!deleteModalOpen)}
          pageName={userToDelete ? userToDelete?.name : ""}
          onConfirm={handleDeleteUser}
        />
      </div>
    </>
  );
};
 
export default UsersListing;